import React from 'react';
import { Header, Button } from '@iffr-dashboard-admin/ui';
import { executeQuery } from '../../../../utils/fetchHelper';
import { DELETE_BLOCKTYPE } from '../mutations/DeleteBlockType';

interface Props {
  blockTypeId: number;
  history: any;
}

export const ModalContent: React.FC<Props> = props => {
  return (
    <div style={{ textAlign: 'center', fontFamily: 'Cabin, sans-serif' }}>
      <Header center>Do you really want to delete this BlockType?</Header>
      <br />
      <p>All associated blocks will be deleted along with this blockType!</p>
      <Button
        onClick={async () => {
          const res = await executeQuery(
            DELETE_BLOCKTYPE,
            { id: props.blockTypeId },
            'deleteBlockTypeById'
          );
          console.log(res);
          props.history.push('/create-dashboard');
        }}
      >
        Yes
      </Button>
    </div>
  );
};
