import React, { useState } from 'react';
import {
  ContentHeader,
  ContentOptionsList,
  ContentOption
} from '@iffr-dashboard-admin/ui';
import { UploadContent } from './components/UploadContent/UploadContent';
import { Content } from './components/Content/Content';

interface Props {
  content: any;
  slideId: string;
  editing: boolean;
  setEditing: (editing: boolean) => void;
  timeActiveInSeconds: number;
}

export const SlideContent: React.FC<Props> = props => {
  const initialState = props.content ? 'content' : 'choose';
  const [state, setState] = useState(initialState);
  const [localEditing, setLocalEditing] = useState(props.editing);

  if (props.editing !== localEditing) {
    setLocalEditing(props.editing);
    if (state !== 'img') setState('img');
  }

  switch (state) {
    case 'content': {
      return (
        <Content
          content={props.content}
          timeActiveInSeconds={props.timeActiveInSeconds}
        />
      );
    }
    case 'choose': {
      return (
        <div>
          <ContentHeader>Add content</ContentHeader>
          <ContentOptionsList>
            <ContentOption icon="img" onClick={() => setState('img')}>
              Image
            </ContentOption>
          </ContentOptionsList>
        </div>
      );
    }
    case 'img': {
      return (
        <UploadContent
          setState={setState}
          type={'img'}
          slideId={props.slideId}
          editing={props.editing}
          setEditing={props.setEditing}
          content={props.content}
          backButton={!props.editing}
          timeActiveInSeconds={props.timeActiveInSeconds}
        />
      );
    }
    default: {
      return <div />;
    }
  }
};
