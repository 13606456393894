import React from 'react';

// components
import { Nav } from '../../components/Nav';
// styles
import { Container, Header } from '@iffr-dashboard-admin/ui';
import { Auth } from '../../components/Auth/Auth';
import { RouteComponentProps } from 'react-router';

const Home: React.FC<RouteComponentProps> = props => {
  return (
    <Auth history={props.history}>
      <Nav active={'/'} />
      <Container>
        <Header center>IFFR Dashboard Admin</Header>
      </Container>
    </Auth>
  );
};

export default Home;
