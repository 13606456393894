import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-boost';
import { ApolloProvider } from 'react-apollo';
import { split } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks';
import dotenv from 'dotenv';

import { apiUrl } from './utils/apiUrl';
import { wsUrl } from './utils/wsUrl';

import { GlobalStyle, ThemeProvider } from '@iffr-dashboard-admin/ui';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faRulerCombined,
  faShapes,
  faTh,
  faImage,
  faPencilAlt,
} from '@fortawesome/free-solid-svg-icons';

import * as serviceWorker from './serviceWorker';

// Components
import Home from './routes/Home/Home';
import CreateBlockType from './routes/CreateBlockType/CreateBlockType';
import EditBlockType from './routes/EditBlockType/EditBlockType';
import CreateDashboard from './routes/CreateDashboard/CreateDashboard';
import DashboardDetail from './routes/DashboardDetail/DashboardDetail';
import { Files } from './routes/Files/Files';
import { Twitter } from './routes/Twitter/Twitter';
import { Instagram } from './routes/Instagram/Instagram';
import { Variables } from './routes/Variables/Variables';
import { CreateNarrowCastingDashboard } from './routes/CreateNarrowCastingDashboard/CreateNarrowCastingDashboard';
import { NarrowCastingDashboardDetail } from './routes/NarrowCastingDashboardDetail/NarrowCastingDashboardDetail';
import { NotFound } from './routes/NotFound/NotFound';
import { Login } from './routes/Login/Login';

dotenv.config();

library.add(faRulerCombined, faShapes, faTh, faImage, faPencilAlt);

// Create an http link:
const httpLink = new HttpLink({
  uri: `${apiUrl}/graphql`,
});

// Create a WebSocket link:
const wsLink = new WebSocketLink({
  uri: `${wsUrl}/graphql`,
  options: {
    reconnect: true,
  },
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

const mode = 'dark';

ReactDOM.render(
  <div>
    <GlobalStyle />
    <ThemeProvider theme={{ mode }}>
      <ApolloProvider client={client}>
        <ApolloHooksProvider client={client}>
          <Router>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/Login" component={Login} />
              <Route path="/create-blocktype" component={CreateBlockType} />
              <Route path="/blocktype/:id" component={EditBlockType} />
              <Route path="/create-dashboard" component={CreateDashboard} />
              <Route
                path="/create-narrow-casting-dashboard"
                component={CreateNarrowCastingDashboard}
              />
              <Route path="/dashboard/:id" component={DashboardDetail} />
              <Route
                path="/narrow-casting-dashboard/:id"
                component={NarrowCastingDashboardDetail}
              />
              <Route path="/files/:route" component={Files} />
              <Route path="/twitter" component={Twitter} />
              <Route path="/instagram" component={Instagram} />
              <Route path="/variables" component={Variables} />
              <Route component={NotFound} />
            </Switch>
          </Router>
        </ApolloHooksProvider>
      </ApolloProvider>
    </ThemeProvider>
  </div>,
  document.getElementById('root')
);

serviceWorker.unregister();
