import React from 'react';

// styles
import {
  DraggableBlockContainer,
  Toolbar,
  CloseButton,
  DraggableBlockContent,
  Button,
  EditButton,
  PredefinedContentContainer
} from '@iffr-dashboard-admin/ui';
// utils
import { renderContent } from './utils/renderContent';

interface Props {
  data: Array<any>;
  block: any;
  onRemove: (block: any) => void;
  onSavePosition: () => void;
  setModal: (modal: {
    type: string;
    open: boolean;
    scenario?: number;
    blockId?: string;
  }) => void;
  dashboardId: number;
  setRefreshed: (refreshed: boolean) => void;
  loadingChanges: boolean;
}

const DraggablePredefinedBlock: React.FC<Props> = props => {
  const renderSaveButton = () => {
    if (
      !props.block.position.minW &&
      props.block.scope === 'global' &&
      props.block.position.y < 20
    ) {
      return (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <Button onClick={() => props.onSavePosition()}>Save Position</Button>
        </div>
      );
    } else {
      return;
    }
  };

  return (
    <DraggableBlockContainer className={'react-grid-item'}>
      <Toolbar
        className="dragHandle"
        global={props.block.scope === 'global'}
        seeThrough={props.block.type === 'campaign'}
      >
        {!props.loadingChanges && (
          <CloseButton scope={props.block.scope}>
            <span onClick={() => props.onRemove(props.block)}>&times;</span>
          </CloseButton>
        )}
        <span style={{ fontWeight: 'bold', textAlign: 'center' }}>
          {props.block.name}
        </span>
        {props.block.type === 'coming-up' && (
          <EditButton
            global={props.block.scope === 'global'}
            onClick={() =>
              props.setModal({
                type: 'editComingUp',
                open: true,
                blockId: props.block.id
              })
            }
          />
        )}
        {props.block.type === 'later-still-available' && (
          <EditButton
            global={props.block.scope === 'global'}
            onClick={() =>
              props.setModal({
                type: 'editLaterStillAvailable',
                open: true,
                blockId: props.block.id
              })
            }
          />
        )}
      </Toolbar>
      <DraggableBlockContent center={props.block.type !== 'iffr-daily'}>
        <PredefinedContentContainer type={props.block.type}>
          {renderContent(
            props.block.type,
            props.block,
            props.setModal,
            props.dashboardId,
            props.setRefreshed
          )}
        </PredefinedContentContainer>
        {renderSaveButton()}
      </DraggableBlockContent>
    </DraggableBlockContainer>
  );
};

export default React.memo(DraggablePredefinedBlock);
