export const ALL_NARROW_CASTING_DASHBOARDS = `
query AllNarrowCastingDashboards {
  allNarrowCastingDashboards {
    id
    name
    slides {
      id
    }
  }
}`;
