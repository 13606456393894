import React, { useEffect, useState } from 'react';

import { executeQuery } from '../../../../../../utils/fetchHelper';
import { ALL_AUDIENCE_AWARDS_FILMS } from './queries/allAudienceAwardsFilms';
import { TopTenListContentContainer } from '@iffr-dashboard-admin/ui';
import { useSubscription } from 'react-apollo-hooks';
import { ALL_AUDIENCE_AWARDS_FILMS_LIVE } from './subscriptions/allAudienceAwardsFilmsLive';
import { FilmsList } from './components/FilmsList/FilmsList';

export const TopTenListContent = () => {
  const [films, setFilms] = useState([]);

  const { data, loading } = useSubscription(ALL_AUDIENCE_AWARDS_FILMS_LIVE);

  useEffect(() => {
    let fetched = true;
    const getTopTenList = async () => {
      // fetch films
      const filmsResult = await executeQuery(
        ALL_AUDIENCE_AWARDS_FILMS,
        {},
        'allAudienceAwardsFilms'
      );

      if (fetched) {
        setFilms(filmsResult);
      }
    };
    getTopTenList();
    return () => {
      fetched = false;
    };
  }, []);

  const renderFilmsList = () => {
    if (!loading) {
      return <FilmsList films={data.allAudienceAwardsFilmsLive} />;
    } else {
      return <FilmsList films={films} />;
    }
  };

  return (
    <TopTenListContentContainer>{renderFilmsList()}</TopTenListContentContainer>
  );
};
