import React from 'react';
import { Formik, Form, FormikActions } from 'formik';
import {
  FormContainer,
  Button,
  FormBlock,
  Background,
  ModalCloseButton,
  Header,
} from '@iffr-dashboard-admin/ui';

// components
import { FormField } from '../../../../components/FormField/FormField';
// interfaces
import { ICreateBlockTypeFormValues } from '../../types/ICreateBlockTypeFormValues';
// queries and Mutations
import { CREATE_BLOCKTYPE } from '../../mutations/CreateBlockType';
import { executeQuery } from '../../../../utils/fetchHelper';

interface Props {
  active: boolean;
  setFormActive: (formActive: boolean) => void;
}

export const CreateBlockForm: React.FC<Props> = (props): JSX.Element => {
  return (
    <div>
      <Background active={props.active} />
      <FormContainer active={props.active}>
        <ModalCloseButton onClick={() => props.setFormActive(false)}>
          <span
            style={{
              lineHeight: '28px',
              fontSize: '1.4em',
            }}
          >
            &times;
          </span>
        </ModalCloseButton>
        <div
          style={{
            padding: '20px 20px 15px',
          }}
        >
          <Header>Create new BlockType</Header>
          <Formik
            initialValues={{ name: '' }}
            onSubmit={(
              values: ICreateBlockTypeFormValues,
              actions: FormikActions<ICreateBlockTypeFormValues>,
            ) => {
              const data = { name: values.name };
              try {
                executeQuery(CREATE_BLOCKTYPE, { data }, 'createBlocktype');
              } catch (e) {
                throw new Error(e);
              }
              actions.setSubmitting(false);
              props.setFormActive(false);
            }}
            validate={(values: ICreateBlockTypeFormValues) => {
              let errors: { name?: string } = {};
              if (!values.name) errors.name = 'Required';
              return errors;
            }}
            render={({ isSubmitting }) => (
              <Form>
                <FormField
                  label="BlockType Name"
                  type="text"
                  name="name"
                  placeholder="BlockType Name"
                  narrow
                />
                <FormBlock narrow>
                  <div />
                  <div />
                  <Button type="submit" disabled={isSubmitting}>
                    Create
                  </Button>
                </FormBlock>
              </Form>
            )}
          />
        </div>
      </FormContainer>
    </div>
  );
};
