import { executeQuery } from '../../../utils/fetchHelper';
import { DELETE_TWEET } from './mutations/deleteTweet';

export const deleteTweet = async (id: string, tweetId: string) => {
  await executeQuery(
    DELETE_TWEET,
    { id: parseInt(id), tweetId: parseInt(tweetId) },
    'deleteTweet'
  );
};
