import React from 'react';

// styles
import { Header, Button } from '@iffr-dashboard-admin/ui';
// queries and mutations
import { DELETE_PREDEFINEDBLOCK_BY_ID_TEMPLATE } from './mutations/DeletePredefinedBlockByIdTemplate';
import { DELETE_CUSTOMBLOCK_BY_ID_TEMPLATE } from './mutations/DeleteCustomBlockByIdTemplate';
// utils
import { executeQuery } from '../../../../../../utils/fetchHelper';
import { renderModalRemoveBlockMessage } from './utils/renderModalRemoveBlockMessage';

interface Props {
  scenario: number | undefined;
  deletingBlock: any;
  setModal: (modal: { type: string; open: boolean; scenario?: number }) => void;
  setLoadingChanges: (loadingChanges: boolean) => void;
}

export const RemoveBlock: React.FC<Props> = props => {
  return (
    <div>
      <Header center>Are you sure?</Header>
      <div style={{ textAlign: 'center', fontFamily: 'Cabin, sans-serif' }}>
        <div
          dangerouslySetInnerHTML={{
            __html: renderModalRemoveBlockMessage(
              props.scenario!,
              props.deletingBlock!.name
            )
          }}
        />
        <br />
        <Button
          onClick={async () => {
            props.setLoadingChanges(true);
            let predef = false;
            let deleteContent = false;
            switch (props.scenario!) {
              case 1:
              case 3: {
                predef = true;
                break;
              }
              case 2: {
                predef = true;
                deleteContent = true;
                break;
              }
              case 6: {
                deleteContent = true;
                break;
              }
              default: {
                predef = false;
                deleteContent = false;
                break;
              }
            }

            if (predef) {
              await executeQuery(
                DELETE_PREDEFINEDBLOCK_BY_ID_TEMPLATE,
                {
                  predefinedBlockId: parseInt(props.deletingBlock!.id),
                  contentId: deleteContent
                    ? parseInt(props.deletingBlock!.content.id)
                    : 0
                },
                'deletePredefinedBlockById'
              );
            } else {
              await executeQuery(
                DELETE_CUSTOMBLOCK_BY_ID_TEMPLATE,
                {
                  customBlockId: parseInt(props.deletingBlock!.id),
                  contentId: deleteContent
                    ? parseInt(props.deletingBlock!.content.id)
                    : 0
                },
                'deleteCustomBlockById'
              );
            }

            props.setModal({ type: '', open: false });
            props.setLoadingChanges(false);
          }}
        >
          Yes
        </Button>
      </div>
    </div>
  );
};
