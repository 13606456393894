import React from 'react';
import { cdnUrl } from '../../../../../../../../../../utils/cdnUrl';

interface Props {
  content: any;
  timeActiveInSeconds: number;
}

export const Content: React.FC<Props> = props => {
  if (props.content) {
    const imageUrlParts = props.content.data.file_url.split(`${cdnUrl}/`);

    let imageUrl = '';
    let videoUrl = '';
    let video = false;

    if (imageUrlParts[1]) {
      if (imageUrlParts[1].indexOf('.mp4') !== -1) {
        videoUrl = `${cdnUrl}/${imageUrlParts[1]}`;
        video = true;
      } else {
        imageUrl = `${cdnUrl}/thumbnails/${imageUrlParts[1]}`;
      }
    }

    switch (props.content.data.type) {
      case 'img': {
        return (
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              margin: '30px 0'
            }}
          >
            {!video && (
              <div
                style={{
                  background: `url('${imageUrl}')`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  height: '108px',
                  width: '192px',
                  borderRadius: '10px'
                }}
              />
            )}
            {video && (
              <video
                autoPlay
                muted
                loop
                style={{
                  height: '108px',
                  width: '192px',
                  alignSelf: 'center',
                  borderRadius: '5px'
                }}
              >
                <source src={videoUrl} type="video/mp4" />
                Your browser does not support HTML5 video.
              </video>
            )}
            <div
              style={{
                alignSelf: 'center',
                justifySelf: 'center'
              }}
            >
              {props.content.data.image_format}
            </div>
            <div
              style={{
                alignSelf: 'center',
                justifySelf: 'center'
              }}
            >
              {props.timeActiveInSeconds} Seconds
            </div>
          </div>
        );
      }
      default: {
        return <div />;
      }
    }
  } else {
    return <div />;
  }
};
