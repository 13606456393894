import { executeQuery } from '../../../../../../../utils/fetchHelper';

import { PREDEFINEDBLOCK_BY_TYPE } from '../mutations/PredefinedBlockByType';

export const handlePredefSwitch = async (
  blockTypeId: string,
  setExistingGlobalBlocks: (existingGlobalBlocks: any) => void
) => {
  switch (blockTypeId) {
    case 'predef-1':
      {
        const existingPredefinedGlobalBlocks = await executeQuery(
          PREDEFINEDBLOCK_BY_TYPE,
          { type: 'iffr-daily' },
          'predefinedBlockByType'
        );

        if (existingPredefinedGlobalBlocks.length !== 0) {
          setExistingGlobalBlocks(existingPredefinedGlobalBlocks);
        }
      }
      break;
    case 'predef-2':
      {
        const existingPredefinedGlobalBlocks = await executeQuery(
          PREDEFINEDBLOCK_BY_TYPE,
          { type: 'this-happened-earlier' },
          'predefinedBlockByType'
        );

        if (existingPredefinedGlobalBlocks.length !== 0) {
          setExistingGlobalBlocks(existingPredefinedGlobalBlocks);
        }
      }
      break;
    case 'predef-3':
      {
        const existingPredefinedGlobalBlocks = await executeQuery(
          PREDEFINEDBLOCK_BY_TYPE,
          { type: 'coming-up' },
          'predefinedBlockByType'
        );

        if (existingPredefinedGlobalBlocks.length !== 0) {
          setExistingGlobalBlocks(existingPredefinedGlobalBlocks);
        }
      }
      break;
    case 'predef-4':
      {
        const existingPredefinedGlobalBlocks = await executeQuery(
          PREDEFINEDBLOCK_BY_TYPE,
          { type: 'campaign' },
          'predefinedBlockByType'
        );

        if (existingPredefinedGlobalBlocks.length !== 0) {
          setExistingGlobalBlocks(existingPredefinedGlobalBlocks);
        }
      }
      break;
    case 'predef-5':
      {
        const existingPredefinedGlobalBlocks = await executeQuery(
          PREDEFINEDBLOCK_BY_TYPE,
          { type: 'twitter' },
          'predefinedBlockByType'
        );

        if (existingPredefinedGlobalBlocks.length !== 0) {
          setExistingGlobalBlocks(existingPredefinedGlobalBlocks);
        }
      }
      break;
    case 'predef-6':
      {
        const existingPredefinedGlobalBlocks = await executeQuery(
          PREDEFINEDBLOCK_BY_TYPE,
          { type: 'sponsor-logos' },
          'predefinedBlockByType'
        );

        if (existingPredefinedGlobalBlocks.length !== 0) {
          setExistingGlobalBlocks(existingPredefinedGlobalBlocks);
        }
      }
      break;
    case 'predef-7':
      {
        const existingPredefinedGlobalBlocks = await executeQuery(
          PREDEFINEDBLOCK_BY_TYPE,
          { type: 'instagram' },
          'predefinedBlockByType'
        );

        if (existingPredefinedGlobalBlocks.length !== 0) {
          setExistingGlobalBlocks(existingPredefinedGlobalBlocks);
        }
      }
      break;
    case 'predef-8':
      {
        const existingPredefinedGlobalBlocks = await executeQuery(
          PREDEFINEDBLOCK_BY_TYPE,
          { type: 'top-10-audience-awards' },
          'predefinedBlockByType'
        );

        if (existingPredefinedGlobalBlocks.length !== 0) {
          setExistingGlobalBlocks(existingPredefinedGlobalBlocks);
        }
      }
      break;
    case 'predef-9':
      {
        const existingPredefinedGlobalBlocks = await executeQuery(
          PREDEFINEDBLOCK_BY_TYPE,
          { type: 'iffr-logo' },
          'predefinedBlockByType'
        );

        if (existingPredefinedGlobalBlocks.length !== 0) {
          setExistingGlobalBlocks(existingPredefinedGlobalBlocks);
        }
      }
      break;
    case 'predef-10':
      {
        const existingPredefinedGlobalBlocks = await executeQuery(
          PREDEFINEDBLOCK_BY_TYPE,
          { type: 'later-still-available' },
          'predefinedBlockByType'
        );

        if (existingPredefinedGlobalBlocks.length !== 0) {
          setExistingGlobalBlocks(existingPredefinedGlobalBlocks);
        }
      }
      break;
    default:
      break;
  }
};
