import { createApolloFetch } from 'apollo-fetch';

import { apiUrl } from './apiUrl';

const fetch = createApolloFetch({
  uri: `${apiUrl}/graphql`,
});

export const executeQuery = async (
  QUERY_TEMPLATE: string,
  variables: any,
  operationName: string
) => {
  const query = await fetch({
    query: QUERY_TEMPLATE,
    variables,
  });
  if (query.errors) return query.errors;
  return query.data[operationName];
};
