import React, { useState } from 'react';
import { Formik, Form, FormikActions, ErrorMessage } from 'formik';
import {
  FormBlock,
  Label,
  FileButton,
  Button,
  ErrorMessageStyle
} from '@iffr-dashboard-admin/ui';
import { ChooseFileWindow } from '../../../../../../../components/ChooseFileWindow/ChooseFileWindow';
import { FormField } from '../../../../../../../components/FormField/FormField';
import { executeQuery } from '../../../../../../../utils/fetchHelper';
import { CREATE_PREDEFINEDBLOCK_TEMPLATE } from '../mutations/CreatePredefinedBlockTemplate';

import { cdnUrl } from '../../../../../../../utils/cdnUrl';

interface Props {
  dashboardId: number;
  setModal: (modal: { type: string; open: boolean }) => void;
  globalOrLocal: string;
}

export const CampaignForm: React.FC<Props> = props => {
  const [fileWindowOpen, setFileWindowOpen] = useState(false);
  const [fileUploaderOpen, setFileUploaderOpen] = useState(false);

  return (
    <div>
      <Formik
        initialValues={{ name: '', file: '' }}
        onSubmit={async (
          values: { name: string; file: string },
          actions: FormikActions<{ name: string; file: string }>
        ) => {
          // create new block

          const data = {
            name: values.name,
            scope: props.globalOrLocal,
            data: { file: values.file },
            dashboardId: `${props.dashboardId}`,
            type: 'campaign',
            height: 6
          };

          await executeQuery(
            CREATE_PREDEFINEDBLOCK_TEMPLATE,
            { data },
            'createPredefinedBlock'
          );

          actions.setSubmitting(false);
          props.setModal({ type: '', open: false });
        }}
        validate={(values: any) => {
          let errors: {
            name?: string;
            file?: string;
          } = {};

          if (values.name === '') errors.name = 'Required';
          if (values.file === '') errors.file = 'Required';

          return errors;
        }}
        render={({ values, isSubmitting }) => (
          <Form>
            <FormField
              label="Name"
              placeholder="Name"
              name="name"
              type="text"
            />
            <FormBlock>
              <Label>Image or Video:&nbsp;</Label>
              <ErrorMessageStyle>
                <ErrorMessage name={'file'} />
              </ErrorMessageStyle>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 4fr'
                }}
              >
                <FileButton
                  onClick={() => {
                    setFileWindowOpen(!fileWindowOpen);
                  }}
                >
                  Choose
                </FileButton>
                <div
                  style={{
                    alignSelf: 'center',
                    padding: '0 5px'
                  }}
                >
                  {values.file.replace(`${cdnUrl}/`, '')}
                </div>
              </div>
            </FormBlock>
            <FormBlock>
              <div />
              <div />
              <ChooseFileWindow
                open={fileWindowOpen}
                fileUploaderOpen={fileUploaderOpen}
                path={'campaign'}
                onChoose={(file: string) => {
                  values.file = file;
                  setFileWindowOpen(false);
                }}
                onClose={() => setFileWindowOpen(false)}
                toggleThisIndex={() => setFileUploaderOpen(true)}
              />
            </FormBlock>
            <FormBlock>
              <div />
              <div />
              <Button type="submit" disabled={isSubmitting}>
                Create
              </Button>
            </FormBlock>
          </Form>
        )}
      />
    </div>
  );
};
