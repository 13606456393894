import React from 'react';
import { IModal } from '../../../../types/modal.interface';
import { Header, Button } from '@iffr-dashboard-admin/ui';
import { executeQuery } from '../../../../../../utils/fetchHelper';
import { DELETE_DASHBOARD_BY_ID } from './mutations/DeleteDashboardById';
import { RouteComponentProps } from 'react-router';

interface Props extends RouteComponentProps {
  deletingDashboard: string;
  setModal: (modal: IModal) => void;
}

export const RemoveDashboard: React.FC<Props> = props => {
  return (
    <div>
      <Header center>Are you sure?</Header>
      <div style={{ textAlign: 'center', fontFamily: 'Cabin, sans-serif' }}>
        <div>
          You are about to delete this DASHBOARD and all of its LOCAL BLOCKS
        </div>
        <Button
          onClick={async () => {
            const res = await executeQuery(
              DELETE_DASHBOARD_BY_ID,
              { id: parseInt(props.deletingDashboard) },
              'deleteDashboardById'
            );
            console.log(res);
            props.history.push('/create-dashboard');
          }}
        >
          Yes
        </Button>
      </div>
    </div>
  );
};
