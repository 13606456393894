export const CREATE_FIELDTYPE = `
  mutation CreateFieldType($data: CreateFieldTypeInput!) {
    createFieldType(data: $data) {
      id
      name
      inputType
      options
      blockType {
        id
        name
      }
    }
  }
`;
