import React, { useEffect, useState } from 'react';
import { Nav } from '../../components/Nav';

// components
import { CreateDashboardForm } from './components/CreateDashboardForm/CreateDashboardForm';
// queries and mutations
import { ALL_DASHBOARDS } from './queries/AllDashboardsTemplate';
// styled
import {
  Container,
  Header,
  BackButton,
  TopBar,
} from '@iffr-dashboard-admin/ui';
import { executeQuery } from '../../utils/fetchHelper';
import { useSubscription } from 'react-apollo';
import { LIVE_ALL_DASHBOARDS } from './subscriptions/liveAllDashboards';
import { DashboardOverview } from './components/DashboardOverview/DashboardOverview';
import { RouteComponentProps } from 'react-router';
import { Auth } from '../../components/Auth/Auth';

const CreateDashboard: React.FC<RouteComponentProps> = props => {
  const [dashboards, setDashboards] = useState([
    // { id: 1, name: '. . .', predefinedBlocks: [], customBlocks: [] },
  ]);
  const [formActive, setFormActive] = useState(false);

  const { data, loading } = useSubscription(LIVE_ALL_DASHBOARDS);

  useEffect(() => {
    const fetchDashboards = async () => {
      const dashboards = await executeQuery(
        ALL_DASHBOARDS,
        null,
        'allDashboards'
      );
      setDashboards(dashboards);
    };
    fetchDashboards();
  }, []);

  return (
    <Auth history={props.history}>
      <CreateDashboardForm active={formActive} setFormActive={setFormActive} />
      <Nav active={'/create-dashboard'} />
      <Container>
        <TopBar>
          <BackButton inactive />
          <Header>Dashboards</Header>
        </TopBar>

        {loading ? (
          <DashboardOverview
            dashboards={dashboards}
            setFormActive={setFormActive}
          />
        ) : (
          <DashboardOverview
            dashboards={data.liveAllDashboards}
            setFormActive={setFormActive}
          />
        )}
      </Container>
    </Auth>
  );
};

export default CreateDashboard;
