import gql from 'graphql-tag';

export const LIVE_NARROW_CASTING_DASHBOARD_BY_ID = gql`
  subscription LiveNarrowCastingDashboardById($id: Float!) {
    liveNarrowCastingDashboardById(id: $id) {
      id
      name
      slides {
        id
        name
        scope
        timeActiveInSeconds
        content {
          id
          name
          data
        }
      }
      slidesOrder
    }
  }
`;
