import React, { useState } from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';

// styles
import {
  IffrDailyContentContainer,
  IffrDailySlide,
  Header,
  Image,
  Input,
  QRCode,
  QRCodeEditPopup
} from '@iffr-dashboard-admin/ui';
// queries and mutations
import { EDIT_PREDEFINEDBLOCK_BY_ID_TEMPLATE } from '../../mutations/EditPredefinedBlockByIdTemplate';
// utils
import { executeQuery } from '../../../../../../utils/fetchHelper';
// types
import { IModal } from '../../../../types/modal.interface';

interface Props {
  block: any;
  setModal: (modal: IModal) => void;
  setRefreshed: (refreshed: boolean) => void;
}

export const IffrDailyContent: React.FC<Props> = props => {
  const [fieldEditing, setFieldEditing] = useState('');
  const [data, setData] = useState(props.block.content.data);

  const updateBlock = async () => {
    props.setRefreshed(false);

    await executeQuery(
      EDIT_PREDEFINEDBLOCK_BY_ID_TEMPLATE,
      {
        data: {
          predefinedBlockId: parseInt(props.block.id),
          data
        }
      },
      'EditPredefinedBlockById'
    );
  };

  const handleBlur = async (index: number, newValue: any, field: string) => {
    while (newValue.indexOf(`<br data-cke-filler="true">`) !== -1) {
      newValue = newValue.replace(`<br data-cke-filler="true">`, '');
    }

    const newData: any = [...data];
    newData[index][field] = newValue;
    setData(newData);
    updateBlock();
  };

  const renderTitle = (article: any, i: number) => {
    if (fieldEditing === `title${i}`)
      return (
        <Input
          style={{
            height: 'content-fit'
          }}
          ref={(input: any) => {
            if (input) input.focus();
          }}
          onBlur={(e: any) => {
            setFieldEditing('false');
            handleBlur(i, e.target.value, 'title');
          }}
          type="text"
          defaultValue={article.title}
        />
      );
    else
      return (
        <Header
          style={{
            padding: '10px 5px 10px',
            fontSize: '1.3em',
            wordWrap: 'break-word',
            lineHeight: '1.53'
          }}
          onClick={() => setFieldEditing(`title${i}`)}
          small
        >
          {article.title}
        </Header>
      );
  };

  const renderQRCodeEditPopup = (article: any, i: number) => {
    if (fieldEditing === `qr${i}`)
      return (
        <QRCodeEditPopup>
          <Input
            ref={(input: any) => {
              if (input) input.focus();
            }}
            onBlur={(e: any) => {
              setFieldEditing('false');
              handleBlur(i, e.target.value, 'qrLink');
            }}
            type="text"
            defaultValue={article.qrLink}
          />
        </QRCodeEditPopup>
      );
  };

  return (
    <IffrDailyContentContainer>
      {data.map((article: any, i: number) => {
        return (
          <IffrDailySlide key={i}>
            <QRCode onClick={() => setFieldEditing(`qr${i}`)} />
            {renderQRCodeEditPopup(article, i)}
            <Image
              background={article.image}
              onClick={() => {
                props.setRefreshed(false);
                props.setModal({
                  type: 'file',
                  open: true,
                  scenario: 1,
                  blockId: props.block.id,
                  fileEditContent: { data: data, index: i }
                });
              }}
            />
            {renderTitle(article, i)}
            <div className="ck-in-draggableBlock dark">
              <CKEditor
                editor={InlineEditor}
                onBlur={(e: any, editor: any) => {
                  setFieldEditing('false');
                  handleBlur(i, editor.sourceElement.innerHTML, 'body');
                }}
                data={article.body}
                config={{
                  toolbar: [
                    'Heading',
                    'Bold',
                    'Italic',
                    'bulletedList',
                    'numberedList',
                    'Undo',
                    'Redo'
                  ]
                }}
              />
            </div>
          </IffrDailySlide>
        );
      })}
    </IffrDailyContentContainer>
  );
};
