import React, { useState, useEffect } from 'react';
import { Nav } from '../../components/Nav';
import {
  Container,
  TopBar,
  BackButton,
  Header,
} from '@iffr-dashboard-admin/ui';
import { executeQuery } from '../../utils/fetchHelper';
import { ALL_NARROW_CASTING_DASHBOARDS } from './queries/allNarrowCastingDashboards';
import { CreateNarrowCastingDashboardForm } from './components/CreateNarrowCastingDashboardForm/CreateNarrowCastingDashboardForm';
import { useSubscription } from 'react-apollo';
import { LIVE_ALL_NARROW_CASTING_DASHBOARDS } from './subscriptions/liveAllNarrowCastingDashboards';
import { NarrowCastingDashboardOverview } from './components/NarrowCastingDashboardOverview/NarrowCastingDashboardOverview';
import { RouteComponentProps } from 'react-router';
import { Auth } from '../../components/Auth/Auth';

export const CreateNarrowCastingDashboard: React.FC<
  RouteComponentProps
> = props => {
  const [dashboards, setDashboards] = useState([]);
  const [formActive, setFormActive] = useState(false);

  const { data, loading } = useSubscription(LIVE_ALL_NARROW_CASTING_DASHBOARDS);

  useEffect(() => {
    const fetchDashboards = async () => {
      const dashboards = await executeQuery(
        ALL_NARROW_CASTING_DASHBOARDS,
        null,
        'allNarrowCastingDashboards'
      );
      setDashboards(dashboards);
    };
    fetchDashboards();
  }, []);

  return (
    <Auth history={props.history}>
      <CreateNarrowCastingDashboardForm
        active={formActive}
        setFormActive={setFormActive}
      />
      <Nav active={'/create-narrow-casting-dashboard'} />
      <Container>
        <TopBar>
          <BackButton inactive />
          <Header>Narrow Casting Dashboards</Header>
        </TopBar>

        {loading ? (
          <NarrowCastingDashboardOverview
            narrowCastingDashboards={dashboards}
            setFormActive={setFormActive}
          />
        ) : (
          <NarrowCastingDashboardOverview
            narrowCastingDashboards={data.liveAllNarrowCastingDashboards}
            setFormActive={setFormActive}
          />
        )}
      </Container>
    </Auth>
  );
};
