export const ALL_GLOBAL_NARROW_CASTING_SLIDES = `
query {
  allGlobalNarrowCastingSlides {
    id
    name
    content {
      name
      data
    }
  }
}
`;
