import { executeQuery } from '../../../../../../../utils/fetchHelper';
import { EDIT_PREDEFINEDBLOCK_BY_ID_TEMPLATE } from '../../../../DraggablePredefinedBlock/mutations/EditPredefinedBlockByIdTemplate';

export const updateFile = async (
  scenario: number | undefined,
  blockId: string | undefined,
  dashboardId: string,
  blockContent: any,
  newImage: string
) => {
  // update some content

  switch (scenario) {
    // Iffr Daily
    case 1: {
      let blockData = [...blockContent.data];
      blockData[blockContent.index].image = newImage;

      let data = {
        predefinedBlockId: parseInt(blockId!),
        dashboardId: dashboardId,
        data: blockData
      };

      console.log(data);

      const res = await executeQuery(
        EDIT_PREDEFINEDBLOCK_BY_ID_TEMPLATE,
        { data },
        'editPredefinedBlockById'
      );
      console.log(res);
      break;
    }
    default: {
      break;
    }
  }
};
