import React, { useState } from 'react';
import { Formik, Form, ErrorMessage, FormikActions } from 'formik';

import { cdnUrl } from '../../../../../../../../../../utils/cdnUrl';

import { ChooseFileWindow } from '../../../../../../../../../../components/ChooseFileWindow/ChooseFileWindow';

import {
  ContentHeader,
  FormBlock,
  Label,
  ErrorMessageStyle,
  FileButton,
  Button,
  SlideFormContainer,
  SlideBackButton,
  SlideFormInnerContainer
} from '@iffr-dashboard-admin/ui';
import { executeQuery } from '../../../../../../../../../../utils/fetchHelper';
import { EDIT_NARROW_CASTING_SLIDE_BY_ID } from './mutations/editNarrowCastingSlideById';
import { FormField } from '../../../../../../../../../../components/FormField/FormField';

interface Props {
  setState: (state: string) => void;
  type: string;
  slideId: string;
  editing: boolean;
  setEditing: (editing: boolean) => void;
  content: any;
  backButton?: boolean;
  timeActiveInSeconds: number;
}

export const UploadContent: React.FC<Props> = props => {
  const [fileWindowOpen, setFileWindowOpen] = useState(false);
  const [newFileUploaderOpen, setNewFileUploaderOpen] = useState(false);
  const [checked, setChecked] = useState(0);

  const ImageFormatOptions = [
    { id: 1, name: 'Cover' },
    { id: 2, name: 'Full-width' }
  ];

  if (props.editing && checked === 0) {
    ImageFormatOptions.forEach((option: { id: number; name: string }) => {
      if (option.name.toLowerCase() === props.content.data.image_format)
        setChecked(option.id);
    });
  }

  return (
    <div>
      {props.backButton && (
        <SlideBackButton onClick={() => props.setState('choose')} />
      )}
      <SlideFormContainer>
        <ContentHeader>Upload Image</ContentHeader>
        <SlideFormInnerContainer>
          <Formik
            initialValues={
              props.editing
                ? {
                    file_url: props.content.data.file_url,
                    image_format: props.content.data.image_format,
                    timeActiveInSeconds: props.timeActiveInSeconds
                  }
                : {
                    file_url: '',
                    image_format: '',
                    timeActiveInSeconds: props.timeActiveInSeconds
                  }
            }
            onSubmit={async (values: any, actions: FormikActions<any>) => {
              const submitData = {
                slideId: parseInt(props.slideId),
                timeActiveInSeconds: values.timeActiveInSeconds,
                data: {
                  type: props.type,
                  file_url: values.file_url,
                  image_format: values.image_format
                }
              };

              try {
                await executeQuery(
                  EDIT_NARROW_CASTING_SLIDE_BY_ID,
                  { data: submitData },
                  'editNarrowCastingSlideById'
                );
              } catch (e) {
                throw new Error(e);
              }

              props.setEditing(false);
              props.setState('content');
            }}
            validate={() => {}}
            render={({ values, isSubmitting }) => (
              <Form>
                <FormField
                  label="Time Active (in seconds)"
                  placeholder="15"
                  name="timeActiveInSeconds"
                  type="number"
                />
                <FormBlock>
                  <Label>Image:&nbsp;</Label>
                  <ErrorMessageStyle>
                    <ErrorMessage name={'image'} />
                  </ErrorMessageStyle>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 4fr'
                    }}
                  >
                    <FileButton
                      onClick={() => {
                        setFileWindowOpen(!fileWindowOpen);
                        setNewFileUploaderOpen(false);
                      }}
                    >
                      Choose
                    </FileButton>
                    <div
                      style={{
                        alignSelf: 'center',
                        padding: '0 5px'
                      }}
                    >
                      {values.file_url.replace(`${cdnUrl}/`, '')}
                    </div>
                  </div>
                </FormBlock>
                <FormBlock>
                  <div />
                  <div />
                  <ChooseFileWindow
                    open={fileWindowOpen}
                    fileUploaderOpen={newFileUploaderOpen}
                    path={'narrow-casting'}
                    onChoose={(file: string) => {
                      values.file_url = file;
                      setFileWindowOpen(false);
                    }}
                    onClose={() => setFileWindowOpen(false)}
                    toggleThisIndex={() => {
                      setNewFileUploaderOpen(!newFileUploaderOpen);
                    }}
                  />
                </FormBlock>
                <FormField
                  label="Image format"
                  placeholder="Image format"
                  type="options"
                  name="scope"
                  options={ImageFormatOptions}
                  onChange={(e: any) => {
                    ImageFormatOptions.forEach(
                      (option: { id: number; name: string }) => {
                        if (option.name === e.target.id) setChecked(option.id);
                      }
                    );
                    values.image_format = e.target.id.toLowerCase();
                  }}
                  checked={checked}
                />
                <FormBlock>
                  <div />
                  <div />
                  <Button type="submit" disabled={isSubmitting}>
                    Save
                  </Button>
                </FormBlock>
              </Form>
            )}
          />
        </SlideFormInnerContainer>
      </SlideFormContainer>
    </div>
  );
};
