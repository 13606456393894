import React, { useState } from 'react';

// components
import { GlobalLocalSwitch } from './components/GlobalLocalSwitch/GlobalLocalSwitch';
import { RemoveBlock } from './components/RemoveBlock/RemoveBlock';
import { ChooseExistingGlobalBlocks } from './components/ChooseExistingGlobalBlocks/ChooseExistingGlobalBlocks';
// interfaces
import { IBlock } from '../../types/IBlock';
// styled
import { ModalStyle } from '@iffr-dashboard-admin/ui';
// types
import { IBlockType } from '../../types/IBlockType';
import { IPredefinedBlock } from '../../types/IPredefinedBlock';
// utils
import { renderNewBlockModalContent } from './utils/renderNewBlockModalContent';
import { FileBrowser } from './components/FileBrowser/FileBrowser';
import { IModal } from '../../types/modal.interface';
import { EditComingUpForm } from './components/PredefinedBlockForms/ComingUpForm/EditComingUpForm';
import { RemoveDashboard } from './components/RemoveDashboard/RemoveDashboard';
import { RouteComponentProps } from 'react-router';
import { EditLaterStillAvailableForm } from './components/PredefinedBlockForms/LaterStillAvailableForm/EditLaterStillAvailableForm';

// TODO: Fish out unnecessary data
interface Props extends RouteComponentProps {
  setModal: (modal: IModal) => void;
  modal: IModal;
  dashboardId: number;
  dashboard: any;
  allBlocks: Array<any>;
  blockType: IBlockType;
  deletingBlock: IBlock | IPredefinedBlock | null;
  setLoadingChanges?: (loadingChanges: boolean) => void;
}

const Modal: React.FC<Props> = (props): JSX.Element => {
  const [glSwitch, setGlSwitch] = useState(true);
  const [newGlobalBlock, setNewGlobalBlock] = useState(false);
  const [globalOrLocal, setGlobalOrLocal] = useState('');
  const [existingGlobalBlocks, setExistingGlobalBlocks] = useState([]);

  // For custom blockType
  const [fileCustomBlockWindowOpen, setCustomBlockFileWindowOpen] = useState(
    false
  );
  const [
    newCustomBlockFileUploaderOpen,
    setNewCustomBlockFileUploaderOpen
  ] = useState(false);

  const renderModalContent = () => {
    switch (props.modal.type) {
      case 'newBlock':
        if (glSwitch) {
          return (
            <GlobalLocalSwitch
              blockType={props.blockType}
              setGlobalOrLocal={setGlobalOrLocal}
              setExistingGlobalBlocks={setExistingGlobalBlocks}
              setGlSwitch={setGlSwitch}
            />
          );
        }

        if (globalOrLocal === 'global' && !newGlobalBlock) {
          return (
            <ChooseExistingGlobalBlocks
              blockType={props.blockType}
              existingGlobalBlocks={existingGlobalBlocks}
              dashboardId={props.dashboardId}
              setModal={props.setModal}
              setNewGlobalBlock={setNewGlobalBlock}
            />
          );
        } else {
          return renderNewBlockModalContent(
            props.setModal,
            props.dashboardId,
            props.blockType,
            globalOrLocal,
            fileCustomBlockWindowOpen,
            setCustomBlockFileWindowOpen,
            newCustomBlockFileUploaderOpen,
            setNewCustomBlockFileUploaderOpen
          );
        }
      case 'removeBlock':
        return (
          <RemoveBlock
            scenario={props.modal.scenario}
            deletingBlock={props.deletingBlock}
            setModal={props.setModal}
            setLoadingChanges={props.setLoadingChanges!}
          />
        );
      case 'file':
        return (
          <FileBrowser
            setModal={props.setModal}
            modal={props.modal}
            dashboardId={props.dashboard.id}
          />
        );
      case 'editComingUp':
        return (
          <EditComingUpForm
            dashboardId={props.dashboard.id}
            setModal={props.setModal}
            blockId={props.modal.blockId!}
          />
        );
      case 'editLaterStillAvailable':
        return (
          <EditLaterStillAvailableForm
            dashboardId={props.dashboard.id}
            setModal={props.setModal}
            globalOrLocal={globalOrLocal}
            blockId={props.modal.blockId!}
          />
        );
      case 'removeDashboard':
        return (
          <RemoveDashboard
            history={props.history}
            location={props.location}
            match={props.match}
            deletingDashboard={props.dashboard.id}
            setModal={props.setModal}
          />
        );
      default:
        return <div />;
    }
  };

  return (
    <ModalStyle
      onClose={() => {
        props.setModal({ type: '', open: false });
      }}
    >
      {renderModalContent()}
    </ModalStyle>
  );
};

export default React.memo(Modal);
