export const ALL_BLOCKTYPES_TEMPLATE = `
query AllBlockTypes {
  allBlockTypes {
    id
    name
    fields {
      name
      inputType
    }
  }
}`;
