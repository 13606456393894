import React, { useEffect, useState } from 'react';

// compontents
import { Nav } from '../../components/Nav';
// styles
import {
  Container,
  Header,
  CheckContainer,
  Checkbox,
  Checkmark,
  Tweet,
  TwitterUserName,
  TweetText,
  TweetTimeSince,
  TweetUserImage,
  UserInfo,
  TrashcanIcon
} from '@iffr-dashboard-admin/ui';
// queries & mutations
import { ALL_TWEETS } from './queries/AllTweets';
// utils
import { executeQuery } from '../../utils/fetchHelper';
import { UPDATE_TWEET_STATUS } from './mutations/updateTweetStatus';
import { RouteComponentProps } from 'react-router';
import { Auth } from '../../components/Auth/Auth';
import { deleteTweet } from './utils/deleteTweet';
import { timeSince } from './utils/timeSince';

export const Twitter: React.FC<RouteComponentProps> = props => {
  const [tweets, setTweets] = useState<Array<any>>([]);
  const [checked, setChecked] = useState([]);

  useEffect(() => {
    const fetchTweets = async () => {
      const res = await executeQuery(ALL_TWEETS, {}, 'allTweets');
      setTweets(res);
      setChecked(res.map((tweet: any) => tweet.status));
    };
    fetchTweets();
  }, []);

  const spanHashtags = (text: string, hashtags: Array<string>) => {
    hashtags.forEach((hashtag: string) => {
      text = text.replace(
        `#${hashtag}`,
        `<span style="color: #000">#${hashtag}</span>`
      );
    });
    return text;
  };

  const restyleAt = (text: string) => {
    text = text.replace(`@`, `<span style="font-family: sans-serif">@</span>`);
    return text;
  };

  const handleCheck = async (tweetId: string, i: number) => {
    await executeQuery(
      UPDATE_TWEET_STATUS,
      { tweetId: parseInt(tweetId), status: !checked[i] },
      'updateTweetStatus'
    );

    let newChecked = [...checked];
    newChecked[i] = !checked[i] as never;
    setChecked(newChecked);
  };

  return (
    <Auth history={props.history}>
      <Nav active="/twitter" />
      <Container>
        <Header>Twitter</Header>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridGap: '50px',
            width: '90%'
          }}
        >
          {tweets.map((tweet: any, i: number) => {
            return (
              <div
                key={tweet.tweetId}
                style={{ background: 'rgba(35,35,35,1)', borderRadius: '15px' }}
              >
                <CheckContainer>
                  <Checkbox
                    type="checkbox"
                    defaultChecked={checked[i]}
                    onClick={() => handleCheck(tweet.id, i)}
                  />
                  <Checkmark checked={checked[i]} viewBox="0 0 50 50">
                    <polyline points="10,25 20,35 40,15" />
                  </Checkmark>
                </CheckContainer>
                <Tweet checked={checked[i]}>
                  <TweetText
                    dangerouslySetInnerHTML={{
                      __html: spanHashtags(tweet.text, tweet.hashtags)
                    }}
                  />
                  <UserInfo>
                    <TweetUserImage src={tweet.userImage} />
                    <TwitterUserName
                      dangerouslySetInnerHTML={{
                        __html: restyleAt(tweet.userName)
                      }}
                    />
                    <TweetTimeSince>
                      {timeSince(tweet.timeSince)}
                    </TweetTimeSince>
                  </UserInfo>
                </Tweet>
                <div style={{ width: '100%', padding: '0 0 20px 0' }}>
                  <TrashcanIcon
                    onClick={() => {
                      deleteTweet(tweet.id, tweet.tweetId);

                      let newTweets = [...tweets];
                      let newChecked = [...checked];
                      const idx = newTweets.findIndex(
                        searchTweet => tweet.id === searchTweet.id
                      );

                      newTweets.splice(idx, 1);
                      setTweets(newTweets);

                      newChecked.splice(idx, 1);
                      setChecked(newChecked);
                    }}
                    center
                  />
                </div>
              </div>
            );
          })}
        </div>
      </Container>
    </Auth>
  );
};
