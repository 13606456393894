export const setError = (item: any, errors: any, name: string, i: number) => {
  if (item[name] === "") {
    if (!errors.items[i]) {
      errors.items.push({ [name]: "Required" });
    } else {
      errors.items[i][name] = "Required";
    }
  } else {
    if (!errors.items[i]) {
      errors.items.push({ [name]: "" });
    } else {
      errors.items[i][name] = "";
    }
  }
};
