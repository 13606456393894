import React from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import QRCode from 'qrcode-react';

import { ItemText, Input } from '@iffr-dashboard-admin/ui';

export const renderItem = (
  field: any,
  fieldEditing: string,
  formData: any,
  handleBlur: (value: string, fieldName: string) => void,
  setFieldEditing: (fieldEditing: string) => void
) => {
  switch (field.inputType) {
    case 'string': {
      if (fieldEditing !== field.name)
        return !formData[field.name] || formData[field.name].value === '' ? (
          <i>Empty</i>
        ) : (
          <div style={{ width: '90%', margin: '0 auto' }}>
            <ItemText>
              {formData[field.name] ? formData[field.name].value : 'Empty'}
            </ItemText>
          </div>
        );
      else
        return (
          <div style={{ width: '90%', margin: '0 auto' }}>
            <Input
              style={{ margin: '-5.5px 0' }}
              ref={(input: any) => {
                if (input) input.focus();
              }}
              onBlur={(e: any) => {
                handleBlur(e.target.value, field.name);
                setFieldEditing('false');
              }}
              type="text"
              defaultValue={
                formData[field.name] ? formData[field.name].value : ''
              }
              placeholder={field.name}
              scope={'block'}
            />
          </div>
        );
    }
    case 'number': {
      if (fieldEditing !== field.name)
        return !formData[field.name] || formData[field.name].value === '' ? (
          <i>Empty</i>
        ) : (
          <ItemText>
            {formData[field.name] ? formData[field.name].value : 'Empty'}
          </ItemText>
        );
      else
        return (
          <Input
            style={{ margin: '-5px 0' }}
            ref={(input: any) => {
              if (input) input.focus();
            }}
            onBlur={(e: any) => {
              setFieldEditing('false');
              handleBlur(e.target.value, field.name);
            }}
            type="number"
            defaultValue={
              formData[field.name] ? formData[field.name].value : ''
            }
            placeholder={field.name}
          />
        );
    }
    case 'image': {
      if (fieldEditing !== field.name)
        return !formData[field.name] || formData[field.name].value === '' ? (
          <i>Empty</i>
        ) : (
          <div
            style={{
              width: '100%',
              height: '200px',
              borderRadius: '5px',
              background: `url("${formData[field.name].value}")`,
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
          />
        );
      else
        return (
          <Input
            ref={(input: any) => {
              if (input) input.focus();
            }}
            onBlur={(e: any) => {
              setFieldEditing('false');
              handleBlur(e.target.value, field.name);
            }}
            type="text"
            defaultValue={
              formData[field.name] ? formData[field.name].value : ''
            }
            placeholder={field.name}
          />
        );
    }
    case 'textarea': {
      return (
        <div className="dark">
          <div className="ck-in-draggableBlock">
            <CKEditor
              editor={InlineEditor}
              onBlur={(e: any, editor: any) => {
                setFieldEditing('false');
                handleBlur(editor.sourceElement.innerHTML, field.name);
              }}
              data={formData[field.name] ? formData[field.name].value : ''}
              config={{
                toolbar: [
                  'Heading',
                  'Bold',
                  'Italic',
                  'bulletedList',
                  'numberedList',
                  'Undo',
                  'Redo'
                ]
              }}
            />
          </div>
        </div>
      );
    }
    case 'qrcode': {
      return (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <QRCode
            size={200}
            value={formData[field.name].value}
            logo="/img/iffr.png"
            logoWidth={200 * 0.4}
            logoHeight={200 * 0.4}
          />
        </div>
      );
    }
    default: {
      return <div />;
    }
  }
};
