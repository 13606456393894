import React, { useEffect, useState } from 'react';
import { AddButton } from '@iffr-dashboard-admin/ui';
import { ALL_BLOCKTYPES_TEMPLATE } from '../../queries/AllBlockTypes';
import { executeQuery } from '../../../../utils/fetchHelper';
import { IBlockType } from '../../types/IBlockType';

interface Props {
  onItemClick: (item: IBlockType) => void;
}

const AvailableBlocksList: React.FC<Props> = (props): JSX.Element => {
  const [allBlockTypes, setAllBlockTypes] = useState<Array<any>>([]);

  useEffect(() => {
    const fetchData = async () => {
      let allBlockTypes = [
        {
          id: 'predef-1',
          name: 'IFFR Daily'
        },
        {
          id: 'predef-2',
          name: 'This happened earlier'
        },
        {
          id: 'predef-3',
          name: 'Coming Up'
        },
        {
          id: 'predef-4',
          name: 'Photo / Video'
        },
        {
          id: 'predef-5',
          name: 'Twitter'
        },
        {
          id: 'predef-7',
          name: 'Instagram'
        },
        {
          id: 'predef-6',
          name: 'Sponsor Logos'
        },
        {
          id: 'predef-8',
          name: 'Top 10 Audience award'
        },
        {
          id: 'predef-9',
          name: 'IFFR Logo'
        },
        {
          id: 'predef-10',
          name: 'Later and still available'
        }
      ];

      let customBlockTypes = await executeQuery(
        ALL_BLOCKTYPES_TEMPLATE,
        {},
        'allBlockTypes'
      );

      allBlockTypes = allBlockTypes.concat(customBlockTypes);
      setAllBlockTypes(allBlockTypes);
    };
    fetchData();
  }, []);

  return <AddButton items={allBlockTypes} onItemClick={props.onItemClick} />;
};

export default React.memo(AvailableBlocksList);
