import { executeQuery } from '../../../../../../../../utils/fetchHelper';
import { EDIT_PREDEFINEDBLOCK_BY_ID_TEMPLATE } from '../../../../../DraggablePredefinedBlock/mutations/EditPredefinedBlockByIdTemplate';

export const editComingUpBlock = async (
  blockId: string,
  eventList: Array<any>
) => {
  const data = {
    predefinedBlockId: parseInt(blockId),
    data: eventList
  };

  const res = await executeQuery(
    EDIT_PREDEFINEDBLOCK_BY_ID_TEMPLATE,
    { data },
    'editPredefinedBlockById'
  );

  console.log(res);
};
