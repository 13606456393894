import React from 'react';
import { ErrorMessage, Field, FieldProps, FieldArray } from 'formik';
import CKEditor from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';

// interfaces
import { IFormFieldProps } from './types/IFormFieldProps';
// styled
import {
  FormBlock,
  Label,
  ErrorMessageStyle,
  Input,
  SelectContainer,
  OptionButtonsContainer,
  OptionButton,
  Button
} from '@iffr-dashboard-admin/ui';

/**
 *
 * Form field renders a field found in any form.
 *
 * @param props
 * @constructor
 */
export const FormField: React.FC<IFormFieldProps> = props => {
  return (
    <FormBlock narrow={props.narrow}>
      <Label>{props.label}:&nbsp;</Label>
      <ErrorMessageStyle>
        <ErrorMessage name={props.name} />
      </ErrorMessageStyle>
      {renderInput(
        props.type,
        props.name,
        props.placeholder,
        props.id ? props.id : undefined,
        props.options ? props.options : undefined,
        props.onBlur ? props.onBlur : undefined,
        props.onChange ? props.onChange : undefined,
        props.scope || undefined,
        props.checked || undefined,
        props.arrayValues || undefined
      )}
    </FormBlock>
  );
};

/**
 *
 * Render input field
 *
 * @param type
 * @param name
 * @param placeholder
 * @param id
 * @param options
 * @param onBlur
 * @param onChange
 */
const renderInput = (
  type: string,
  name: string,
  placeholder: string,
  id?: string,
  options?: Array<any>,
  onBlur?: any,
  onChange?: any,
  scope?: string,
  checked?: number,
  arrayValues?: Array<any>
) => {
  switch (type) {
    case 'text':
    case 'number':
      return (
        <Field
          name={name}
          render={({ field }: FieldProps<any>) => {
            return (
              <Input
                {...(id && { id: id })}
                {...field}
                onBlur={onBlur}
                type={type}
                placeholder={placeholder}
                scope={scope}
              />
            );
          }}
        />
      );
    case 'select':
      return (
        <SelectContainer>
          <Field
            name={name}
            component="select"
            defaultValue=""
            {...(id && { id: id })}
            {...(onChange && { onChange: onChange })}
          >
            <option disabled value="">
              {placeholder}
            </option>
            {options!.map((option: { id: number; name: string }) => {
              return (
                <option value={option.id} key={option.id}>
                  {option.name}
                </option>
              );
            })}
          </Field>
        </SelectContainer>
      );
    case 'textarea':
      return (
        <Field
          name={name}
          render={({ field }: FieldProps<any>) => (
            <div className="dark">
              <CKEditor
                {...(id && { id: id })}
                {...field}
                config={{
                  toolbar: [
                    'Heading',
                    'Bold',
                    'Italic',
                    'bulletedList',
                    'numberedList',
                    'Undo',
                    'Redo'
                  ]
                }}
                onChange={onChange}
                onBlur={onBlur}
                editor={InlineEditor}
                placeholder={placeholder}
              />
            </div>
          )}
        />
      );
    case 'options':
      return (
        <Field
          name={name}
          render={() => (
            <OptionButtonsContainer>
              {options!.map((option: { id: number; name: string }) => (
                <OptionButton
                  key={option.id}
                  id={option.name}
                  onClick={onChange}
                  checked={option.id === checked}
                >
                  {option.name}
                </OptionButton>
              ))}
            </OptionButtonsContainer>
          )}
        />
      );
    case 'array':
      return (
        <FieldArray
          name={name}
          render={arrayHelpers => (
            <div style={{ fontFamily: 'Cabin, sans-serif', color: '#fff' }}>
              {arrayValues && arrayValues.length > 0
                ? arrayValues.map((arrayValue, index) => (
                    <div
                      key={index}
                      style={{
                        display: 'grid',
                        alignItems: 'center',
                        width: '60%',
                        gridTemplateColumns: '8fr 1fr',
                        margin: '10px 0 0 0'
                      }}
                    >
                      <div>{arrayValue}</div>
                      <div style={{ textAlign: 'center' }}>
                        <Button
                          style={{ margin: 0 }}
                          onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                        >
                          -
                        </Button>
                      </div>
                    </div>
                  ))
                : 'No ID'}
            </div>
          )}
        />
      );
    default:
      return null;
  }
};
