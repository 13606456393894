import React, { useState } from 'react';
import { Formik, Form, FieldArray, FormikActions } from 'formik';

import { cdnUrl } from '../../../../../../../utils/cdnUrl';

import { FormField } from '../../../../../../../components/FormField/FormField';
import { ChooseFileWindow } from '../../../../../../../components/ChooseFileWindow/ChooseFileWindow';
import {
  Header,
  AddItemButton,
  Button,
  FormBlock,
  FormSection,
  Label,
  FileButton
} from '@iffr-dashboard-admin/ui';
import { executeQuery } from '../../../../../../../utils/fetchHelper';
import { CREATE_PREDEFINEDBLOCK_TEMPLATE } from '../mutations/CreatePredefinedBlockTemplate';
import { setError } from './utils/setError';

interface IffrDailyItem {
  image: string;
  title: string;
  body: string;
  qrLink: string;
}

export const IffrDailyForm: React.FC<{
  dashboardId: number;
  setModal: (modal: { type: string; open: boolean }) => void;
  globalOrLocal: string;
}> = props => {
  const [fileWindowOpen, setFileWindowOpen] = useState({
    index: 0,
    open: false
  });
  const [newFileUploaderOpen, setNewFileUploaderOpen] = useState({
    index: 0,
    open: false
  });

  return (
    <div>
      <Header center>
        Create IFFR Daily Block{' - '}
        {props.globalOrLocal.charAt(0).toUpperCase() +
          props.globalOrLocal.slice(1)}
      </Header>
      <br />
      <div>
        <Formik
          initialValues={{
            name: '',
            items: [
              {
                image: '',
                title: '',
                body: '',
                qrLink: ''
              }
            ]
          }}
          onSubmit={async (
            values: { name: string; items: IffrDailyItem[] },
            actions: FormikActions<{ name: string; items: IffrDailyItem[] }>
          ) => {
            const data = {
              name: values.name,
              scope: props.globalOrLocal,
              data: values.items,
              dashboardId: `${props.dashboardId}`,
              type: 'iffr-daily',
              height: 14
            };

            await executeQuery(
              CREATE_PREDEFINEDBLOCK_TEMPLATE,
              { data },
              'createPredefinedBlock'
            );

            actions.setSubmitting(false);
            props.setModal({ type: '', open: false });
          }}
          validate={(values: any) => {
            let errors: {
              name?: string;
              items: Array<{
                image?: string;
                title?: string;
                body?: string;
                qrLink?: string;
              }>;
            } = { items: [] };

            const fields: Array<string> = ['image', 'title', 'body', 'qrLink'];

            if (values.name === '') errors.name = 'Required';

            values.items.forEach((item: any, i: number) =>
              fields.forEach(fieldname => setError(item, errors, fieldname, i))
            );

            let errorFound: boolean = false;
            errors.items.forEach((item: any) => {
              fields.forEach(fieldname => {
                if (item[fieldname] !== '') errorFound = true;
              });
            });

            return errorFound ? errors : {};
          }}
          render={({ values, isSubmitting }) => (
            <Form>
              <FormField
                label="Name"
                placeholder="Name"
                name="name"
                type="text"
              />
              <FieldArray
                name="items"
                render={arrayHelpers => (
                  <div>
                    {values.items.map((item, index) => (
                      <div key={index}>
                        <Header small>Item {index + 1}</Header>
                        <FormSection>
                          <FormBlock>
                            <Label>Header Image:&nbsp;</Label>
                            <div />
                            <div
                              style={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 4fr'
                              }}
                            >
                              <FileButton
                                onClick={() => {
                                  setFileWindowOpen({
                                    index: index,
                                    open:
                                      fileWindowOpen.index === index
                                        ? !fileWindowOpen.open
                                        : true
                                  });
                                  setNewFileUploaderOpen({
                                    index: index,
                                    open: false
                                  });
                                }}
                              >
                                Choose
                              </FileButton>
                              <div
                                style={{
                                  alignSelf: 'center',
                                  padding: '0 5px'
                                }}
                              >
                                {values.items[index].image.replace(
                                  `${cdnUrl}/`,
                                  ''
                                )}
                              </div>
                            </div>
                          </FormBlock>
                          <FormBlock>
                            <div />
                            <div />
                            <ChooseFileWindow
                              open={
                                fileWindowOpen.index === index &&
                                fileWindowOpen.open
                              }
                              fileUploaderOpen={
                                newFileUploaderOpen.index === index &&
                                newFileUploaderOpen.open
                              }
                              path={'iffr-daily'}
                              onChoose={(file: string) => {
                                values.items[index].image = file;
                                setFileWindowOpen({ index: 0, open: false });
                              }}
                              onClose={() =>
                                setFileWindowOpen({ index: 0, open: false })
                              }
                              toggleThisIndex={() => {
                                setNewFileUploaderOpen({
                                  index: index,
                                  open: !newFileUploaderOpen.open
                                });
                              }}
                            />
                          </FormBlock>
                          <FormField
                            label="Title"
                            placeholder="Title"
                            name={`items.${index}.title`}
                            type="text"
                          />
                          <FormField
                            label="Body"
                            placeholder="Body"
                            onChange={(e: any, editor: any) => {
                              values.items[index].body =
                                editor.sourceElement.innerHTML;
                            }}
                            name={`items.${index}.body`}
                            type="textarea"
                          />
                          <FormField
                            label="QR Link"
                            placeholder="URL"
                            name={`items.${index}.qrLink`}
                            type="text"
                          />
                        </FormSection>
                      </div>
                    ))}
                    <div style={{ textAlign: 'center', paddingTop: '10px' }}>
                      <AddItemButton
                        onClick={() =>
                          arrayHelpers.insert(values.items.length, {
                            image: '',
                            title: '',
                            body: '',
                            qrLink: ''
                          })
                        }
                      >
                        +
                      </AddItemButton>
                      {values.items.length! > 1 && (
                        <AddItemButton
                          onClick={() =>
                            arrayHelpers.remove(values.items.length - 1)
                          }
                        >
                          -
                        </AddItemButton>
                      )}
                    </div>
                    <FormBlock>
                      <div />
                      <div />
                      <Button type="submit" disabled={isSubmitting}>
                        Create
                      </Button>
                    </FormBlock>
                  </div>
                )}
              />
            </Form>
          )}
        />
      </div>
    </div>
  );
};
