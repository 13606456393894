import React from 'react';

// Interfaces
// Queries and mutations
import { GLOBAL_CUSTOMBLOCKS_FOR_BLOCKTYPE_BY_ID } from './queries/GlobalCustomBlocksForBlockTypeById';
// styles
import {
  Header,
  GlobalLocalSwitchContainer,
  GlobalButton,
  LocalButton
} from '@iffr-dashboard-admin/ui';
// types
import { IBlock } from '../../../../types/IBlock';
// Utils
import { executeQuery } from '../../../../../../utils/fetchHelper';
import { handlePredefSwitch } from './utils/handlePredefSwitch';

interface Props {
  blockType: any;
  setGlobalOrLocal: (GlobalOrLocal: string) => void;
  setExistingGlobalBlocks: (existingGlobalBlocks: any) => void;
  setGlSwitch: (setGlSwitch: boolean) => void;
}

export const GlobalLocalSwitch: React.FC<Props> = props => {
  return (
    <div>
      <Header center>Create a new {props.blockType.name} block</Header>
      <GlobalLocalSwitchContainer>
        <GlobalButton
          onClick={async () => {
            props.setGlobalOrLocal('global');

            if (props.blockType.id.substr(0, 6) === 'predef') {
              handlePredefSwitch(
                props.blockType.id,
                props.setExistingGlobalBlocks
              );
            } else {
              // Get global blocks of this type and check if there are any
              const existingBlockType = await executeQuery(
                GLOBAL_CUSTOMBLOCKS_FOR_BLOCKTYPE_BY_ID,
                { id: parseInt(props.blockType.id) },
                'globalCustomBlocksForBlockTypeById'
              );

              const uniqueBlocks = existingBlockType.customBlocks.filter(
                (customBlock: IBlock, pos: number) =>
                  existingBlockType.customBlocks.findIndex(
                    (x: any) => x.content.id === customBlock.content.id
                  ) === pos
              );

              existingBlockType.customBlocks = uniqueBlocks;

              if (existingBlockType.customBlocks.length !== 0) {
                props.setExistingGlobalBlocks(existingBlockType.customBlocks);
              }
            }

            props.setGlSwitch(false);
          }}
        />
        <LocalButton
          onClick={async () => {
            props.setGlobalOrLocal('local');
            props.setGlSwitch(false);
          }}
        />
      </GlobalLocalSwitchContainer>
    </div>
  );
};
