import gql from 'graphql-tag';

export const LIVE_BLOCKTYPE_BY_ID = gql`
  subscription LiveBlockTypeById($blockTypeId: Float!) {
    liveBlockTypeById(blockTypeId: $blockTypeId) {
      id
      name
      fields {
        id
        name
        inputType
        index
        options
      }
    }
  }
`;
