import gql from "graphql-tag";

export const LIVE_ALL_BLOCKTYPES = gql`
  subscription {
    liveAllBlockTypes {
      id
      name
    }
  }
`;
