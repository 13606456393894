import React, { useState, useEffect } from 'react';
import { Header, Container, Button, Loading } from '@iffr-dashboard-admin/ui';
import { Nav } from '../../components/Nav';
import { Formik, Form, FormikActions } from 'formik';
import { FormField } from '../../components/FormField/FormField';
import { ALL_VARIABLES } from './queries/AllVariables';
import { executeQuery } from '../../utils/fetchHelper';
import { CREATE_VARIABLES } from './mutations/createVariables';
import { EDIT_VARIABLES } from './mutations/editVariables';
import { Auth } from '../../components/Auth/Auth';
import { RouteComponentProps } from 'react-router';

let newObject = true;

export const Variables: React.FC<RouteComponentProps> = props => {
  const superUser = props.location.search === '?super=true';

  const [variablesObject, setVariablesObject] = useState<{
    id: number;
    variables: {
      thisHappendEarlierLocation: string;
      sponsorLogoLocation: string;
      iffrDailyImageLocation: string;
      campaignFilesLocation: string;
      narrowCastingImagesLocation: string;
      instagramHashtags: string;
      comingUpLocation: string;
      laterAndStillAvailableLocation: string;
      audienceAwardsLocation: string;
      deletedTweets: [string];
    };
  }>({
    id: 0,
    variables: {
      thisHappendEarlierLocation: '',
      sponsorLogoLocation: '',
      iffrDailyImageLocation: '',
      campaignFilesLocation: '',
      narrowCastingImagesLocation: '',
      instagramHashtags: '',
      comingUpLocation: '',
      laterAndStillAvailableLocation: '',
      audienceAwardsLocation: '',
      deletedTweets: ['']
    }
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchVariablesObject = async () => {
      const res = await executeQuery(ALL_VARIABLES, {}, 'allVariables');
      if (res.length > 0) {
        newObject = false;
        setVariablesObject(res[0]);
      }
      setLoading(false);
    };
    fetchVariablesObject();
  }, []);

  return (
    <Auth history={props.history}>
      <Nav active={`/variables`} />
      <Container>
        <Header>Variables</Header>
        {loading && <Loading />}
        {!loading && (
          <div
            style={{
              width: '50%'
            }}
          >
            <Formik
              initialValues={{
                thisHappendEarlierLocation:
                  variablesObject.variables.thisHappendEarlierLocation,
                sponsorLogoLocation:
                  variablesObject.variables.sponsorLogoLocation,
                iffrDailyImageLocation:
                  variablesObject.variables.iffrDailyImageLocation,
                campaignFilesLocation:
                  variablesObject.variables.campaignFilesLocation,
                narrowCastingImagesLocation:
                  variablesObject.variables.narrowCastingImagesLocation,
                instagramHashtags: variablesObject.variables.instagramHashtags,
                comingUpLocation: variablesObject.variables.comingUpLocation,
                laterAndStillAvailableLocation:
                  variablesObject.variables.laterAndStillAvailableLocation,
                audienceAwardsLocation:
                  variablesObject.variables.audienceAwardsLocation,
                deletedTweets: variablesObject.variables.deletedTweets
              }}
              onSubmit={async (
                values: {
                  thisHappendEarlierLocation: string;
                  sponsorLogoLocation: string;
                  iffrDailyImageLocation: string;
                  campaignFilesLocation: string;
                  narrowCastingImagesLocation: string;
                  instagramHashtags: string;
                  comingUpLocation: string;
                  laterAndStillAvailableLocation: string;
                  audienceAwardsLocation: string;
                  deletedTweets: [string];
                },
                actions: FormikActions<{
                  thisHappendEarlierLocation: string;
                  sponsorLogoLocation: string;
                  iffrDailyImageLocation: string;
                  campaignFilesLocation: string;
                  narrowCastingImagesLocation: string;
                  instagramHashtags: string;
                  comingUpLocation: string;
                  laterAndStillAvailableLocation: string;
                  audienceAwardsLocation: string;
                  deletedTweets: [string];
                }>
              ) => {
                let data: {
                  id?: number;
                  variables?: {
                    thisHappendEarlierLocation?: string;
                    sponsorLogoLocation?: string;
                    iffrDailyImageLocation?: string;
                    campaignFilesLocation?: string;
                    narrowCastingImagesLocation?: string;
                    instagramHashtags?: string;
                    comingUpLocation?: string;
                    laterAndStillAvailableLocation?: string;
                    audienceAwardsLocation?: string;
                    deletedTweets?: [string];
                  };
                } = {
                  variables: {
                    thisHappendEarlierLocation:
                      values.thisHappendEarlierLocation,
                    sponsorLogoLocation: values.sponsorLogoLocation,
                    iffrDailyImageLocation: values.iffrDailyImageLocation,
                    campaignFilesLocation: values.campaignFilesLocation,
                    narrowCastingImagesLocation:
                      values.narrowCastingImagesLocation,
                    instagramHashtags: values.instagramHashtags,
                    comingUpLocation: values.comingUpLocation,
                    laterAndStillAvailableLocation:
                      values.laterAndStillAvailableLocation,
                    audienceAwardsLocation: values.audienceAwardsLocation,
                    deletedTweets: values.deletedTweets
                  }
                };

                if (newObject) {
                  // create new
                  try {
                    await executeQuery(
                      CREATE_VARIABLES,
                      { data },
                      'createVariables'
                    );
                  } catch (e) {
                    console.log(e);
                  }
                } else {
                  // update existing
                  data = {
                    id: 1,
                    ...data
                  };

                  try {
                    await executeQuery(
                      EDIT_VARIABLES,
                      { data },
                      'editVariables'
                    );
                  } catch (e) {
                    console.log(e);
                  }
                }

                actions.setSubmitting(false);
              }}
              validate={() => {}}
              render={({ values, isSubmitting }) => {
                console.log(values);

                return (
                  <Form>
                    <FormField
                      label="This happened earlier"
                      placeholder="Location"
                      name="thisHappendEarlierLocation"
                      type="text"
                    />
                    <FormField
                      label="Sponsor Logos folder"
                      placeholder="Location"
                      name="sponsorLogoLocation"
                      type="text"
                    />
                    <FormField
                      label="IFFR Daily Images folder"
                      placeholder="Location"
                      name="iffrDailyImageLocation"
                      type="text"
                    />
                    <FormField
                      label="Campaign files folder"
                      placeholder="Location"
                      name="campaignFilesLocation"
                      type="text"
                    />
                    <FormField
                      label="Narrow Casting Images folder"
                      placeholder="Location"
                      name="narrowCastingImagesLocation"
                      type="text"
                    />
                    <FormField
                      label="Coming Up Location"
                      placeholder="Location"
                      name="comingUpLocation"
                      type="text"
                    />
                    <FormField
                      label="Later and Still available Location"
                      placeholder="Location"
                      name="laterAndStillAvailableLocation"
                      type="text"
                    />
                    <FormField
                      label="Audience Awards Location"
                      placeholder="Location"
                      name="audienceAwardsLocation"
                      type="text"
                    />
                    <FormField
                      label="Instagram Hashtags"
                      placeholder="Instagram Hashtags"
                      name="instagramHashtags"
                      type="text"
                    />
                    <div
                      style={{
                        color: '#fff',
                        fontFamily: 'Cabin',
                        fontStyle: 'italic',
                        margin: '8px 0 8px 10px'
                      }}
                    >
                      Seperate values with a comma (e.g. iffr,iffr2020,etc)
                    </div>
                    {superUser && (
                      <>
                        <br />
                        <br />
                        <FormField
                          label="Deleted Tweets"
                          placeholder="Deleted Tweets"
                          name="deletedTweets"
                          type="array"
                          arrayValues={values.deletedTweets}
                        />
                      </>
                    )}
                    <div
                      style={{
                        width: '100%',
                        textAlign: 'center',
                        margin: '20px 0 0 0'
                      }}
                    >
                      <Button type="submit" disabled={isSubmitting}>
                        Update
                      </Button>
                    </div>
                  </Form>
                );
              }}
            />
          </div>
        )}
      </Container>
    </Auth>
  );
};
