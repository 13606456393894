import { executeQuery } from '../../../utils/fetchHelper';
import { EDIT_CUSTOMBLOCK_BY_ID_TEMPLATE } from '../components/DraggableCustomBlock/mutations/EditCustomBlockById';
import { EDIT_PREDEFINEDBLOCK_BY_ID_TEMPLATE } from '../components/DraggablePredefinedBlock/mutations/EditPredefinedBlockByIdTemplate';
import { IBlock } from '../types/IBlock';
import { IPredefinedBlock } from '../types/IPredefinedBlock';

/**
 *
 * When a global block's position is saved, the block's static value is set to true
 * and the front end's CSS is updated to disable dragging and resizing.
 *
 * @param blockType
 * @param block
 * @param predefinedBlock
 * @param dashboardId
 */
export const onSavePosition = async (
  blockType: string,
  block: IBlock | null,
  predefinedBlock: IPredefinedBlock | null,
  dashboardId: number
): Promise<void> => {
  let el: HTMLElement | null;

  if (blockType === 'customblock') {
    const data = {
      customBlockId: parseInt(block!.id),
      dashboardId: dashboardId,
      data: block!.content.data,
      position: {
        x: block!.position.x,
        y: block!.position.y,
        minW: block!.position.w,
        maxW: block!.position.w,
        w: block!.position.w,
        minH: block!.position.h,
        maxH: block!.position.h,
        h: block!.position.h
      }
    };
    await executeQuery(
      EDIT_CUSTOMBLOCK_BY_ID_TEMPLATE,
      { data },
      'editCustomBlockById'
    );
    el = document.getElementById(block!.id);
  } else if (blockType === 'predefinedBlock') {
    const data = {
      predefinedBlockId: parseInt(predefinedBlock!.id),
      dashboardId: `${dashboardId}`,
      position: {
        x: predefinedBlock!.position.x,
        y: predefinedBlock!.position.y,
        minW: predefinedBlock!.position.w,
        maxW: predefinedBlock!.position.w,
        w: predefinedBlock!.position.w,
        minH: predefinedBlock!.position.h,
        maxH: predefinedBlock!.position.h,
        h: predefinedBlock!.position.h
      }
    };
    await executeQuery(
      EDIT_PREDEFINEDBLOCK_BY_ID_TEMPLATE,
      { data },
      'editPredefinedBlockById'
    );
    el = document.getElementById(`predef-${predefinedBlock!.id}`);
  }

  el!.classList.remove('react-resizeable');

  el!.childNodes.forEach((childEl, i: number) =>
    i === 1 ? childEl.remove() : null
  );
};
