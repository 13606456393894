import React from 'react';
import { EDIT_DASHBOARD_SWIPE_ANIMATION_BY_ID } from './mutations/editDashboardSwipeAnimationById';
import { executeQuery } from '../../../../utils/fetchHelper';

interface Props {
  dashboardId: number;
  swipeAnimationActive: boolean;
}

export const SwipeAnimationSwitch: React.FC<Props> = props => {
  return (
    <div
      style={{
        color: '#fff',
        textAlign: 'center',
        fontFamily: 'Cabin, sans-serif'
      }}
    >
      <label htmlFor="template">Swipe Animation Active:</label>
      &nbsp;
      <input
        id="template"
        type="checkbox"
        checked={props.swipeAnimationActive}
        onChange={async () => {
          await executeQuery(
            EDIT_DASHBOARD_SWIPE_ANIMATION_BY_ID,
            {
              id: props.dashboardId
            },
            'editDashboardSwipeAnimationById'
          );
        }}
      ></input>
    </div>
  );
};
