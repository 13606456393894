import React, { useEffect, useState } from 'react';
import { useSubscription } from 'react-apollo';
import { RouteComponentProps } from 'react-router';
import GridLayout from 'react-grid-layout';

// Components
import { Nav } from '../../components/Nav';
import Modal from './components/Modal/Modal';
import AvailableBlocksList from './components/AvailableBlocksList/AvailableBlocksList';
import DraggableCustomBlock from './components/DraggableCustomBlock/DraggableCustomBlock';
import DraggablePredefinedBlock from './components/DraggablePredefinedBlock/DraggablePredefinedBlock';
import DFHPlaceholder from './components/DragFromHerePlaceholder/DragFromHerePlaceholder';
// Interfaces
import { IEditBlockParams } from './types/IEditBlockParams';
import { IBlock } from './types/IBlock';
import { IPredefinedBlock } from './types/IPredefinedBlock';
import { IBlockType } from './types/IBlockType';
// Styled
import {
  Container,
  Header,
  BackButton,
  TopBar,
  Loading,
  DynamicSaveButton,
  DeleteButton,
  ErrorMessageStyle
} from '@iffr-dashboard-admin/ui';
// Queries and mutations
import { DASHBOARD_BY_ID_TEMPLATE } from './queries/DashboardByIdTemplate';
import { ALL_GLOBAL_PREDEFINEDBLOCKS_BY_TYPE } from './components/Modal/components/GlobalLocalSwitch/queries/AllGlobalPredefinedBlocksByType';
import { GLOBAL_CUSTOMBLOCKS_FOR_BLOCKTYPE_BY_ID } from './components/Modal/components/GlobalLocalSwitch/queries/GlobalCustomBlocksForBlockTypeById';
// Utils
import { executeQuery } from '../../utils/fetchHelper';
import { onSavePosition } from './utils/onSavePosition';
// types
import { IModal } from './types/modal.interface';
import { createTwitterBlock } from './utils/createTwitterBlock';
import { createThisHappenedEarlierBlock } from './utils/createThisHappenedEarlierBlock';
import { contentlessBlocks } from '../../utils/contentlessBlocks';
import { updateName } from './utils/updateName';
import { createInstagramBlock } from './utils/createInstagramBlock';
import { Auth } from '../../components/Auth/Auth';
import { createTopTenListBlock } from './utils/createTopTenListBlock';
import { createIffrLogoBlock } from './utils/createIffrLogoBlock';
import { LIVE_DASHBOARD_BY_ID } from './subscriptions/liveDashboardById';
import { TemplateOptions } from './components/TemplateOptions/TemplateOptions';
import { updateBlockLayout } from './utils/updateBlockLayout';
import { SwipeAnimationSwitch } from './components/SwipeAnimationSwitch/SwipeAnimationSwitch';

const theme = 'dark';

interface IDashboard {
  id?: string;
  name?: string;
  blocks?: Array<IBlock>;
  predefinedBlocks: Array<IPredefinedBlock>;
  customBlocks: Array<any>;
  isTemplate?: boolean;
  swipeAnimation?: boolean;
  templateDashboardId?: number;
}

/**
 * Dashboard detail page
 * @param props
 */
const DashboardDetail: React.FC<RouteComponentProps> = (props): JSX.Element => {
  // id from url parameters
  const { id }: IEditBlockParams = props.match.params;
  const dashboardId: number = parseInt(id!);

  const [blockType, setBlockType] = useState<IBlockType>({ id: '', name: '' });
  const [dashboard, setDashboard] = useState<IDashboard>({
    customBlocks: [],
    predefinedBlocks: []
  });
  const [loading, setLoading] = useState(true);
  const [loadingChanges, setLoadingChanges] = useState(false);
  const [modal, setModal] = useState<IModal>({
    type: '',
    open: false
  });
  const [deletingBlock, setDeletingBlock] = useState<any>({});
  const [nameEditable, setNameEditable] = useState(false);
  const [refreshed, setRefreshed] = useState(true);
  const [layout, setLayout] = useState();

  const dashboardSubscription = useSubscription(LIVE_DASHBOARD_BY_ID, {
    variables: { id: dashboardId }
  });

  useEffect(() => {
    if (
      dashboardSubscription.data &&
      dashboardSubscription.data.liveDashboardById
    ) {
      const dashboardData = dashboardSubscription.data.liveDashboardById;

      if (dashboardData.customBlocks)
        dashboardData.customBlocks.forEach((block: any) => {
          block.predefined = false;
        });

      if (dashboardData.predefinedBlocks)
        dashboardData.predefinedBlocks.forEach((predefinedBlock: any) => {
          predefinedBlock.predefined = true;
        });

      setDashboard(dashboardData);
    }
  }, [dashboardSubscription.data]);

  /**
   * Fetch dashboard and blockType data and save it in state
   */
  useEffect(() => {
    const fetchData = async () => {
      if (loading) {
        const dashboardData = await executeQuery(
          DASHBOARD_BY_ID_TEMPLATE,
          { id: dashboardId },
          'dashboardById'
        );

        if (dashboardData.customBlocks)
          dashboardData.customBlocks.forEach((block: any) => {
            block.predefined = false;
          });

        if (dashboardData.predefinedBlocks)
          dashboardData.predefinedBlocks.forEach((predefinedBlock: any) => {
            predefinedBlock.predefined = true;
          });

        setDashboard(dashboardData);
        setLoading(false);
      }
    };
    fetchData();
  }, [dashboardId, loading]);

  /**
   * Open Modal to currently added block
   * @param block
   */
  const onAddBlock = (blockType: IBlockType) => {
    switch (blockType.id) {
      case 'predef-2': {
        createThisHappenedEarlierBlock(dashboardId);
        break;
      }
      case 'predef-5': {
        createTwitterBlock(dashboardId);
        break;
      }
      case 'predef-7': {
        createInstagramBlock(dashboardId);
        break;
      }
      case 'predef-8': {
        createTopTenListBlock(dashboardId);
        break;
      }
      case 'predef-9': {
        createIffrLogoBlock(dashboardId);
        break;
      }
      default: {
        setBlockType(blockType);
        setModal({ type: 'newBlock', open: true });
      }
    }
  };

  /**
   * Remove block
   * @param item
   */
  const onRemoveBlock = async (block: IBlock | IPredefinedBlock) => {
    let scenario = 0;

    if (block.predefined) {
      if (block.scope === 'global') {
        // check if it's the last of the global
        const globalPredefinedBlocks = await executeQuery(
          ALL_GLOBAL_PREDEFINEDBLOCKS_BY_TYPE,
          {
            type: block.type
          },
          'allGlobalPredefinedBlocksByType'
        );
        if (globalPredefinedBlocks.length > 1) {
          scenario = 1; // one instance of global predefined
        } else {
          if (contentlessBlocks.indexOf(block.type) === -1) {
            scenario = 2; // last instance of global predefined
          } else {
            scenario = 1; // last instance of global predefined without Content i.e. Twitter
          }
        }
      } else if (block.scope === 'local') {
        scenario = 3; // one instance of local predefined
      }
    } else if (!block.predefined) {
      if (block.scope === 'global') {
        // check if it's the last of the global
        const blockType = await executeQuery(
          GLOBAL_CUSTOMBLOCKS_FOR_BLOCKTYPE_BY_ID,
          {
            id: parseInt((block as IBlock).blockType.id)
          },
          'globalCustomBlocksForBlockTypeById'
        );

        if (blockType.customBlocks.length > 1) {
          scenario = 4; // one instance of global custom
        } else {
          scenario = 5; // last instance of global custom
        }
      } else if (block.scope === 'local') {
        scenario = 6; // one instance of local custom
      }
    }

    setModal({ type: 'removeBlock', open: true, scenario });
    setDeletingBlock(block);
  };

  /**
   * Render the editable title
   */
  const renderTitle = () => {
    if (nameEditable) {
      return (
        <div>
          <input
            style={{
              color: '#fff',
              fontFamily: 'Cabin, sans-serif',
              padding: '10px 20px',
              fontSize: '2em',
              fontWeight: 'bold',
              textAlign: 'left',
              lineHeight: '30px',
              height: '100%',
              background: 'rgba(35, 35, 35, 1)',
              border: 'none',
              borderRadius: '5px'
            }}
            ref={(input: any) => {
              if (input) input.focus();
            }}
            onBlur={(e: any) => {
              setNameEditable(false);
              updateName(dashboardId, e.target.value);
              setDashboard({
                ...dashboard,
                name: e.target.value
              });
            }}
            defaultValue={dashboard.name}
          />
        </div>
      );
    } else {
      return (
        <Header
          style={{ width: '50%' }}
          onClick={() => setNameEditable(true)}
          edit
        >
          {dashboard.name}
        </Header>
      );
    }
  };

  const deleteDashboard = () => {
    setModal({ type: 'removeDashboard', open: true });
  };

  /**
   * Render
   */
  return loading ? (
    <Loading />
  ) : (
    <Auth history={props.history}>
      <Nav active="/create-dashboard" dashboardId={dashboardId} />
      <Container>
        <TopBar>
          <BackButton href={'/create-dashboard'} />
          {renderTitle()}
        </TopBar>
        <SwipeAnimationSwitch
          dashboardId={dashboardId}
          swipeAnimationActive={dashboard.swipeAnimation || false}
        />
        <TemplateOptions dashboard={dashboard} />
        {dashboard.templateDashboardId !== 0 && (
          <div style={{ textAlign: 'center', padding: '20px 0 10px' }}>
            <ErrorMessageStyle>
              Dashboard is currently using a template, below blocks are not
              used!
            </ErrorMessageStyle>
          </div>
        )}
        <div>
          <AvailableBlocksList onItemClick={onAddBlock} />
          <GridLayout
            style={{ background: theme === 'dark' ? 'rgba(25,25,25,1)' : '' }}
            onLayoutChange={newLayout => {
              // Check if there are changes in the layout, to set the refresh button active
              setLayout(newLayout);
              if (layout && refreshed) {
                setRefreshed(false);
              }
            }}
            autoSize={true}
            draggableHandle={'.dragHandle'}
            preventCollision={true}
            compactType={null}
            rowHeight={23.2} // 607.5
            maxRows={18}
            width={1350}
            cols={5}
            isDraggable={!loadingChanges}
            isResizable={!loadingChanges}
          >
            {dashboard.customBlocks &&
              dashboard.customBlocks.map((block: any) => (
                <div
                  className={
                    block.position.minW !== undefined &&
                    block.scope === 'global'
                      ? 'unresizable'
                      : ''
                  }
                  data-grid={block.position}
                  key={block.predefined ? `predef-${block.id}` : block.id}
                  id={block.predefined ? `predef-${block.id}` : block.id}
                >
                  {!block.predefined && (
                    <DraggableCustomBlock
                      data={block.content.data}
                      block={block}
                      onRemove={(block: IBlock) => {
                        onRemoveBlock(block);
                      }}
                      onSavePosition={() =>
                        onSavePosition('customblock', block, null, dashboardId)
                      }
                      setRefreshed={setRefreshed}
                      loadingChanges={false} // loadingChanges
                    />
                  )}
                </div>
              ))}
            {dashboard.predefinedBlocks &&
              dashboard.predefinedBlocks.map((block: any) => (
                <div
                  className={
                    block.position.minW !== undefined &&
                    block.scope === 'global'
                      ? 'unresizable'
                      : ''
                  }
                  data-grid={block.position}
                  key={`predef-${block.id}`}
                  id={`predef-${block.id}`}
                >
                  <DraggablePredefinedBlock
                    onRemove={(block: IPredefinedBlock) => {
                      onRemoveBlock(block);
                    }}
                    data={
                      contentlessBlocks.indexOf(block.type) === -1
                        ? block.content.data
                        : ''
                    }
                    block={block}
                    onSavePosition={() =>
                      onSavePosition(
                        'predefinedBlock',
                        null,
                        block,
                        dashboardId
                      )
                    }
                    setModal={setModal}
                    dashboardId={dashboardId}
                    setRefreshed={setRefreshed}
                    loadingChanges={false} //loadingChanges
                  />
                </div>
              ))}
          </GridLayout>
          <DFHPlaceholder
            allBlocks={[
              ...dashboard.customBlocks,
              ...dashboard.predefinedBlocks
            ]}
          />
        </div>
        <div style={{ width: '100%' }}>
          <DynamicSaveButton
            unsaved={!refreshed}
            handleSave={async () => {
              try {
                updateBlockLayout(
                  dashboardId,
                  [...dashboard.customBlocks, ...dashboard.predefinedBlocks],
                  layout,
                  setLoadingChanges
                );
              } catch (e) {
                throw new Error(e);
              }
              setRefreshed(true);
            }}
          >
            Update
          </DynamicSaveButton>
          <DeleteButton
            style={{
              display: 'inline-block',
              fontFamily: 'Cabin, sans-serif',
              fontSize: '1em',
              padding: '10px 15px',
              margin: '0 0 0 20px',
              transform: 'translateY(-40px)'
            }}
            onClick={() => deleteDashboard()}
          >
            Delete
          </DeleteButton>
          <div
            style={{
              fontFamily: 'Cabin, sans-serif',
              fontSize: '3em',
              color: '#fff',
              textAlign: 'center'
            }}
          >
            {loadingChanges && <Loading inline />}
          </div>
        </div>
      </Container>
      {modal.open && (
        <Modal
          history={props.history}
          location={props.location}
          match={props.match}
          setModal={setModal}
          modal={modal}
          dashboardId={dashboardId}
          dashboard={dashboard}
          allBlocks={[...dashboard.customBlocks, ...dashboard.predefinedBlocks]}
          blockType={blockType}
          deletingBlock={deletingBlock}
          setLoadingChanges={setLoadingChanges}
        />
      )}
    </Auth>
  );
};

export default React.memo(DashboardDetail);
