import React from 'react';
import {
  DashboardTitle,
  DashboardOverviewContainer,
  DashboardOverviewItem,
  Plus,
} from '@iffr-dashboard-admin/ui';

interface Props {
  narrowCastingDashboards: Array<any>;
  setFormActive: (formActive: boolean) => void;
}

export const NarrowCastingDashboardOverview: React.FC<Props> = props => {
  return (
    <div>
      <DashboardOverviewContainer>
        {props.narrowCastingDashboards.map((narrowCastingDashboard: any) => {
          return (
            <DashboardOverviewItem
              href={`narrow-casting-dashboard/${narrowCastingDashboard.id}`}
              key={narrowCastingDashboard.id}
            >
              <DashboardTitle>{narrowCastingDashboard.name}</DashboardTitle>
            </DashboardOverviewItem>
          );
        })}
        <DashboardOverviewItem
          key={'new'}
          onClick={() => props.setFormActive(true)}
          new
        >
          <Plus />
        </DashboardOverviewItem>
      </DashboardOverviewContainer>
    </div>
  );
};
