import React, { useEffect } from 'react';

// components
import { Nav } from '../../components/Nav';
// queries and Mutations
import { ALL_BLOCKTYPES } from './queries/AllBlockTypesTemplate';
// styled
import {
  Container,
  Header,
  BackButton,
  TopBar,
} from '@iffr-dashboard-admin/ui';

import { useState } from 'react';
import { executeQuery } from '../../utils/fetchHelper';
import { CreateBlockForm } from './components/CreateBlockForm/CreateBlockForm';
import { useSubscription } from 'react-apollo';
import { LIVE_ALL_BLOCKTYPES } from './subscriptions/liveAllBlockTypes';
import { BlockTypeOverview } from './components/BlockTypeOverview/BlockTypeOverview';
import { Auth } from '../../components/Auth/Auth';
import { RouteComponentProps } from 'react-router';

/**
 * Create a new BlockType, it takes just a name.
 * @constructor
 */
const CreateBlockType: React.FC<RouteComponentProps> = props => {
  const [blockTypes, setBlockTypes] = useState([{ id: 1, name: '. . .' }]);
  const [formActive, setFormActive] = useState(false);

  const { data, loading } = useSubscription(LIVE_ALL_BLOCKTYPES);

  useEffect(() => {
    const fetchData = async () => {
      const blockTypes = await executeQuery(
        ALL_BLOCKTYPES,
        null,
        'allBlockTypes'
      );
      setBlockTypes(blockTypes);
    };
    fetchData();
  }, []);

  return (
    <Auth history={props.history}>
      <CreateBlockForm active={formActive} setFormActive={setFormActive} />
      <Nav active={'/create-blocktype'} />
      <Container>
        <TopBar>
          <BackButton inactive />
          <Header>BlockType</Header>
        </TopBar>

        {loading ? (
          <BlockTypeOverview
            blockTypes={blockTypes}
            setFormActive={setFormActive}
          />
        ) : (
          <BlockTypeOverview
            blockTypes={data.liveAllBlockTypes}
            setFormActive={setFormActive}
          />
        )}
      </Container>
    </Auth>
  );
};

export default CreateBlockType;
