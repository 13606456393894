import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { useSubscription } from 'react-apollo';

import { NARROW_CASTING_DASHBOARD_BY_ID } from './queries/NarrowCastingDashboardById';
import { LIVE_NARROW_CASTING_DASHBOARD_BY_ID } from './subscriptions/liveNarrowCastingDashboardById';

import { Nav } from '../../components/Nav';
import {
  Container,
  Header,
  Loading,
  TopBar,
  BackButton,
  DeleteButton,
  ModalStyle
} from '@iffr-dashboard-admin/ui';
import { executeQuery } from '../../utils/fetchHelper';
import { Slides } from './components/Slides/Slides';
import { CreateNarrowCastingSlideForm } from './components/CreateNarrowCastingSlideForm/CreateNarrowCastingSlideForm';
import { Auth } from '../../components/Auth/Auth';
import { DeleteNarrowCastingDashboardModalContent } from './components/DeleteNarrowCastingDashboardModalContent/DeleteNarrowCastingDashboardModalContent';
import { updateName } from './utils/updateName';

interface Props extends RouteComponentProps {}

export const NarrowCastingDashboardDetail: React.FC<Props> = props => {
  // id from url parameters
  const { id }: { id?: string } = props.match.params;
  const dashboardId: number = parseInt(id!);

  const [narrowCastingDashboard, setNarrowCastingDashboard] = useState();
  const [initialLoading, setInitialLoading] = useState(true);
  const [formState, setFormState] = useState({
    active: false,
    type: ''
  });
  const [modal, setModal] = useState({
    open: false
  });
  const [nameEditable, setNameEditable] = useState(false);

  const { loading, data } = useSubscription(
    LIVE_NARROW_CASTING_DASHBOARD_BY_ID,
    {
      variables: { id: dashboardId }
    }
  );

  useEffect(() => {
    const fetchDashboard = async () => {
      const narrowCastingDashboard = await executeQuery(
        NARROW_CASTING_DASHBOARD_BY_ID,
        {
          id: dashboardId
        },
        'narrowCastingDashboardById'
      );

      setNarrowCastingDashboard(narrowCastingDashboard);
      setInitialLoading(false);
    };
    fetchDashboard();
  }, [dashboardId]);

  /**
   * Render the editable title
   */
  const renderTitle = () => {
    if (nameEditable) {
      return (
        <div>
          <input
            style={{
              color: '#fff',
              fontFamily: 'Cabin, sans-serif',
              padding: '10px 20px',
              fontSize: '2em',
              fontWeight: 'bold',
              textAlign: 'left',
              lineHeight: '30px',
              height: '100%',
              background: 'rgba(35, 35, 35, 1)',
              border: 'none',
              borderRadius: '5px'
            }}
            ref={(input: any) => {
              if (input) input.focus();
            }}
            onBlur={(e: any) => {
              setNameEditable(false);
              updateName(dashboardId, e.target.value);
              setNarrowCastingDashboard({
                ...narrowCastingDashboard,
                name: e.target.value
              });
            }}
            defaultValue={narrowCastingDashboard.name}
          />
        </div>
      );
    } else {
      return (
        <Header
          style={{ width: '50%' }}
          onClick={() => setNameEditable(true)}
          edit
        >
          {narrowCastingDashboard.name}
        </Header>
      );
    }
  };

  return (
    <Auth history={props.history}>
      <CreateNarrowCastingSlideForm
        formState={formState}
        setFormState={setFormState}
        dashboardId={dashboardId}
      />
      <Nav active={'/create-narrow-casting-dashboard'} />
      <Container>
        {initialLoading && <Loading />}
        {!initialLoading && loading && (
          <div>
            <TopBar>
              <BackButton href={'/create-narrow-casting-dashboard'} />
              {renderTitle()}
            </TopBar>
            <Slides
              narrowCastingDashboardId={dashboardId}
              slides={narrowCastingDashboard.slides}
              slidesOrder={narrowCastingDashboard.slidesOrder}
              setFormState={setFormState}
              dashboardId={dashboardId}
            />
          </div>
        )}
        {!loading && (
          <div>
            <TopBar>
              <BackButton href={'/create-narrow-casting-dashboard'} />
              {renderTitle()}
            </TopBar>
            <Slides
              narrowCastingDashboardId={dashboardId}
              slides={data.liveNarrowCastingDashboardById.slides}
              slidesOrder={data.liveNarrowCastingDashboardById.slidesOrder}
              setFormState={setFormState}
              dashboardId={dashboardId}
            />
          </div>
        )}
        <div>
          <DeleteButton
            style={{
              display: 'inline-block',
              fontFamily: 'Cabin, sans-serif',
              fontSize: '1em',
              padding: '10px 15px',
              margin: '20px 0 0 120px'
            }}
            onClick={() => setModal({ open: true })}
          >
            Delete
          </DeleteButton>
        </div>
      </Container>
      {modal.open && (
        <ModalStyle onClose={() => setModal({ open: false })}>
          <DeleteNarrowCastingDashboardModalContent
            history={props.history}
            deletingDashboard={dashboardId}
          />
        </ModalStyle>
      )}
    </Auth>
  );
};
