import React from 'react';

// components
import { IffrDailyContent } from '../components/IffrDailyContent/IffrDailyContent';
// type
import { IModal } from '../../../types/modal.interface';
import { ComingUpContent } from '../components/ComingUpContent/ComingUpContent';
import { SponsorLogosContent } from '../components/SponsorLogosContent/SponsorLogosContent';
import { CampaignContent } from '../components/CampaignContent/CampaignContent';
import InstagramContent from '../components/InstagramContent/InstagramContent';
import { TopTenListContent } from '../components/TopTenListContent/TopTenListContent';
import { IffrLogoContent } from '../components/IffrLogoContent/IffrLogoContent';
import { LaterStillAvailableContent } from '../components/LaterStillAvailableContent/LaterStillAvailableContent';
// import { ThisHappendEarlierContent } from '../components/ThisHappendEarlierContent/ThisHappendEarlierContent';

export const renderContent = (
  type: string,
  block: any,
  setModal: (modal: IModal) => void,
  dashboardId: number,
  setRefreshed: (refreshed: boolean) => void
) => {
  // console.log('renderContent');
  switch (type) {
    case 'iffr-daily': {
      return (
        <IffrDailyContent
          block={block}
          setModal={setModal}
          setRefreshed={setRefreshed}
        />
      );
    }
    case 'coming-up': {
      return (
        <ComingUpContent
          block={block}
          setModal={setModal}
          setRefreshed={setRefreshed}
        />
      );
    }
    case 'sponsor-logos': {
      return (
        <SponsorLogosContent
          block={block}
          dashboardId={dashboardId}
          setRefreshed={setRefreshed}
        />
      );
    }
    case 'campaign': {
      return (
        <CampaignContent
          block={block}
          dashboardId={dashboardId}
          setRefreshed={setRefreshed}
        />
      );
    }
    case 'instagram': {
      return <InstagramContent />;
    }
    case 'top-10-audience-awards': {
      return <TopTenListContent />;
    }
    case 'iffr-logo': {
      return <IffrLogoContent />;
    }
    case 'later-still-available': {
      return <LaterStillAvailableContent content={block.content} />;
    }
    // case "this-happened-earlier": {
    //   return <ThisHappendEarlierContent block={block} />;
    // }
    default: {
      return <div />;
    }
  }
};
