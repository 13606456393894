import React, { useEffect, useState } from 'react';
import { Dashboard } from '@uppy/react';
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';

import { cdnUrl } from '../../utils/cdnUrl';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

import {
  ChooseFileWindowContainer,
  FileList,
  FileListItem,
  FileUploadContainer,
  NewFileLink,
  BreadCrumbItem,
  FolderIcon
} from '@iffr-dashboard-admin/ui';
import { getVariables } from '../../utils/getVariables';

interface Props {
  open: boolean;
  fileUploaderOpen: boolean;
  path: string;
  onChoose: (file: string) => void;
  onClose: () => void;
  toggleThisIndex: () => void;
}

export const ChooseFileWindow: React.FC<Props> = props => {
  const [path, setPath] = useState<boolean | string>(false);
  const [images, setImages] = useState([]);
  const [directories, setDirectories] = useState([]);

  const uppy = Uppy({
    restrictions: {
      maxFileSize: 52428800,
      maxNumberOfFiles: 50,
      minNumberOfFiles: 1,
      allowedFileTypes: ['image/*', 'video/*']
    }
  });

  uppy.use(XHRUpload, {
    endpoint: `${cdnUrl}/upload`,
    method: 'post',
    formData: true,
    fieldName: 'file'
  });

  uppy.setMeta({
    path: path === '' ? '/' : path || '/'
  });

  uppy.on('complete', (result: any) => {
    props.onChoose(
      `${cdnUrl}/${result.successful[0].meta.path}${result.successful[0].meta.name}`
    );
    props.toggleThisIndex();
  });

  useEffect(() => {
    return () => {
      uppy.close();
    };
  });

  useEffect(() => {
    if (path === false) {
      return;
    }

    const fetchData = async () => {
      fetch(`${cdnUrl}/${path}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((res: any) => {
          return res.json();
        })
        .then((json: any) => {
          setDirectories(json.directories);
          setImages(json.files);
        });
    };
    fetchData();
  }, [path]);

  useEffect(() => {
    const fetchVars = async () => {
      const variablesRes = await getVariables();

      switch (props.path) {
        case 'iffr-daily':
          setPath(
            variablesRes ? variablesRes.iffrDailyImageLocation || '' : ''
          );
          break;
        case 'campaign':
          setPath(variablesRes ? variablesRes.campaignFilesLocation || '' : '');
          break;
        case 'narrow-casting':
          setPath(
            variablesRes ? variablesRes.narrowCastingImagesLocation || '' : ''
          );
          break;
        default:
          setPath('');
          break;
      }
    };
    fetchVars();
  }, [props.path]);

  const renderBreadCrumb = () => {
    if (typeof path === 'boolean') {
      return <span>/</span>;
    }

    let paths: Array<string> = [];

    if (path !== '' && path.substr(path.length - 1, 1) === '/') {
      const pathWOTrailingSlash = path.substr(0, path.length - 1);
      paths = pathWOTrailingSlash.split('/');
    } else {
      paths = path.split('/');
    }

    return (
      <span>
        <BreadCrumbItem>&nbsp;/&nbsp;</BreadCrumbItem>
        <BreadCrumbItem
          link={paths.length > 0}
          onClick={() => (paths.length > 0 ? setPath('') : '')}
        >
          home
        </BreadCrumbItem>
        {paths.map((pathPart: string, i: number) => (
          <span key={i}>
            <BreadCrumbItem>&nbsp;/&nbsp;</BreadCrumbItem>
            <BreadCrumbItem
              onClick={() => {
                if (i < paths.length - 1) {
                  paths.pop();
                  setPath(paths.join('/'));
                }
              }}
              link={i !== paths.length - 1}
            >
              {pathPart}
            </BreadCrumbItem>
          </span>
        ))}
      </span>
    );
  };

  return (
    <div>
      <ChooseFileWindowContainer open={props.open}>
        <div
          style={{
            padding: '0 20px',
            margin: '5px 0px',
            fontFamily: 'Cabin, sans-serif',
            fontWeight: 'bold'
          }}
        >
          {renderBreadCrumb()}
        </div>
        <FileList>
          {images.map((image: any, i: number) => (
            <FileListItem
              key={i}
              onClick={() =>
                props.onChoose(`${cdnUrl}/${image.path}${image.name}`)
              }
            >
              {image.name.indexOf('.mp4') === -1 && (
                <div
                  style={{
                    borderRadius: '10px',
                    width: '50px',
                    height: '50px',
                    background: `url("${cdnUrl}/thumbnails/${image.path}${image.name}") center center / cover`
                  }}
                />
              )}
              {image.name.indexOf('.mp4') !== -1 && (
                <video
                  autoPlay
                  muted
                  loop
                  style={{
                    width: '50px',
                    alignSelf: 'center',
                    borderRadius: '5px'
                  }}
                >
                  <source
                    src={`${cdnUrl}/${image.path}${image.name}`}
                    type="video/mp4"
                  />
                  Your browser does not support HTML5 video.
                </video>
              )}
              <div
                style={{
                  alignSelf: 'center',
                  fontFamily: 'Cabin, sans-serif',
                  margin: '0 0 0 5px'
                }}
              >
                {image.name}
              </div>
            </FileListItem>
          ))}
          {directories.map((directory: any, i: number) => (
            <FileListItem
              key={i}
              onClick={() =>
                setPath(
                  directory.path !== '/'
                    ? directory.path + directory.machineName
                    : directory.machineName + '/'
                )
              }
            >
              <div
                style={{
                  width: '50px',
                  height: '50px'
                }}
              >
                <FolderIcon />
              </div>
              <div
                style={{
                  alignSelf: 'center',
                  fontFamily: 'Cabin, sans-serif',
                  margin: '0 0 0 5px'
                }}
              >
                {directory.name}
              </div>
            </FileListItem>
          ))}
          <NewFileLink
            onClick={() => {
              props.toggleThisIndex();
              props.onClose();
            }}
          >
            Or upload a new image.
          </NewFileLink>
        </FileList>
      </ChooseFileWindowContainer>
      <FileUploadContainer open={props.fileUploaderOpen}>
        <Dashboard uppy={uppy} />
      </FileUploadContainer>
    </div>
  );
};
