import React, { useEffect, useState } from 'react'
import Cookies from 'universal-cookie'
import moment from 'moment'
import { FacebookLoginButton } from 'react-social-login-buttons'

// compontents
import { Nav } from '../../components/Nav'
// styles
import {
  Container,
  Header,
  Button,
  InstagramCheckContainer,
  InstagramCheckbox,
  InstagramCheckmark,
  InstagramContainer,
  InstagramImage,
  Paragraph,
  HashtagsContainer,
  Hashtag
} from '@iffr-dashboard-admin/ui'
import { executeQuery } from '../../utils/fetchHelper'
import { REFRESH_INSTAGRAM } from './mutations/refreshInstagram'
import { ALL_INSTAGRAM } from './queries/AllInstagram'
import { UPDATE_INSTAGRAM_STATUS } from './mutations/updateInstagramStatus'
import { Auth } from '../../components/Auth/Auth'
import { RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import { getVariables } from '../../utils/getVariables'

const cookies = new Cookies()

export const Instagram: React.FC<RouteComponentProps> = (props) => {
  const [instagramImages, setInstagramImages] = useState([])
  const [checked, setChecked] = useState([])
  const [FACEBOOK, setFACEBOOK] = useState()
  const [errorMessage, setErrorMessage] = useState('')
  const [hashtags, setHashtags] = useState([])
  const [instagramUpdating, setInstagramUpdating] = useState(false)
  const [updatingMessage, setUpdatingMessage] = useState(
    'Updating Instagram images...'
  )

  const accessTokenActive = moment(cookies.get('expiresAt'))
    .subtract('10', 'minutes')
    .isAfter(moment())

  const [fbAuthenticated, setFbAuthenticated] = useState(accessTokenActive)

  useEffect(() => {
    const fetchHastags = async () => {
      const variables = await getVariables()
      setHashtags(variables ? variables.instagramHashtags.split(',') : [])
    }
    fetchHastags()
  }, [])

  useEffect(() => {
    const fetchInstagram = async () => {
      const res = await executeQuery(ALL_INSTAGRAM, {}, 'allInstagram')
      setInstagramImages(res)
      setChecked(res.map((mediaItem: any) => mediaItem.status))
    }
    fetchInstagram()
  }, [])

  useEffect(() => {
    // @ts-ignore
    if (!FACEBOOK && window.FB) {
      // @ts-ignore
      setFACEBOOK(window.FB)
    }

    document.addEventListener('FBObjectReady', initializeFacebookLogin)

    // @ts-ignore
    window.fbAsyncInit = function() {
      // @ts-ignore
      FB.init({
        appId: '2150442985256646',
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v5.0'
      })

      // Broadcast an event when FB object is ready
      var fbInitEvent = new Event('FBObjectReady')
      document.dispatchEvent(fbInitEvent)
    }
    ;(function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      // @ts-ignore
      fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'facebook-jssdk')
  }, [FACEBOOK])

  const initializeFacebookLogin = () => {
    // @ts-ignore
    setFACEBOOK(window.FB)
  }

  const facebookLoginHandler = (response: any) => {
    if (response.status === 'connected') {
      ;(FACEBOOK as any).api('/me/accounts', (accountData: any) => {
        // Save login data to cookies
        if (response)
          if (response.authResponse) {
            cookies.set(
              'expiresAt',
              moment()
                .add(response.authResponse.expiresIn, 'seconds')
                .format('YYYY-MM-DD HH:mm:ss')
            )
            cookies.set('accessToken', response.authResponse.accessToken, {
              path: '/instagram'
            })
            cookies.set('userID', response.authResponse.userID, {
              path: '/instagram'
            })
          }

        setFbAuthenticated(true)
      })
    } else {
      console.log('no deal no dice')
    }
  }

  const login = () => {
    if (!FACEBOOK) return
    ;(FACEBOOK as any).getLoginStatus((response: any) => {
      if (response.status === 'connected') {
        facebookLoginHandler(response)
      } else {
        ;(FACEBOOK as any).login(facebookLoginHandler, {
          scope: 'public_profile,instagram_basic,pages_show_list'
        })
      }
    })
  }

  const updateMedia = async () => {
    setInstagramUpdating(true)

    console.log('Getting page ID', cookies.get('accessToken'))

    console.log(FACEBOOK)

    // First get the facebook page id, to which the instagram account is linked
    const getPageId = new Promise((resolve, reject) => {
      if (FACEBOOK) {
        ;(FACEBOOK as any).api(
          `/me/accounts?access_token=${cookies.get('accessToken')}`,
          async (accountData: any) => {
            if (accountData && accountData.data) {
              const iffrPage = await accountData.data.find((page: any) => {
                return page.name === 'International Film Festival Rotterdam'
              })

              if (iffrPage) {
                resolve(iffrPage.id)
              } else {
                setErrorMessage('No linked facebook page found')
                reject('No linked facebook page found')
              }
            } else {
              console.log(accountData)
              reject('Facebook session expired, please refresh and login again')
            }
          }
        )
      }
    })

    // Then get the Instagram account's business ID
    const getBusinessId = async (pageId: number) =>
      await new Promise((resolve, reject) => {
        ;(FACEBOOK as any).api(
          `/${pageId}?fields=instagram_business_account&access_token=${cookies.get(
            'accessToken'
          )}`,
          async (pageData: any) => {
            if (pageData) {
              resolve(pageData.instagram_business_account.id)
            } else {
              setErrorMessage('Error while obtaining Business Id')
              reject('Error while obtaining Business Id')
            }
          }
        )
      })

    // Get all the hashtags
    const getHashtagId = async (businessId: number, hashtag: string) =>
      await new Promise((resolve, reject) => {
        ;(FACEBOOK as any).api(
          `/ig_hashtag_search?user_id=${businessId}&q=${hashtag}&access_token=${cookies.get(
            'accessToken'
          )}`,
          async (hashtagData: any) => {
            if (hashtagData) {
              resolve(hashtagData.data[0].id)
            } else {
              setErrorMessage(`Can't find Hashtag: ${hashtag}`)
              reject(`Can't find Hashtag: ${hashtag}`)
            }
          }
        )
      })

    // Then get all media, connected to this instagram account
    const getMedia = async (businessId: number, hashtagId: string) =>
      await new Promise((resolve, reject) => {
        ;(FACEBOOK as any).api(
          `/${hashtagId}/recent_media?user_id=${businessId}&fields=id,media_url,media_type&access_token=${cookies.get(
            'accessToken'
          )}`,
          async (mediaData: any) => {
            if (mediaData) {
              resolve(mediaData.data)
            } else {
              setErrorMessage('Error while obtaining Media')
              reject('Error while obtaining Media')
            }
          }
        )
      })

    try {
      const pageId = await getPageId
      const businessId = await getBusinessId(pageId as number)

      const hashtagIds = await Promise.all(
        hashtags.map(
          async (hashtag: string) =>
            (await getHashtagId(businessId as number, hashtag)) as string
        )
      )

      let newMedia: Array<any> = []

      await Promise.all(
        hashtagIds.map(async (hashtagId: string) => {
          const mediaData: any = await getMedia(businessId as number, hashtagId)

          mediaData.forEach((mediaItem: any) => {
            newMedia.push(mediaItem)
          })
        })
      )

      const data = {
        accessToken: cookies.get('accessToken'),
        newMedia
      }

      const refRes = await executeQuery(
        REFRESH_INSTAGRAM,
        { data },
        'refreshInstagram'
      )

      setUpdatingMessage(refRes)
      setTimeout(async () => {
        setInstagramUpdating(false)
        setUpdatingMessage('Updating Instagram images...')

        const res = await executeQuery(ALL_INSTAGRAM, {}, 'allInstagram')
        setInstagramImages(res)
        setChecked(res.map((mediaItem: any) => mediaItem.status))
      }, 500)
    } catch (e) {
      console.log(e)
    }
  }

  const handleCheck = async (instagramId: string, i: number) => {
    const data = { instagramId: parseInt(instagramId), newStatus: !checked[i] }

    try {
      await executeQuery(
        UPDATE_INSTAGRAM_STATUS,
        { data },
        'updateInstagramStatus'
      )
    } catch (e) {
      throw new Error(e as any)
    }

    let newChecked = [...checked]
    newChecked[i] = !checked[i] as never
    setChecked(newChecked)
  }

  const renderInstagramImages = () => {
    return instagramImages.map((mediaItem: any, i: number) => {
      return (
        <InstagramContainer key={mediaItem.id}>
          <InstagramCheckContainer>
            <InstagramCheckbox
              type='checkbox'
              defaultChecked={checked[i]}
              onClick={() => {
                handleCheck(mediaItem.id, i)
              }}
            />
            <InstagramCheckmark checked={checked[i]} viewBox='0 0 50 50'>
              <polyline points='10,25 20,35 40,15' />
            </InstagramCheckmark>
          </InstagramCheckContainer>
          {mediaItem.mediaType === 'VIDEO' && (
            <video
              autoPlay
              muted
              loop
              style={{
                width: '200px',
                alignSelf: 'center',
                borderRadius: '5px'
              }}
            >
              <source src={mediaItem.fileUrl} type='video/mp4' />
              Your browser does not support HTML5 video.
            </video>
          )}
          {(mediaItem.mediaType === 'IMAGE' ||
            mediaItem.mediaType === 'CAROUSEL_ALBUM') && (
            <InstagramImage
              src={mediaItem.fileUrl}
              width='200'
              alt='instagram'
              checked={checked[i]}
            />
          )}
        </InstagramContainer>
      )
    })
  }

  return (
    <Auth history={props.history}>
      <Nav active='/instagram' />
      <Container>
        <Header>Instagram</Header>
        <div
          style={{
            color: 'red',
            textAlign: 'center',
            fontFamily: 'Cabin, sans-serif'
          }}
        >
          {errorMessage}
        </div>
        <div style={{ width: '100%', textAlign: 'center' }}>
          {!fbAuthenticated && (
            <FacebookLoginButton
              style={{ width: 'auto', margin: '0 auto' }}
              onClick={login}
            />
          )}
          {fbAuthenticated && (
            <>
              <HashtagsContainer>
                {hashtags.map((hashtag: string) => {
                  return <Hashtag key={hashtag}>#{hashtag}</Hashtag>
                })}
              </HashtagsContainer>
              <Paragraph style={{ color: '#fff' }}>
                Edit the hashtags in the{' '}
                <Link
                  style={{ color: '#fff', textDecoration: 'underline' }}
                  to={'/variables'}
                >
                  variables
                </Link>{' '}
                menu.
              </Paragraph>
              <Button onClick={updateMedia} disabled={hashtags.length === 0}>
                Update media
              </Button>
              {instagramUpdating && (
                <Paragraph style={{ color: '#fff' }}>
                  {updatingMessage}
                </Paragraph>
              )}
            </>
          )}
        </div>
        <div
          style={{
            margin: '20px 0 0 0',
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gridGap: '20px'
          }}
        >
          {renderInstagramImages()}
        </div>
      </Container>
    </Auth>
  )
}
