import gql from 'graphql-tag';

export const LIVE_DASHBOARD_BY_ID = gql`
  subscription LiveDashboardById($id: Float!) {
    liveDashboardById(id: $id) {
      id
      name
      isTemplate
      swipeAnimation
      templateDashboardId
      customBlocks {
        id
        name
        scope
        blockType {
          id
          name
          fields {
            name
            inputType
            index
          }
        }
        position
        content {
          id
          data
        }
      }
      predefinedBlocks {
        id
        name
        scope
        type
        content {
          id
          data
        }
        position
      }
    }
  }
`;
