import gql from 'graphql-tag';

export const ALL_AUDIENCE_AWARDS_FILMS_LIVE = gql`
  subscription {
    allAudienceAwardsFilmsLive {
      id
      title
      description
      fionaId
      averageScore
      rank
      previousRank
      headerImage
      director
      runTime
      showLocation
      showTime
      ticketsAvailable
      detailViewAvailable
      detailViewActive
    }
  }
`;
