import React from 'react';
import { Header, Button } from '@iffr-dashboard-admin/ui';
import { executeQuery } from '../../../../utils/fetchHelper';
import { DELETE_NARROW_CASTING_DASHBOARD_BY_ID } from './mutations/deleteNarrowCastingDashboardById';

interface Props {
  history: any;
  deletingDashboard: number;
}

export const DeleteNarrowCastingDashboardModalContent: React.FC<
  Props
> = props => {
  return (
    <>
      <Header center>Are you sure?</Header>
      <div style={{ textAlign: 'center', fontFamily: 'Cabin, sans-serif' }}>
        <div>
          You are about to delete this NARROW CASTING DASHBOARD and all of its
          LOCAL SLIDES
        </div>
        <Button
          onClick={async () => {
            await executeQuery(
              DELETE_NARROW_CASTING_DASHBOARD_BY_ID,
              { id: props.deletingDashboard },
              'deleteNarrowCastingDashboardById'
            );
            props.history.push('/create-narrow-casting-dashboard');
          }}
        >
          Yes
        </Button>
      </div>
    </>
  );
};
