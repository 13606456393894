import React from "react";
import { DashboardOverviewContainer, DashboardOverviewItem, DashboardTitle, Plus } from '@iffr-dashboard-admin/ui';

interface Props {
  blockTypes: Array<any>;
  setFormActive: (formActive: boolean) => void;
}

export const BlockTypeOverview: React.FC<Props> = props => {
  return <DashboardOverviewContainer>
    {props.blockTypes.map((blockType: any) => {
      return (
        <DashboardOverviewItem
          key={blockType.id}
          href={`/blocktype/${blockType.id}`}
        >
          <DashboardTitle>{blockType.name}</DashboardTitle>
        </DashboardOverviewItem>
      );
    })}
    <DashboardOverviewItem
      key={'new'}
      onClick={() => props.setFormActive(true)}
      new
    >
      <Plus />
    </DashboardOverviewItem>
  </DashboardOverviewContainer>
}