import React from 'react';
import { FormBlock, Button } from '@iffr-dashboard-admin/ui';
import { Formik, FormikActions, Form } from 'formik';
import { ICreateNarrowCastingSlideFormValues } from '../../types/ICreateNarrowCastingSlideFormValues';
import { executeQuery } from '../../../../../../utils/fetchHelper';
import { CREATE_NARROW_CASTING_SLIDE } from '../../mutations/CreateNarrowCastingSlide';
import { FormField } from '../../../../../../components/FormField/FormField';

interface Props {
  slideScope: string;
  dashboardId: number;
  setFormState: (formState: { type: string; active: boolean }) => void;
}

export const NewSlideForm: React.FC<Props> = props => {
  return (
    <Formik
      initialValues={{ name: '', timeActiveInSeconds: 15 }}
      onSubmit={async (
        values: ICreateNarrowCastingSlideFormValues,
        actions: FormikActions<ICreateNarrowCastingSlideFormValues>
      ) => {
        const data = {
          name: values.name,
          timeActiveInSeconds: values.timeActiveInSeconds,
          scope: props.slideScope,
          dashboardId: props.dashboardId
        };

        try {
          await executeQuery(
            CREATE_NARROW_CASTING_SLIDE,
            { data },
            'createNarrowCastingSlide'
          );
        } catch (e) {
          throw new Error(e);
        }

        actions.setSubmitting(false);
        props.setFormState({ active: false, type: '' });
      }}
      validate={(values: ICreateNarrowCastingSlideFormValues) => {
        let errors: { name?: string; scope?: string } = {};
        if (!values.name) errors.name = 'required';
        return errors;
      }}
      render={({ isSubmitting }) => (
        <Form>
          <FormField
            label="Name"
            placeholder="Name"
            name="name"
            type="text"
            narrow
          />
          <FormField
            label="Time Active (in seconds)"
            placeholder="15"
            name="timeActiveInSeconds"
            type="number"
            narrow
          />
          <FormBlock narrow>
            <div />
            <div />
            <Button type="submit" disabled={isSubmitting}>
              Create
            </Button>
          </FormBlock>
        </Form>
      )}
    />
  );
};
