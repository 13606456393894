import React, { useState, useEffect } from 'react';
import {
  Header,
  ComingUpContainer,
  SearchListContainer,
  FormBlock,
  Label,
  Input,
  SearchList,
  SearchListItem,
  SearchListItemText,
  CurrentEventsContainer,
  CurrentShow,
  Loading,
  CurrentShowTitle,
  CurrentShowSection,
  CurrentShowImage,
  CurrentShowLocation,
  CurrentShowTickets,
  CurrentShowBody,
  Button
} from '@iffr-dashboard-admin/ui';
import moment from 'moment';
import { apiUrl } from '../../../../../../../utils/apiUrl';
import { executeQuery } from '../../../../../../../utils/fetchHelper';
import { PREDEFINEDBLOCK_BY_ID } from '../ComingUpForm/queries/PredefinedBlockById';
import { EDIT_PREDEFINEDBLOCK_BY_ID_TEMPLATE } from '../../../../DraggablePredefinedBlock/mutations/EditPredefinedBlockByIdTemplate';

interface Props {
  dashboardId: number;
  setModal: (modal: { type: string; open: boolean }) => void;
  globalOrLocal: string;
  blockId: string;
}

export const EditLaterStillAvailableForm: React.FC<Props> = props => {
  const [allShows, setAllShows] = useState([]);
  const [filteredShows, setFilteredShows] = useState([]);
  const [currentShowId, setCurrentShowId] = useState({});
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [currentShow, setCurrentShow] = useState<any>({ show: false });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(`${apiUrl}/on-demand/${date}`);
        try {
          const jsondata = await res.json();
          setAllShows(jsondata);
          setFilteredShows(jsondata);
        } catch (e) {
          console.log(e);
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, [date]);

  useEffect(() => {
    const fetchData = async () => {
      if (currentShowId !== '') {
        let res: any;
        let jsondata: any;

        try {
          res = await fetch(`${apiUrl}/coming-up/detail/${currentShowId}`);
        } catch (e) {
          console.log(e);
        }

        try {
          jsondata = await res.json();
          if (currentShow.show) {
            setCurrentShow(jsondata);
          }
          setLoading(false);
        } catch (e) {
          console.log(e);
        }
      }
    };
    fetchData();
  }, [currentShowId, currentShow.show]);

  useEffect(() => {
    const fetchCurrentData = async () => {
      const block = await executeQuery(
        PREDEFINEDBLOCK_BY_ID,
        { id: props.blockId },
        'predefinedBlockById'
      );

      setCurrentShow({ show: block[0].content.data });
    };

    fetchCurrentData();
  }, [props.blockId]);

  const handleSearch = async (e: any) => {
    const newShows = await allShows.filter((show: any) => {
      return (
        show.fullTitle.toLowerCase().indexOf(e.target.value.toLowerCase()) !==
        -1
      );
    });
    setFilteredShows(newShows);
  };

  const editLaterStillAvailableBlock = async () => {
    const data = {
      predefinedBlockId: parseInt(props.blockId),
      data: currentShow.show
    };

    try {
      const res = await executeQuery(
        EDIT_PREDEFINEDBLOCK_BY_ID_TEMPLATE,
        { data },
        'editPredefinedBlockById'
      );

      console.log(res);
    } catch (e) {
      console.error(e);
    }

    props.setModal({ type: '', open: false });
  };

  return (
    <div>
      <Header center>Edit Later and Still Available</Header>
      <br />

      <ComingUpContainer>
        <SearchListContainer>
          <FormBlock>
            <Label>Date: </Label>
            <div />
            <Input
              type={'date'}
              defaultValue={date}
              onChange={e => setDate(e.target.value)}
            />
          </FormBlock>
          <Input placeholder="Search for..." onChange={handleSearch} />
          <SearchList>
            {filteredShows.map((show: any) => (
              <SearchListItem
                key={show.id}
                onClick={() => {
                  setLoading(true);
                  setCurrentShowId(show.id);
                }}
              >
                <SearchListItemText>{show.fullTitle}</SearchListItemText>
              </SearchListItem>
            ))}
          </SearchList>
        </SearchListContainer>

        <CurrentEventsContainer>
          {currentShow.show.title && (
            <CurrentShow>
              {loading && (
                <div style={{ height: '400px' }}>
                  <Loading inline center />
                </div>
              )}
              {!loading && (
                <div>
                  <CurrentShowTitle>
                    {currentShow.show.title}
                    {currentShow.show.section && (
                      <CurrentShowSection section={currentShow.show.section}>
                        {currentShow.show.section.replace(/-/g, ' ')}
                      </CurrentShowSection>
                    )}
                  </CurrentShowTitle>
                  <CurrentShowImage src={currentShow.show.image || ''}>
                    {!currentShow.show.image ? 'No image' : ''}
                  </CurrentShowImage>
                  <CurrentShowLocation>
                    {currentShow.show.location} -{' '}
                    {moment(currentShow.show.time).format('HH:mm')}
                    <CurrentShowTickets>
                      {currentShow.show.ticketsAvailable
                        ? 'Tickets available'
                        : 'No tickets available'}
                    </CurrentShowTickets>
                  </CurrentShowLocation>
                  <CurrentShowBody
                    dangerouslySetInnerHTML={{
                      __html: currentShow.show.text
                    }}
                  />
                </div>
              )}
            </CurrentShow>
          )}
          <div style={{ width: '100%', textAlign: 'center' }}>
            <Button onClick={editLaterStillAvailableBlock}>Edit</Button>
          </div>
        </CurrentEventsContainer>
      </ComingUpContainer>
    </div>
  );
};
