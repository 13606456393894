export const renderModalRemoveBlockMessage = (
  scenario: number,
  blockName: string
) => {
  let message;
  switch (scenario) {
    case 1:
    case 4:
      message = `Are you sure you would like to delete this instance of <b>${blockName}</b>? 
      Other global instances will not be deleted.`; // one instance of global predefined
      break;
    case 2:
    case 5:
      message = `Are you sure you would like to delete this last instance of <b>${blockName}</b>? 
      this is the last global block of it's type!`; // last instance of global predefined
      break;
    case 3:
    case 6:
      message = `Are you sure you would like to delete this instance of <b>${blockName}</b>?`; // one instance of global predefined
      break;
    default:
      message = `def`;
      break;
  }
  return message;
};
