import React, { useState, useEffect } from 'react';

// components
import { Nav } from '../../components/Nav';
import { BlockType } from './BlockType/BlockType';
// interfaces
import { RouteComponentProps } from 'react-router-dom';
import { IFieldsForTemplateParams } from './types/IFieldsForTemplateParams';
// queries and Mutations
import { BLOCKTYPE_BY_ID_TEMPLATE } from './queries/BlockTypeByIdTemplate';
// styled
import {
  Container,
  Header,
  FormAndListContainer,
  ListContainer,
  BackButton,
  TopBar,
  Loading,
  ModalStyle,
  DeleteButton
} from '@iffr-dashboard-admin/ui';
import { executeQuery } from '../../utils/fetchHelper';
import { IBlockType } from '../CreateBlockType/types/IBlockType';
import { EditBlockTypeForm } from './EditBlockTypeForm/EditBlockTypeForm';
import { Auth } from '../../components/Auth/Auth';
import { useSubscription } from 'react-apollo';
import { LIVE_BLOCKTYPE_BY_ID } from './subscriptions/liveBlockTypeById';
import { ModalContent } from './components/ModalContent/ModalContent';
import { updateName } from './utils/updateName';

/**
 * Add fields for templates
 *
 * Add fields to a template. Fields have a name and an inputType.
 *
 * @param props
 * @constructor
 */
const EditBlockType: React.FC<RouteComponentProps> = (props): JSX.Element => {
  // id from url parameters
  const { id }: IFieldsForTemplateParams = props.match.params;
  const blockTypeId = parseInt(id!);

  const [blockType, setBlockType] = useState<IBlockType>({
    id: '',
    name: '',
    fields: []
  });
  const [queryLoading, setQueryLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [nameEditable, setNameEditable] = useState(false);

  const { data, loading } = useSubscription(LIVE_BLOCKTYPE_BY_ID, {
    variables: { blockTypeId: blockTypeId }
  });

  useEffect(() => {
    const fetchData = async () => {
      const blockTypeById = await executeQuery(
        BLOCKTYPE_BY_ID_TEMPLATE,
        { id: blockTypeId },
        'blockTypeById'
      );

      setBlockType(blockTypeById);
      setQueryLoading(false);
    };
    fetchData();
  }, [blockTypeId]);

  const deleteBlockType = () => {
    setModalOpen(true);
  };

  /**
   * Render the editable title
   */
  const renderTitle = () => {
    if (nameEditable) {
      return (
        <div>
          <input
            style={{
              color: '#fff',
              fontFamily: 'Cabin, sans-serif',
              padding: '10px 20px',
              fontSize: '2em',
              fontWeight: 'bold',
              textAlign: 'left',
              lineHeight: '30px',
              height: '100%',
              background: 'rgba(35, 35, 35, 1)',
              border: 'none',
              borderRadius: '5px'
            }}
            ref={(input: any) => {
              if (input) input.focus();
            }}
            onBlur={(e: any) => {
              setNameEditable(false);
              updateName(blockTypeId, e.target.value);
              setBlockType({
                ...blockType,
                name: e.target.value
              });
            }}
            defaultValue={blockType.name}
          />
        </div>
      );
    } else {
      return (
        <Header
          style={{ width: '50%' }}
          onClick={() => setNameEditable(true)}
          edit
        >
          {blockType.name}
        </Header>
      );
    }
  };

  if (queryLoading) return <Loading />;
  return (
    <Auth history={props.history}>
      <Nav active="/create-blocktype" />
      {loading ? (
        <Container>
          <TopBar style={{ display: 'grid', gridTemplateColumns: '1fr 19fr' }}>
            <BackButton href={'/create-blocktype'} />
            {renderTitle()}
          </TopBar>
          <FormAndListContainer>
            <div>
              <EditBlockTypeForm
                blockTypeId={blockTypeId}
                blockType={blockType}
              />
              <DeleteButton
                style={{
                  display: 'inline-block',
                  fontFamily: 'Cabin, sans-serif',
                  fontSize: '1em',
                  padding: '10px 15px',
                  margin: '0 0 0 20px'
                }}
                onClick={() => deleteBlockType()}
              >
                Delete
              </DeleteButton>
            </div>
            <ListContainer>
              <BlockType template={blockType} />
            </ListContainer>
          </FormAndListContainer>
        </Container>
      ) : (
        <Container>
          <TopBar style={{ display: 'grid', gridTemplateColumns: '1fr 19fr' }}>
            <BackButton href={'/create-blocktype'} />
            {renderTitle()}
          </TopBar>
          <FormAndListContainer>
            <div>
              <EditBlockTypeForm
                blockTypeId={blockTypeId}
                blockType={data.liveBlockTypeById}
              />
              <DeleteButton
                style={{
                  display: 'inline-block',
                  fontFamily: 'Cabin, sans-serif',
                  fontSize: '1em',
                  padding: '10px 15px',
                  margin: '0 0 0 20px'
                }}
                onClick={() => deleteBlockType()}
              >
                Delete
              </DeleteButton>
            </div>
            <ListContainer>
              <BlockType template={data.liveBlockTypeById} />
            </ListContainer>
          </FormAndListContainer>
        </Container>
      )}
      {modalOpen && (
        <ModalStyle
          onClose={() => {
            setModalOpen(false);
          }}
        >
          <ModalContent blockTypeId={blockTypeId} history={props.history} />
        </ModalStyle>
      )}
    </Auth>
  );
};

export default EditBlockType;
