import React from 'react';
import { ErrorMessage } from 'formik';

// components
import { FormField } from '../../../../components/FormField/FormField';
// interfaces
import { IField } from '../../types/IField';
import {
  FormBlock,
  Label,
  ErrorMessageStyle,
  FileButton
} from '@iffr-dashboard-admin/ui';
import { ChooseFileWindow } from '../../../../components/ChooseFileWindow/ChooseFileWindow';
import { cdnUrl } from '../../../../utils/cdnUrl';

/**
 * Render fields
 *
 * Take the array with fieldTypes and render them out into real fields.
 *
 * @param fieldsArray
 * @param data
 */
export const renderFields = (
  fieldsArray: Array<IField>,
  data: any,
  values: any,
  fileWindowOpen: boolean,
  setFileWindowOpen: (fileWindowOpen: boolean) => void,
  newFileUploaderOpen: boolean,
  setNewFileUploaderOpen: (newFileUploaderOpen: boolean) => void
) => {
  const fields: Array<any> = [];
  fieldsArray.forEach((field: any, i: number) => {
    switch (field.inputType) {
      case 'string':
        fields.push(createField(fieldsArray, i, 'text', data));
        break;
      case 'textarea':
        fields.push(createField(fieldsArray, i, 'textarea', data, values));
        break;
      case 'qrcode':
        fields.push(createField(fieldsArray, i, 'text', data));
        break;
      case 'image':
        fields.push(
          createFileOptionField(
            fieldsArray,
            i,
            data,
            fileWindowOpen,
            setFileWindowOpen,
            newFileUploaderOpen,
            setNewFileUploaderOpen,
            values
          )
        );
        break;
      default:
        return null;
    }
  });
  return fields;
};

/**
 * Create Field
 *
 * Create a field with a dynamic inputType
 *
 * @param fieldsArray
 * @param i
 * @param inputType
 * @param data
 */
const createField = (
  fieldsArray: Array<IField>,
  i: number,
  inputType: string,
  data: any,
  values?: any
) => {
  return (
    <FormField
      key={i}
      label={`${fieldsArray[i].name.charAt(0).toUpperCase() +
        fieldsArray[i].name.slice(1)}`}
      placeholder={
        fieldsArray[i].inputType === 'image'
          ? 'URL'
          : `${fieldsArray[i].name.charAt(0).toUpperCase() +
              fieldsArray[i].name.slice(1)}`
      }
      name={fieldsArray[i].name}
      type={inputType}
      onBlur={(event: any) => handleBlur(data[fieldsArray[i].name], event)}
      onChange={(e: any, editor: any) => {
        if (inputType === 'textarea')
          values[fieldsArray[i].name] = editor.sourceElement.innerHTML;
      }}
      scope={`block`}
    />
  );
};

/**
 * Create Field
 *
 * Create a field with a dynamic inputType
 *
 * @param fieldsArray
 * @param i
 * @param inputType
 * @param data
 */
const createFileOptionField = (
  fieldsArray: Array<IField>,
  i: number,
  data: any,
  fileWindowOpen: boolean,
  setFileWindowOpen: (fileWindowOpen: boolean) => void,
  newFileUploaderOpen: boolean,
  setNewFileUploaderOpen: (newFileUploaderOpen: boolean) => void,
  values?: any
) => {
  return (
    <span key={i}>
      <FormBlock>
        <Label>Image or Video:&nbsp;</Label>
        <ErrorMessageStyle>
          <ErrorMessage name={'file'} />
        </ErrorMessageStyle>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 4fr'
          }}
        >
          <FileButton
            onClick={() => {
              setFileWindowOpen(!fileWindowOpen);
            }}
          >
            Choose
          </FileButton>
          <div
            style={{
              alignSelf: 'center',
              padding: '0 5px'
            }}
          >
            {values[fieldsArray[i].name].replace(`${cdnUrl}/`, '')}
          </div>
        </div>
      </FormBlock>
      <FormBlock>
        <div />
        <div />
        <ChooseFileWindow
          open={fileWindowOpen}
          fileUploaderOpen={newFileUploaderOpen}
          path={'iffr-daily'}
          onChoose={(file: string) => {
            values[fieldsArray[i].name] = file;
            setFileWindowOpen(false);
          }}
          onClose={() => setFileWindowOpen(false)}
          toggleThisIndex={() => {
            setNewFileUploaderOpen(true);
          }}
        />
      </FormBlock>
    </span>
  );
};

/**
 *
 * Handle Blur
 *
 * Handle the input blur to indicate an edited field
 *
 * @param currentData
 * @param e
 */
const handleBlur = (currentData: string, e: any) => {
  if (e) {
    const blackBorder = () => {
      if (e.target) {
        if (
          currentData === e.target.value &&
          (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA')
        )
          e.target.style.border = '1px solid #fff';
      }
    };

    const grayborder = () => {
      if (e.target) {
        if (
          currentData === e.target.value &&
          (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA')
        )
          e.target.style.border = '1px solid #e5e5e5';
      }
    };

    if (e.target) {
      if (
        currentData !== e.target.value &&
        (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA')
      ) {
        e.target.style.border = '1px solid blue';
        e.target.addEventListener('focus', blackBorder);
        e.target.addEventListener('focusout', grayborder);
      }
    }
  }
};
