import React from 'react';

export const IffrLogoContent = () => {
  return (
    <div
      style={{
        margin: '25px 0 0 0',
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px',
        background: 'rgba(246, 201, 251, 1)',
        height: '100%',
        textAlign: 'center',
      }}
    >
      <img src={'/img/iffr-logo.png'} alt={'iffr-logo'} width="36%" />
    </div>
  );
};
