export const CREATE_NARROW_CASTING_DASHBOARD = `
  mutation CreateNarrowCastingDashboard(
    $data: CreateNarrowCastingDashboardInput!
  ) {
    createNarrowCastingDashboard(data: $data) {
      id
      name
    }
  }
`;
