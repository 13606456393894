import { IBlock } from '../types/IBlock';
import { executeQuery } from '../../../utils/fetchHelper';
import { EDIT_BLOCK_LAYOUT } from '../mutations/editBlockLayout';

/**
 * Update the updated blocks when the layout changes
 * @param layout
 */
export const updateBlockLayout = async (
  dashboardId: number,
  allBlocks: Array<any>,
  layout: Array<{ x: number; y: number; w: number; h: number }>,
  setLoadingChanges: (loadingChanges: boolean) => void
) => {
  setLoadingChanges(true);

  let updatedBlocks: Array<any> = [];

  allBlocks.forEach((block: IBlock) => {
    const layoutId = block.predefined ? `predef-${block.id}` : block.id;

    const blockLayout = layout.find(
      (layoutItem: any) => layoutItem.i === layoutId
    );

    if (!blockLayout) return;

    // Are there layout changes?
    if (
      block.position.w !== blockLayout.w ||
      block.position.h !== blockLayout.h ||
      block.position.x !== blockLayout.x ||
      block.position.y !== blockLayout.y
    ) {
      let data: any = {
        blockId: parseInt(block.id),
        predefined: block.predefined,
        newPosition: {
          x: blockLayout.x,
          y: blockLayout.y,
          w: blockLayout.w,
          h: blockLayout.h
        }
      };

      updatedBlocks.push(data);
    }
  });

  const res = await executeQuery(
    EDIT_BLOCK_LAYOUT,
    { data: { updatedBlocks } },
    'editBlockLayout'
  );

  console.log(res);

  setLoadingChanges(false);
};
