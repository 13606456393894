import React from 'react';
import { Dashboard } from '@uppy/react';
import { Formik, Form, FormikActions } from 'formik';

import { cdnUrl } from '../../../utils/cdnUrl';

// components
import { FilesList } from '../components/FilesList/FilesList';
import { FormField } from '../../../components/FormField/FormField';
// styles
import {
  FileUploadContainer,
  Button,
  FormBlock
} from '@iffr-dashboard-admin/ui';
// types
import { IFile } from '../types/file.interface';
import { IDirectory } from '../types/directory.interface';
import { currentRouteStringified } from './currentRouteStringified';

export const renderFileWindow = (
  fileWindowState: string,
  setFileWindowState: (fileWindowState: string) => void,
  files: Array<IFile>,
  setFiles: (files: Array<IFile>) => void,
  directories: Array<IDirectory>,
  setDirectories: (directories: Array<IDirectory>) => void,
  currentRoute: Array<string>,
  setCurrentRoute: (currentRoute: Array<string>) => void,
  setLoading: (loading: boolean) => void,
  uppy: any,
  history: any
) => {
  switch (fileWindowState) {
    case 'fileList': {
      return (
        <FilesList
          files={files}
          setFiles={setFiles}
          directories={directories}
          setDirectories={setDirectories}
          currentRoute={currentRoute}
          setCurrentRoute={setCurrentRoute}
          setLoading={setLoading}
          history={history}
        />
      );
    }
    case 'fileUpload': {
      return (
        <div
          style={{
            display: 'grid',
            justifyContent: 'center',
            marginTop: '40px'
          }}
        >
          <FileUploadContainer open={true}>
            <Dashboard uppy={uppy} />
          </FileUploadContainer>
        </div>
      );
    }
    case 'createFolder': {
      return (
        <div
          style={{
            display: 'grid',
            margin: '40px auto 0',
            width: '40%'
          }}
        >
          <Formik
            initialValues={{ folder: '' }}
            validate={(values: { folder: string }) => {
              let errors: { folder?: string } = {};
              if (values.folder === '') {
                errors.folder = 'required';
              }
              return errors;
            }}
            onSubmit={async (
              values: { folder: string },
              actions: FormikActions<{ folder: string }>
            ) => {
              try {
                await fetch(`${cdnUrl}/new-directory`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    directoryName: values.folder,
                    path: currentRouteStringified(currentRoute)
                  })
                });
              } catch (e) {
                console.log(e);
              }
              actions.setSubmitting(false);
              setFileWindowState('fileList');
              setLoading(true);
            }}
            render={({ values, isSubmitting }) => (
              <Form>
                <FormField
                  label="Folder"
                  placeholder="Folder"
                  name="folder"
                  type="text"
                />
                <FormBlock>
                  <div />
                  <div />
                  <Button type="submit" disabled={isSubmitting}>
                    Create
                  </Button>
                </FormBlock>
              </Form>
            )}
          />
        </div>
      );
    }
    default: {
      return <div />;
    }
  }
};
