import { executeQuery } from '../../../utils/fetchHelper';
import { CREATE_GLOBAL_PREDEFINEDBLOCK } from '../components/Modal/components/ChooseExistingGlobalBlocks/mutations/CreateGlobalPredefinedBlock';

export const createTopTenListBlock = async (dashboardId: number) => {
  const data = {
    name: 'Top 10 Audience awards',
    type: 'top-10-audience-awards',
    dashboardId: `${dashboardId}`,
    contentId: '',
    width: 1,
    height: 18,
  };

  const res = await executeQuery(
    CREATE_GLOBAL_PREDEFINEDBLOCK,
    { data },
    'createGlobalPredefinedBlock'
  );

  console.log(res);
};
