export const ALL_AUDIENCE_AWARDS_FILMS = `
query {
  allAudienceAwardsFilms {
    id
    title
    description
    fionaId
    averageScore
    rank
    previousRank
    headerImage
    director
    runTime
    showLocation
    showTime
    ticketsAvailable
    detailViewAvailable
    detailViewActive
  }
}`;
