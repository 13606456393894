import { executeQuery } from '../../../utils/fetchHelper';
import { EDIT_BLOCKTYPE_TITLE_BY_ID } from '../mutations/EditBlockTypeTitleById';

export const updateName = async (id: number, newName: string) => {
  await executeQuery(
    EDIT_BLOCKTYPE_TITLE_BY_ID,
    { id, newName },
    'editDashboardTitleById'
  );
};
