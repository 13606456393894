import React, { useEffect, useState } from 'react';
import { DragFromHerePlaceholder } from '@iffr-dashboard-admin/ui';

interface Props {
  allBlocks: Array<any>;
}

const DFHPlaceholder: React.FC<Props> = props => {
  const [newBlockInPosition, setNewBlockInPosition] = useState(false);

  /**
   * Check if a new block is in place
   * @param blockType
   */
  useEffect(() => {
    let newBlockInPositionFound = false;

    props.allBlocks.forEach((block: any) => {
      if (block.position.y > 18) {
        newBlockInPositionFound = true;
      }
    });
    setNewBlockInPosition(newBlockInPositionFound);
  }, [props.allBlocks]);

  return <div>{newBlockInPosition && <DragFromHerePlaceholder />}</div>;
};

export default React.memo(DFHPlaceholder);
