import React, { useState } from 'react';
import { Formik, FormikActions, Form, ErrorMessage } from 'formik';

import { cdnUrl } from '../../../../../../utils/cdnUrl';

// components
import { ChooseFileWindow } from '../../../../../../components/ChooseFileWindow/ChooseFileWindow';
// styles
import {
  Header,
  FormBlock,
  Label,
  ErrorMessageStyle,
  FileButton,
  Button
} from '@iffr-dashboard-admin/ui';
// type
import { IModal } from '../../../../types/modal.interface';
// utils
import { updateFile } from './utils/updateFile';

interface Props {
  setModal: (modal: IModal) => void;
  modal: IModal;
  dashboardId: string;
}

export const FileBrowser: React.FC<Props> = props => {
  const [fileWindowOpen, setFileWindowOpen] = useState(false);
  const [newFileUploaderOpen, setNewFileUploaderOpen] = useState(false);

  return (
    <div>
      <Header>File Browser</Header>
      <Formik
        initialValues={{ image: '' }}
        onSubmit={async (
          values: { image: string },
          actions: FormikActions<{ image: string }>
        ) => {
          updateFile(
            props.modal.scenario,
            props.modal.blockId,
            props.dashboardId,
            props.modal.fileEditContent,
            values.image
          );

          actions.setSubmitting(false);
          props.setModal({ type: '', open: false });
        }}
        validate={(values: any) => {
          let errors: { image?: string } = {};
          if (values.url === '') {
            errors.image = 'Required';
          }
          return errors;
        }}
        render={({ values, isSubmitting }) => {
          return (
            <Form>
              <FormBlock>
                <Label>Image:&nbsp;</Label>
                <ErrorMessageStyle>
                  <ErrorMessage name={'image'} />
                </ErrorMessageStyle>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 4fr'
                  }}
                >
                  <FileButton
                    onClick={() => {
                      setFileWindowOpen(!fileWindowOpen);
                      setNewFileUploaderOpen(false);
                    }}
                  >
                    Choose
                  </FileButton>
                  <div
                    style={{
                      alignSelf: 'center',
                      padding: '0 5px'
                    }}
                  >
                    {values.image.replace(`${cdnUrl}/`, '')}
                  </div>
                </div>
              </FormBlock>
              <FormBlock>
                <div />
                <div />
                <ChooseFileWindow
                  open={fileWindowOpen}
                  fileUploaderOpen={newFileUploaderOpen}
                  path={''}
                  onChoose={(file: string) => {
                    values.image = file;
                    setFileWindowOpen(false);
                  }}
                  onClose={() => setFileWindowOpen(false)}
                  toggleThisIndex={() => {
                    setNewFileUploaderOpen(!newFileUploaderOpen);
                  }}
                />
              </FormBlock>
              <FormBlock>
                <div />
                <div />
                <Button type="submit" disabled={isSubmitting}>
                  Submit
                </Button>
              </FormBlock>
            </Form>
          );
        }}
      />
    </div>
  );
};
