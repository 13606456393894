import gql from "graphql-tag";

export const LIVE_ALL_DASHBOARDS = gql`
  subscription {
    liveAllDashboards {
      id
      name
      predefinedBlocks {
        id
        scope
        name
      }
      customBlocks {
        id
        scope
        name
      }
    }
  }
`;
