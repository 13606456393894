import React from 'react';
import { renderForm } from '../../../utils/renders/renderForm';

// components
import { IffrDailyForm } from '../components/PredefinedBlockForms/IffrDailyForm';
import { ThisHappenedEarlierForm } from '../components/PredefinedBlockForms/ThisHappenedEarlierForm/ThisHappenedEarlierForm';
import { IBlockType } from '../../../types/IBlockType';
import { ComingUpForm } from '../components/PredefinedBlockForms/ComingUpForm/ComingUpForm';
import { SponsorLogosForm } from '../components/PredefinedBlockForms/SponsorLogosForm/SponsorLogosForm';
import { CampaignForm } from '../components/PredefinedBlockForms/CampaignForm/CampaignForm';
import { LaterStillAvailableForm } from '../components/PredefinedBlockForms/LaterStillAvailableForm/LaterStillAvailableForm';

export const renderNewBlockModalContent = (
  setModal: (modal: { type: string; open: boolean }) => void,
  dashboardId: number,
  blockType: IBlockType,
  globalOrLocal: string,
  fileWindowOpen: boolean,
  setFileWindowOpen: (fileWindowOpen: boolean) => void,
  newFileUploaderOpen: boolean,
  setNewFileUploaderOpen: (newFileUploaderOpen: boolean) => void
) => {
  switch (blockType.id) {
    case 'predef-1': {
      return (
        <IffrDailyForm
          dashboardId={dashboardId}
          setModal={setModal}
          globalOrLocal={globalOrLocal}
        />
      );
    }
    case 'predef-2': {
      return (
        <ThisHappenedEarlierForm
          dashboardId={dashboardId}
          setModal={setModal}
          globalOrLocal={globalOrLocal}
        />
      );
    }
    case 'predef-3': {
      return (
        <ComingUpForm
          dashboardId={dashboardId}
          setModal={setModal}
          globalOrLocal={globalOrLocal}
        />
      );
    }
    case 'predef-4': {
      return (
        <CampaignForm
          dashboardId={dashboardId}
          setModal={setModal}
          globalOrLocal={globalOrLocal}
        />
      );
    }
    case 'predef-6': {
      return (
        <SponsorLogosForm
          dashboardId={dashboardId}
          setModal={setModal}
          globalOrLocal={globalOrLocal}
        />
      );
    }
    case 'predef-10': {
      return (
        <LaterStillAvailableForm
          dashboardId={dashboardId}
          setModal={setModal}
          globalOrLocal={globalOrLocal}
        />
      );
    }
    default: {
      return renderForm(
        blockType,
        dashboardId,
        globalOrLocal,
        setModal,
        fileWindowOpen,
        setFileWindowOpen,
        newFileUploaderOpen,
        setNewFileUploaderOpen
      );
    }
  }
};
