export const GLOBAL_CUSTOMBLOCKS_FOR_BLOCKTYPE_BY_ID = `
query GlobalCustomBlocksForBlockTypeById($id: Float!) {
  globalCustomBlocksForBlockTypeById(id: $id) {
    id
    customBlocks {
      id
      name
      scope
      position
      blockType {
        id
      }
      content {
        id
        data
      }
      dashboards {
        id
      }
    }
  }
}`;
