import moment from 'moment';

export const timeSince = (date: string) => {
  if (moment().diff(date, 'minutes') < 60) {
    const trailingS = moment().diff(date, 'minutes') < 2 ? '' : 's';
    return `${moment().diff(date, 'minutes')} minute${trailingS} ago`;
  } else if (moment().diff(date, 'hours') < 24) {
    const trailingS = moment().diff(date, 'hours') < 2 ? '' : 's';
    return `${moment().diff(date, 'hours')} hour${trailingS} ago`;
  } else {
    const trailingS = moment().diff(date, 'days') < 2 ? '' : 's';
    return `${moment().diff(date, 'days')} day${trailingS} ago`;
  }
};
