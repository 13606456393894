import React from 'react';

// queries and mutations
import { CREATE_GLOBAL_CUSTOMBLOCK } from './mutations/CreateGlobalCustomBlockInput';
import { CREATE_GLOBAL_PREDEFINEDBLOCK } from './mutations/CreateGlobalPredefinedBlock';
// styles
import {
  Header,
  Button,
  GlobalBlocksList,
  GlobalBlocksListItem
} from '@iffr-dashboard-admin/ui';
// types
import { IBlock } from '../../../../types/IBlock';
import { IPredefinedBlock } from '../../../../types/IPredefinedBlock';
// utils
import { executeQuery } from '../../../../../../utils/fetchHelper';

interface Props {
  blockType: any;
  existingGlobalBlocks: any;
  dashboardId: number;
  setModal: (modal: any) => void;
  setNewGlobalBlock: (newGlobalBlock: any) => void;
}

export const ChooseExistingGlobalBlocks: React.FC<Props> = props => {
  return (
    <div>
      <Header center>
        Create a new / Choose an existing global {props.blockType.name}
      </Header>

      <div style={{ textAlign: 'center' }}>
        <Button onClick={() => props.setNewGlobalBlock(true)}>
          Create new
        </Button>
      </div>

      <GlobalBlocksList>
        {props.existingGlobalBlocks.map(
          (existingGlobalBlock: IBlock | IPredefinedBlock) => {
            return (
              <GlobalBlocksListItem key={existingGlobalBlock.id}>
                <div
                  onClick={async () => {
                    switch (existingGlobalBlock.type) {
                      case 'iffr-daily':
                      case 'this-happened-earlier':
                      case 'coming-up':
                      case 'campaign':
                      case 'twitter':
                      case 'sponsor-logos':
                      case 'instagram':
                      case 'top-10-audience-awards':
                      case 'iffr-logo':
                      case 'later-still-available':
                        {
                          const data = {
                            name: existingGlobalBlock.name,
                            type: existingGlobalBlock.type,
                            dashboardId: `${props.dashboardId}`,
                            contentId: existingGlobalBlock.content.id,
                            width: existingGlobalBlock.position.w,
                            height: existingGlobalBlock.position.h
                          };
                          await executeQuery(
                            CREATE_GLOBAL_PREDEFINEDBLOCK,
                            { data },
                            'createGlobalPredefinedBlock'
                          );
                        }
                        break;
                      default:
                        {
                          const data = {
                            name: existingGlobalBlock.name,
                            blockTypeId: parseInt(props.blockType.id),
                            dashboardId: props.dashboardId,
                            contentId: parseInt(existingGlobalBlock.content.id),
                            width: existingGlobalBlock.position.w,
                            height: existingGlobalBlock.position.h
                          };
                          const res = await executeQuery(
                            CREATE_GLOBAL_CUSTOMBLOCK,
                            { data },
                            'createGlobalBlock'
                          );
                          console.log(res);
                        }
                        break;
                    }

                    props.setModal({ type: '', open: false });
                  }}
                >
                  {existingGlobalBlock.name}
                </div>
              </GlobalBlocksListItem>
            );
          }
        )}
      </GlobalBlocksList>
    </div>
  );
};
