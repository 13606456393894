import { executeQuery } from "../../../utils/fetchHelper";
import { EDIT_DASHBOARD_TITLE_BY_ID } from "../mutations/editDashboardTitleById";

export const updateName = async (id: number, newName: string) => {
  const res = await executeQuery(
    EDIT_DASHBOARD_TITLE_BY_ID,
    { id, newName },
    "editDashboardTitleById"
  );
  console.log(res);
};
