import gql from 'graphql-tag';

export const LIVE_ALL_NARROW_CASTING_DASHBOARDS = gql`
  subscription {
    liveAllNarrowCastingDashboards {
      id
      name
    }
  }
`;
