import React, { useState } from 'react';
import { Formik, Form, FormikActions, ErrorMessage } from 'formik';

import { cdnUrl } from '../../../../../../../utils/cdnUrl';

// components
import { FormField } from '../../../../../../../components/FormField/FormField';
import { ChooseFileWindow } from '../../../../../../../components/ChooseFileWindow/ChooseFileWindow';
// styles
import {
  Header,
  FormBlock,
  Label,
  ErrorMessageStyle,
  FileButton,
  Button
} from '@iffr-dashboard-admin/ui';
// queries and mutations
import { CREATE_PREDEFINEDBLOCK_TEMPLATE } from '../mutations/CreatePredefinedBlockTemplate';
// utils
import { executeQuery } from '../../../../../../../utils/fetchHelper';

interface Props {
  dashboardId: number;
  setModal: (modal: any) => void;
  globalOrLocal: string;
}

export const ThisHappenedEarlierForm: React.FC<Props> = props => {
  const [fileWindowOpen, setFileWindowOpen] = useState(false);
  const [newFileUploaderOpen, setNewFileUploaderOpen] = useState(false);

  return (
    <div>
      <Header center>Create a new This Happend Earlier block</Header>
      <div style={{ padding: '20px 0' }}>
        <Formik
          initialValues={{ name: '', url: '' }}
          onSubmit={async (
            values: { name: string; url: string },
            actions: FormikActions<{ name: string; url: string }>
          ) => {
            const data = {
              name: values.name,
              scope: props.globalOrLocal,
              data: { url: values.url },
              dashboardId: `${props.dashboardId}`,
              type: 'this-happened-earlier',
              height: 5
            };

            const res = await executeQuery(
              CREATE_PREDEFINEDBLOCK_TEMPLATE,
              { data },
              'createPredefinedBlock'
            );

            console.log(res);

            actions.setSubmitting(false);
            props.setModal({ type: '', open: false });
          }}
          validate={(values: any) => {
            let errors: { name?: string; url?: string } = {};
            if (values.name === '') {
              errors.name = 'Required';
            }
            if (values.url === '') {
              errors.url = 'Required';
            }
            return errors;
          }}
          render={({ values, isSubmitting }) => {
            return (
              <Form>
                <FormField
                  label="Name"
                  placeholder="Name"
                  name="name"
                  type="text"
                />
                <FormBlock>
                  <Label>Image:&nbsp;</Label>
                  <ErrorMessageStyle>
                    <ErrorMessage name={'url'} />
                  </ErrorMessageStyle>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 4fr'
                    }}
                  >
                    <FileButton
                      onClick={() => {
                        setFileWindowOpen(!fileWindowOpen);
                        setNewFileUploaderOpen(false);
                      }}
                    >
                      Choose
                    </FileButton>
                    <div
                      style={{
                        alignSelf: 'center',
                        padding: '0 5px'
                      }}
                    >
                      {values.url.replace(`${cdnUrl}/`, '')}
                    </div>
                  </div>
                </FormBlock>
                <FormBlock>
                  <div />
                  <div />
                  <ChooseFileWindow
                    open={fileWindowOpen}
                    fileUploaderOpen={newFileUploaderOpen}
                    path={''}
                    onChoose={(file: string) => {
                      values.url = file;
                      setFileWindowOpen(false);
                    }}
                    onClose={() => setFileWindowOpen(false)}
                    toggleThisIndex={() => {
                      setNewFileUploaderOpen(!newFileUploaderOpen);
                    }}
                  />
                </FormBlock>
                <FormBlock>
                  <div />
                  <div />
                  <Button type="submit" disabled={isSubmitting}>
                    Create
                  </Button>
                </FormBlock>
              </Form>
            );
          }}
        />
      </div>
    </div>
  );
};
