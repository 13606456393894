import React from 'react';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

interface Props {
  children: any;
  history: any;
}

export const Auth = (props: Props) => {
  const authCookie = cookies.get('auth');

  if (!authCookie || authCookie !== 'u7@iU793t724,9t7Y3?=') {
    props.history.replace('/login');
  }

  return <div>{props.children}</div>;
};
