import React, { useEffect, useState } from 'react';
import { executeQuery } from '../../../../utils/fetchHelper';
import { EDIT_DASHBOARD_IS_TEMPLATE_BY_ID } from '../../mutations/editDashboardIsTemplateById/editDashboardIsTemplateById';
import { ALL_TEMPLATE_DASHBOARDS } from '../../queries/AllTemplateDashboards';
import { EDIT_TEMPLATE_DASHBOARD } from './mutations/editTemplateDashboard';

interface Props {
  dashboard: any;
}

export const TemplateOptions: React.FC<Props> = props => {
  const [allTemplates, setAllTemplates] = useState([]);
  const [updateTemplates, setUpdateTemplates] = useState(false);

  useEffect(() => {
    let fetched = true;
    const fetchTemplates = async () => {
      const allTemplateDashboardsRes = await executeQuery(
        ALL_TEMPLATE_DASHBOARDS,
        {},
        'allTemplateDashboards'
      );
      if (fetched) {
        setAllTemplates(allTemplateDashboardsRes);
      }
    };
    fetchTemplates();

    if (updateTemplates) {
      setUpdateTemplates(false);
    }

    return () => {
      fetched = false;
    };
  }, [updateTemplates]);

  return (
    <div
      style={{
        color: '#fff',
        textAlign: 'center',
        fontFamily: 'Cabin, sans-serif'
      }}
    >
      <form>
        <label htmlFor="template">Template</label>
        &nbsp;
        <input
          id="template"
          type="checkbox"
          checked={props.dashboard.isTemplate}
          onChange={async () => {
            await executeQuery(
              EDIT_DASHBOARD_IS_TEMPLATE_BY_ID,
              {
                id: parseInt(props.dashboard.id),
                newIsTemplate: !props.dashboard.isTemplate
              },
              'editDashboardIsTemplateById'
            );
            setUpdateTemplates(true);
          }}
        ></input>
        <br />
        <br />
        {!props.dashboard.isTemplate && (
          <select
            value={props.dashboard.templateDashboardId}
            onChange={async (e: any) => {
              const res = await executeQuery(
                EDIT_TEMPLATE_DASHBOARD,
                {
                  id: parseInt(props.dashboard.id),
                  templateDashboardId: parseInt(e.target.value)
                },
                'editTemplateDashboard'
              );

              console.log(res);
            }}
          >
            <option value={0}>No template</option>
            {allTemplates.map((template: any) => (
              <option key={template.id} value={template.id}>
                {template.name}
              </option>
            ))}
          </select>
        )}
      </form>
    </div>
  );
};
