"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var theme = {
  fonts: {
    sans: '"Rubik", sans-serif',
    mono: '"Roboto Mono", sans-serif'
  },
  fontSizes: [".8rem", "1rem", "1.2rem", "1.3rem", "1.4rem", "1.6rem", "1.8rem"],
  colors: {
    primary: [],
    neutrals: [],
    errors: [],
    warnings: [],
    success: []
  },
  radii: [],
  space: [],
  buttons: {
    primary: {},
    form: {}
  }
};
exports.default = theme;