"use strict";

var __makeTemplateObject = this && this.__makeTemplateObject || function (cooked, raw) {
  if (Object.defineProperty) {
    Object.defineProperty(cooked, "raw", {
      value: raw
    });
  } else {
    cooked.raw = raw;
  }

  return cooked;
};

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
}); // styled-components.ts

var styledComponents = __importStar(require("styled-components"));

var _a = styledComponents,
    styled = _a.default,
    css = _a.css,
    createGlobalStyle = _a.createGlobalStyle,
    keyframes = _a.keyframes,
    ThemeProvider = _a.ThemeProvider;
exports.styled = styled;
exports.css = css;
exports.createGlobalStyle = createGlobalStyle;
exports.keyframes = keyframes;
exports.ThemeProvider = ThemeProvider;
exports.default = styled;
exports.GlobalStyle = createGlobalStyle(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\nbody {\n  font-size: 1.5rem;\n}\n* {\n  outline: none;\n  box-sizing: border-box;\n  padding: 0;\n  margin: 0;\n  scrollbar-color: #2e2e2e rgba(0, 0, 0, 0);\n}\nhtml {\n  font-size: 62.5%;\n}\na {\n  text-decoration: none;\n}\n\n::-webkit-scrollbar {\n  width: 8px;\n  height: 8px;\n  margin: 4px;\n}\n\n::-webkit-scrollbar-thumb {\n  width: 8px;\n  height: 8px;\n  margin: 4px;\n  border: 14px solid rgba(0, 0, 0, 0);\n  background-clip: padding-box;\n  border-radius: 15px;\n  background: #eee;\n}\n"], ["\nbody {\n  font-size: 1.5rem;\n}\n* {\n  outline: none;\n  box-sizing: border-box;\n  padding: 0;\n  margin: 0;\n  scrollbar-color: #2e2e2e rgba(0, 0, 0, 0);\n}\nhtml {\n  font-size: 62.5%;\n}\na {\n  text-decoration: none;\n}\n\n::-webkit-scrollbar {\n  width: 8px;\n  height: 8px;\n  margin: 4px;\n}\n\n::-webkit-scrollbar-thumb {\n  width: 8px;\n  height: 8px;\n  margin: 4px;\n  border: 14px solid rgba(0, 0, 0, 0);\n  background-clip: padding-box;\n  border-radius: 15px;\n  background: #eee;\n}\n"])));
var templateObject_1;