export const sortByNestedProperty = (
  prop: string,
  arr: Array<any>
): Array<any> => {
  let splitProp: Array<string> = prop.split(".");
  var len: number = splitProp.length;

  arr.sort(
    (a: any, b: any): number => {
      let i: number = 0;
      while (i < len) {
        a = a[splitProp[i]];
        b = b[splitProp[i]];
        i++;
      }
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    }
  );
  return arr;
};
