import React from 'react'

import {
  FilmItem,
  FilmListContainer,
  AvailabilityLabel
} from '@iffr-dashboard-admin/ui'
import { executeQuery } from '../../../../../../../../utils/fetchHelper'
import { UPDATE_DETAIL_VIEW_ACTIVE_BY_ID } from '../../mutations/updateDetailViewActiveById'

interface Props {
  films: Array<any>
}

export const FilmsList: React.FC<Props> = (props) => {
  const updateDetailViewActive = async (id: string) => {
    // find old active film and make it inactive
    const oldActive = props.films.find((f: any) => f.detailViewActive)

    if (oldActive) {
      const oldActiveId = parseInt(oldActive.id)

      await executeQuery(
        UPDATE_DETAIL_VIEW_ACTIVE_BY_ID,
        { id: oldActiveId },
        'updateDetailViewActiveById'
      )
    }

    const filmId = parseInt(id)

    await executeQuery(
      UPDATE_DETAIL_VIEW_ACTIVE_BY_ID,
      { id: filmId },
      'updateDetailViewActiveById'
    )
  }

  return (
    <FilmListContainer>
      {props.films.map((film: any, index: number) => {
        return (
          <FilmItem
            key={film.title}
            availableForDetail={film.detailViewAvailable}
            detailViewActive={film.detailViewActive}
            index={index}
            onClick={() => {
              updateDetailViewActive(film.id)
            }}
          >
            {film.rank}: {film.title}{' '}
            {film.ticketsAvailable && (
              <AvailabilityLabel type='ticket'>Tickets</AvailabilityLabel>
            )}
            {film.showLocation && (
              <AvailabilityLabel type='show'>Show</AvailabilityLabel>
            )}
          </FilmItem>
        )
      })}
    </FilmListContainer>
  )
}
