import React from 'react';
import { Menu } from '@iffr-dashboard-admin/ui';

interface INavProps {
  dashboardId?: number;
  active?: string;
}

/**
 * Nav
 * @constructor
 */
export const Nav: React.FC<INavProps> = props => {
  return (
    <Menu
      dashboardId={props.dashboardId}
      active={props.active}
      menuItems={[
        {
          content: 'Create',
          static: true
        },
        {
          to: '/create-blocktype',
          content: 'BlockTypes',
          icon: 'shapes',
          nested: true
        },
        {
          to: '/create-dashboard',
          content: 'Dashboards',
          icon: 'th',
          nested: true
        },
        {
          to: '/create-narrow-casting-dashboard',
          content: 'Narrow Casting',
          icon: 'shapes',
          nested: true
        },
        {
          content: 'Settings',
          static: true
        },
        {
          to: '/files/home',
          content: 'Files',
          icon: '',
          nested: true
        },
        {
          to: '/twitter',
          content: 'Twitter',
          icon: '',
          nested: true
        },
        {
          to: '/instagram',
          content: 'Instagram',
          icon: '',
          nested: true
        },
        {
          to: '/variables',
          content: 'Variables',
          icon: '',
          nested: true
        }
      ]}
    />
  );
};

export default React.memo(Nav);
