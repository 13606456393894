export const ALL_INSTAGRAM = `
query AllInstagram {
  allInstagram {
    id
    instagramId
    fileUrl
    mediaType    
    status
  }
}`;
