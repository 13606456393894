import { executeQuery } from '../../../utils/fetchHelper';
import { EDIT_NARROW_CASTING_DASHBOARD_TITLE_BY_ID } from '../mutations/EditNarrowCastingDashboardTitleById';

export const updateName = async (id: number, name: string) => {
  await executeQuery(
    EDIT_NARROW_CASTING_DASHBOARD_TITLE_BY_ID,
    { id, name },
    'editNarrowCastingDashboardTitleById'
  );
};
