export const ALL_TWEETS = `
query AllTweets {
  allTweets {
    id
    tweetId
    status
    text
    userName
    userImage
    timeSince
    hashtags
  }
}`;
