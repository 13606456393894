import React, { useState } from 'react';

// styled
import {
  FormContainer,
  Background,
  ModalCloseButton,
  Header,
  GlobalButton,
  LocalButton,
  GlobalLocalSwitchContainer
} from '@iffr-dashboard-admin/ui';
import { NewSlideForm } from './components/NewSlideForm/NewSlideForm';
import { ListOfGlobalSlides } from './components/ListOfGlobalSlides/ListOfGlobalSlides';

interface Props {
  formState: { active: boolean; type: string };
  setFormState: (formState: { active: boolean; type: string }) => void;
  dashboardId: number;
}

export const CreateNarrowCastingSlideForm: React.FC<Props> = props => {
  const [slideScope, setSlideScope] = useState('');

  if (props.formState.active) {
    switch (props.formState.type) {
      case 'globalLocalSwitch': {
        return (
          <div>
            <Background active />
            <FormContainer active>
              <ModalCloseButton
                onClick={() => props.setFormState({ active: false, type: '' })}
              >
                <span
                  style={{
                    lineHeight: '28px',
                    fontSize: '1.4em'
                  }}
                >
                  &times;
                </span>
              </ModalCloseButton>
              <div
                style={{
                  padding: '20px 20px 15px'
                }}
              >
                <Header>Create new Narrow Casting Slide</Header>
                <GlobalLocalSwitchContainer>
                  <GlobalButton
                    onClick={async () => {
                      setSlideScope('global');
                      props.setFormState({
                        active: true,
                        type: 'createNewGlobalSlide'
                      });
                    }}
                  />
                  <LocalButton
                    onClick={async () => {
                      setSlideScope('local');
                      props.setFormState({
                        active: true,
                        type: 'createNewLocalSlide'
                      });
                    }}
                  />
                </GlobalLocalSwitchContainer>
              </div>
            </FormContainer>
          </div>
        );
      }
      case 'createNewLocalSlide': {
        return (
          <div>
            <Background active />
            <FormContainer active>
              <ModalCloseButton
                onClick={() => props.setFormState({ active: false, type: '' })}
              >
                <span
                  style={{
                    lineHeight: '28px',
                    fontSize: '1.4em'
                  }}
                >
                  &times;
                </span>
              </ModalCloseButton>
              <div
                style={{
                  padding: '20px 20px 15px'
                }}
              >
                <Header>Create new Narrow Local Casting Slide</Header>
                <NewSlideForm
                  setFormState={props.setFormState}
                  slideScope={slideScope}
                  dashboardId={props.dashboardId}
                />
              </div>
            </FormContainer>
          </div>
        );
      }
      case 'createNewGlobalSlide': {
        return (
          <div>
            <Background active />
            <FormContainer active>
              <ModalCloseButton
                onClick={() => props.setFormState({ active: false, type: '' })}
              >
                <span
                  style={{
                    lineHeight: '28px',
                    fontSize: '1.4em'
                  }}
                >
                  &times;
                </span>
              </ModalCloseButton>
              <div
                style={{
                  padding: '20px 20px 15px'
                }}
              >
                <Header>Create new Narrow Global Casting Slide</Header>
                <NewSlideForm
                  setFormState={props.setFormState}
                  slideScope={slideScope}
                  dashboardId={props.dashboardId}
                />
                <ListOfGlobalSlides
                  setFormState={props.setFormState}
                  dashboardId={props.dashboardId}
                />
              </div>
            </FormContainer>
          </div>
        );
      }
      default: {
        return <div />;
      }
    }
  } else {
    return <div />;
  }
};
