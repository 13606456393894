export const BLOCKTYPE_BY_ID_TEMPLATE = `
  query BlockTypeById($id: Float!) {
    blockTypeById(id: $id) {
      id
      name
      fields {
        id
        name
        inputType
        index
        options
      }
    }
  }
`;
