export const EDIT_NARROW_CASTING_DASHBOARD_BY_ID = `
  mutation EditNarrowCastingDashboardById($data: EditNarrowCastingDashboardInput!){
    editNarrowCastingDashboardById(data: $data) {
      slides {
        id
        name
      }
      slidesOrder
    }
  }
`;
