import React, { useEffect, useState } from 'react';

import { InstagramContentContainer } from '@iffr-dashboard-admin/ui';
import { executeQuery } from '../../../../../../utils/fetchHelper';
import { MOST_RECENT_INSTAGRAM } from './queries/mostRecentInstagram';

let InstagramContent = () => {
  const [mediaItem, setMediaItem] = useState({ fileUrl: '' });

  useEffect(() => {
    let fetched = true;
    const fetchInstagramImage = async () => {
      const mediaItem = await executeQuery(
        MOST_RECENT_INSTAGRAM,
        {},
        'mostRecentInstagram'
      );
      if (fetched) {
        setMediaItem(mediaItem);
      }
    };

    if (mediaItem.fileUrl === '') {
      fetchInstagramImage();
    }

    return () => {
      fetched = false;
    };
  }, [mediaItem.fileUrl]);

  return <InstagramContentContainer imageUrl={mediaItem.fileUrl} />;
};

export default React.memo(InstagramContent);
