import React from 'react';
import { Formik, Form, FormikActions } from 'formik';

// components
import { FormField } from '../../../components/FormField/FormField';
// interfaces
import { ICreateFieldTypeFormValues } from '../types/ICreateFieldTypeFormValues';
import { IBlockType } from '../../CreateBlockType/types/IBlockType';
// queries and Mutations
import { CREATE_FIELDTYPE } from '../mutations/CreateFieldType';
// styled
import { FormContainer, Button, FormBlock } from '@iffr-dashboard-admin/ui';
import { executeQuery } from '../../../utils/fetchHelper';

interface Props {
  blockTypeId: number;
  blockType: IBlockType;
}

export const EditBlockTypeForm: React.FC<Props> = (props): JSX.Element => {
  const inputTypeFormOptions = [
    {
      id: 'string',
      name: 'Text Field'
    },
    {
      id: 'textArea',
      name: 'Large Text Area'
    },
    {
      id: 'image',
      name: 'Image'
    },
    {
      id: 'qrcode',
      name: 'QR Code'
    }
  ];

  return (
    <FormContainer static>
      <Formik
        initialValues={{
          name: '',
          inputType: undefined,
          options: {}
        }}
        onSubmit={(
          values: ICreateFieldTypeFormValues,
          actions: FormikActions<ICreateFieldTypeFormValues>
        ) => {
          const fieldIndex = props.blockType.fields.length + 1;
          const fieldTypeData = {
            name: values.name.toLowerCase(),
            inputType: values.inputType!.toLowerCase(),
            index: fieldIndex,
            options: {},
            blocktypeId: props.blockTypeId
          };

          try {
            executeQuery(
              CREATE_FIELDTYPE,
              { data: fieldTypeData },
              'createFieldType'
            );
          } catch (e) {
            throw new Error(e);
          }

          actions.setSubmitting(false);
        }}
        validate={(values: ICreateFieldTypeFormValues) => {
          let errors: { name?: string; inputType?: string } = {};
          if (!values.name) errors.name = 'Required';
          if (values.name.indexOf(' ') !== -1)
            errors.name = "Can't have a space";
          if (
            props.blockType.fields.findIndex(
              (x: any) => x.name === values.name.toLowerCase()
            ) !== -1
          ) {
            errors.name = 'Already Exists';
          }
          if (!values.inputType) errors.inputType = 'Required';
          return errors;
        }}
        render={({ isSubmitting }) => (
          <Form>
            <FormField
              label="Name"
              placeholder="Name"
              type="text"
              name="name"
            />
            <FormField
              label="Input Type"
              placeholder="Input Type"
              type="select"
              name="inputType"
              options={inputTypeFormOptions}
            />
            <FormBlock>
              <div />
              <div />
              <Button type="submit" disabled={isSubmitting}>
                Add Field
              </Button>
            </FormBlock>
          </Form>
        )}
      />
    </FormContainer>
  );
};
