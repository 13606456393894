import React, { useState } from 'react';
import { ISlide } from '../../types/ISlide';
import {
  SlideStyle,
  SlideName,
  SlideScope,
  DeleteSlide,
  CloseSlide,
  SlideBottomBar,
  EditSlide
} from '@iffr-dashboard-admin/ui';
import { executeQuery } from '../../../../../../utils/fetchHelper';
import { DELETE_NARROW_CASTING_SLIDE_BY_ID } from './mutations/deleteNarrowCastingSlideById';
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot
} from 'react-beautiful-dnd';
import { SlideContent } from './components/SlideContent/SlideContent';

interface Props {
  index: number;
  active: boolean;
  slide: ISlide;
  setActiveSlideId: (activeSlideId: string) => void;
  activeSlideId: string;
  dashboardId: number;
}

export const Slide: React.FC<Props> = props => {
  const [editing, setEditing] = useState(false);

  return (
    <Draggable
      key={props.slide.id}
      draggableId={`${props.slide.id}`}
      index={props.index}
    >
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <SlideStyle
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          active={props.active}
          onClick={() => {
            if (!props.active) {
              props.setActiveSlideId(props.slide.id);
            }
          }}
        >
          <SlideName active={props.active}>
            {props.slide.name} <SlideScope>{props.slide.scope}</SlideScope>
          </SlideName>
          <SlideContent
            content={props.slide.content}
            slideId={props.slide.id}
            editing={editing}
            setEditing={setEditing}
            timeActiveInSeconds={props.slide.timeActiveInSeconds}
          />
          <SlideBottomBar>
            <CloseSlide onClick={() => props.setActiveSlideId('0')}>
              Close
            </CloseSlide>
            {!props.slide.content ? (
              <div />
            ) : (
              <EditSlide onClick={() => setEditing(true)}>Edit</EditSlide>
            )}
            <DeleteSlide
              onClick={() => {
                props.setActiveSlideId('0');

                // Wait for animation
                setTimeout(async () => {
                  try {
                    const res = await executeQuery(
                      DELETE_NARROW_CASTING_SLIDE_BY_ID,
                      {
                        narrowCastingSlideId: parseInt(props.slide.id),
                        narrowCastingDashboardId: props.dashboardId
                      },
                      'deleteNarrowCastingSlideById'
                    );
                    console.log(res);
                  } catch (e) {
                    throw new Error(e);
                  }
                }, 400);
              }}
            >
              Delete
            </DeleteSlide>
          </SlideBottomBar>
        </SlideStyle>
      )}
    </Draggable>
  );
};
