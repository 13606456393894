import React, { useState } from 'react';
import { ISlide } from './types/ISlide';
import { Slide } from './components/Slide/Slide';
import {
  SlidesOverview,
  AddSlide,
  AddSlidePlus
} from '@iffr-dashboard-admin/ui';
import {
  DragDropContext,
  Droppable,
  DroppableProvided
} from 'react-beautiful-dnd';
import { executeQuery } from '../../../../utils/fetchHelper';
import { EDIT_NARROW_CASTING_DASHBOARD_BY_ID } from './mutations/editNarrowCastingDashboardById';

interface Props {
  narrowCastingDashboardId: number;
  slides: Array<ISlide>;
  slidesOrder: Array<number>;
  setFormState: (formState: { type: string; active: boolean }) => void;
  dashboardId: number;
}

const orderSlides = (
  slides: Array<ISlide>,
  slidesOrder: Array<number>
): Array<ISlide> => {
  return slidesOrder.map(
    slideId => slides[slides.findIndex(x => parseInt(x.id) === slideId)]
  );
};

export const Slides: React.FC<Props> = props => {
  const orderedSlides = orderSlides(props.slides, props.slidesOrder);

  const [activeSlideId, setActiveSlideId] = useState('0');
  const [slides, setSlides] = useState(orderedSlides);
  const [slidesOrder, setSlidesOrder] = useState(props.slidesOrder);

  if (props.slidesOrder !== slidesOrder) {
    setSlides(orderSlides(props.slides, props.slidesOrder));
    setSlidesOrder(props.slidesOrder);
  }

  return (
    <DragDropContext
      onDragEnd={async (result: any) => {
        const { destination, source, draggableId } = result;

        const draggableSlide: any =
          slides[slides.findIndex((slide: any) => slide.id === draggableId)];

        if (!destination) return;

        if (
          destination.droppableId === source.droppableId &&
          destination.index === source.index
        )
          return;

        const newSlides: Array<any> = [...slides];
        newSlides.splice(source.index, 1);
        newSlides.splice(destination.index, 0, draggableSlide);

        setSlides(newSlides);

        const newSlidesOrder = newSlides.map((slide: any) =>
          parseInt(slide.id)
        );

        const data = {
          narrowCastingDashboardId: props.narrowCastingDashboardId,
          slidesOrder: newSlidesOrder
        };

        try {
          await executeQuery(
            EDIT_NARROW_CASTING_DASHBOARD_BY_ID,
            { data },
            'editNarrowCastingDashboardById'
          );
        } catch (e) {
          throw new Error(e);
        }
      }}
    >
      <Droppable droppableId={'narrow-casting-slides'} direction={'vertical'}>
        {(provided: DroppableProvided) => (
          <SlidesOverview ref={provided.innerRef} {...provided.droppableProps}>
            {slides.map((slide: ISlide, i: number) => {
              return (
                <Slide
                  index={i}
                  key={slide.id}
                  slide={slide}
                  active={slide.id === activeSlideId}
                  setActiveSlideId={setActiveSlideId}
                  activeSlideId={activeSlideId}
                  dashboardId={props.dashboardId}
                />
              );
            })}
            {provided.placeholder}
            <AddSlide
              key={'new'}
              onClick={() =>
                props.setFormState({ type: 'globalLocalSwitch', active: true })
              }
            >
              <AddSlidePlus>+</AddSlidePlus>
            </AddSlide>
          </SlidesOverview>
        )}
      </Droppable>
    </DragDropContext>
  );
};
