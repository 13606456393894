import React, { useState, useEffect } from 'react';
import moment from 'moment';
// styles
import {
  Header,
  Input,
  ComingUpContainer,
  SearchListContainer,
  SearchList,
  SearchListItem,
  CurrentEventsContainer,
  Button,
  SearchListItemText,
  CurrentShow,
  CurrentShowImage,
  CurrentShowTitle,
  CurrentShowLocation,
  CurrentShowSection,
  CurrentShowTickets,
  CurrentShowBody,
  CurrentShowList,
  CurrentShowListItem,
  CurrentShowListItemTitle,
  CurrentShowListItemRemoveButton,
  Loading,
  FormBlock,
  Label
} from '@iffr-dashboard-admin/ui';
import { createComingUpBlock } from './utils/CreateComingUpBlock';

import { apiUrl } from '../../../../../../../utils/apiUrl';

interface Props {
  dashboardId: number;
  setModal: (modal: { type: string; open: boolean }) => void;
  globalOrLocal: string;
  eventList?: Array<any>;
}

export const ComingUpForm: React.FC<Props> = props => {
  const [allShows, setAllShows] = useState([]);
  const [filteredShows, setFilteredShows] = useState([]);
  const [currentShowId, setCurrentShowId] = useState('');
  const [currentShow, setCurrentShow] = useState<any>({});
  const [eventList, setEventList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));

  const addEventToList = async (event: any) => {
    let newEventList = [...eventList, event];
    newEventList = newEventList.sort((a: any, b: any) =>
      moment(a.time).isAfter(b.time) ? 1 : -1
    );
    setEventList(newEventList);
  };

  useEffect(() => {
    let fetched = true;
    const fetchData = async () => {
      if (currentShowId !== '') {
        let res: any;
        let jsondata: any;

        try {
          res = await fetch(`${apiUrl}/coming-up/detail/${currentShowId}`);
        } catch (e) {
          console.log(e);
        }

        try {
          jsondata = await res.json();
          if (fetched) {
            setCurrentShow(jsondata);
            setLoading(false);
          }
        } catch (e) {
          console.log(e);
        }
      }
    };
    fetchData();
    return () => {
      fetched = false;
    };
  }, [currentShowId]);

  useEffect(() => {
    let fetched = true;
    const fetchData = async () => {
      try {
        const res = await fetch(`${apiUrl}/coming-up/${date}`);
        try {
          const jsondata = await res.json();
          if (fetched) {
            setAllShows(jsondata);
            setFilteredShows(jsondata);
          }
        } catch (e) {
          console.log(e);
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
    return () => {
      fetched = false;
    };
  }, [date]);

  const handleSearch = async (e: any) => {
    const newShows = allShows.filter((show: any) => {
      return (
        show.fullTitle.toLowerCase().indexOf(e.target.value.toLowerCase()) !==
        -1
      );
    });
    setFilteredShows(newShows);
  };

  return (
    <div>
      <Header>Coming Up - {moment().format('D / M / Y')}</Header>
      <ComingUpContainer>
        <SearchListContainer>
          <FormBlock>
            <Label>Date: </Label>
            <div />
            <Input
              type={'date'}
              defaultValue={date}
              onChange={e => setDate(e.target.value)}
            />
          </FormBlock>
          <Input placeholder="Search for..." onChange={handleSearch} />
          <SearchList>
            {filteredShows.map((show: any) => (
              <SearchListItem
                key={show.id}
                onClick={() => {
                  setLoading(true);
                  setCurrentShowId(show.id);
                }}
              >
                <SearchListItemText>{show.fullTitle}</SearchListItemText>
              </SearchListItem>
            ))}
          </SearchList>
        </SearchListContainer>
        <CurrentEventsContainer>
          <div>
            {currentShow.show && (
              <CurrentShow>
                {loading && (
                  <div style={{ height: '400px' }}>
                    <Loading inline center />
                  </div>
                )}
                {!loading && (
                  <div>
                    <CurrentShowTitle>
                      {currentShow.show.title}
                      {currentShow.show.section && (
                        <CurrentShowSection section={currentShow.show.section}>
                          {currentShow.show.section.replace(/-/g, ' ')}
                        </CurrentShowSection>
                      )}
                    </CurrentShowTitle>
                    <CurrentShowImage src={currentShow.show.image || ''}>
                      {!currentShow.show.image ? 'No image' : ''}
                    </CurrentShowImage>
                    <CurrentShowLocation>
                      {currentShow.show.location} -{' '}
                      {moment(currentShow.show.time).format('HH:mm')}
                      <CurrentShowTickets>
                        {currentShow.show.ticketsAvailable
                          ? 'Tickets available'
                          : 'No tickets available'}
                      </CurrentShowTickets>
                    </CurrentShowLocation>
                    <CurrentShowBody
                      dangerouslySetInnerHTML={{
                        __html: currentShow.show.text
                      }}
                    />
                    <div
                      style={{
                        textAlign: 'right',
                        width: '100%',
                        margin: '0 0 20px'
                      }}
                    >
                      <Button
                        style={{
                          fontSize: '.8em'
                        }}
                        onClick={() =>
                          addEventToList({
                            ...currentShow.show,
                            detailViewActive: false
                          })
                        }
                      >
                        Add to list
                      </Button>
                    </div>
                  </div>
                )}
              </CurrentShow>
            )}

            <CurrentShowList>
              {eventList.map((event: any, i: number) => {
                return (
                  <CurrentShowListItem key={i}>
                    <CurrentShowListItemTitle>
                      {moment(event.time).format('HH:mm')} - {event.title}
                    </CurrentShowListItemTitle>
                    <CurrentShowListItemRemoveButton
                      onClick={() => {
                        let newEventList = [...eventList];
                        newEventList.splice(i, 1);
                        setEventList(newEventList);
                      }}
                    >
                      Remove
                    </CurrentShowListItemRemoveButton>
                  </CurrentShowListItem>
                );
              })}
            </CurrentShowList>

            <div
              style={{
                float: 'right'
              }}
            >
              <Button
                onClick={() => {
                  createComingUpBlock(
                    props.dashboardId,
                    eventList,
                    props.globalOrLocal
                  );
                  props.setModal({ type: '', open: false });
                }}
              >
                Create
              </Button>
            </div>
          </div>
        </CurrentEventsContainer>
      </ComingUpContainer>
    </div>
  );
};
