import React, { useState, Component } from 'react';
import ReactDOM from 'react-dom';
import {
  SponsorLogosContainer,
  SponsorLogosImage,
  SponsorLogosImageContainer
} from '@iffr-dashboard-admin/ui';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot
} from 'react-beautiful-dnd';
import { executeQuery } from '../../../../../../utils/fetchHelper';
import { EDIT_PREDEFINEDBLOCK_BY_ID_TEMPLATE } from '../../mutations/EditPredefinedBlockByIdTemplate';
import { cdnUrl } from '../../../../../../utils/cdnUrl';

interface Props {
  block: any;
  dashboardId: number;
  setRefreshed: (refreshed: boolean) => void;
}

interface ISponsorLogo {
  id: number;
  image: string;
  active: boolean;
}

type ItemProps = {
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  sponsorLogo: any;
};

const portal: HTMLElement = document.createElement('div');
document.body.appendChild(portal);

class PortalAwareItem extends Component<ItemProps> {
  render() {
    const provided: DraggableProvided = this.props.provided;
    const snapshot: DraggableStateSnapshot = this.props.snapshot;

    const usePortal: boolean = snapshot.isDragging;

    const child: any = (
      // @ts-ignore
      <SponsorLogosImageContainer
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        inportal={`${usePortal}`}
      >
        <SponsorLogosImage src={`${cdnUrl}/${this.props.sponsorLogo.image}`} />
      </SponsorLogosImageContainer>
    );

    if (!usePortal) {
      return child;
    }

    // if dragging - put the item in a portal
    return ReactDOM.createPortal(child, portal);
  }
}

export const SponsorLogosContent: React.FC<Props> = props => {
  const [sponsorLogos, setSponsorLogos] = useState(
    props.block.content.data.filter(s => s.active)
  );

  return (
    <DragDropContext
      onDragEnd={async (result: any) => {
        const { destination, source, draggableId } = result;

        const draggableSponsorLogo: any =
          sponsorLogos[
            sponsorLogos.findIndex(
              (sponsorLogo: any) => sponsorLogo.id === parseInt(draggableId)
            )
          ];

        if (!destination) return;

        if (
          destination.droppableId === source.droppableId &&
          destination.index === source.index
        )
          return;

        const newSponsorLogos: Array<any> = [...sponsorLogos];
        newSponsorLogos.splice(source.index, 1);
        newSponsorLogos.splice(destination.index, 0, draggableSponsorLogo);

        const data = {
          predefinedBlockId: parseInt(props.block.id),
          dashboardId: `${props.dashboardId}`,
          data: newSponsorLogos
        };

        setSponsorLogos(newSponsorLogos);
        props.setRefreshed(false);

        try {
          await executeQuery(
            EDIT_PREDEFINEDBLOCK_BY_ID_TEMPLATE,
            { data },
            'editCustomBlockById'
          );
        } catch (e) {
          throw new Error(e);
        }
      }}
    >
      <Droppable
        droppableId={'sponsor-logos-in-block'}
        direction={'horizontal'}
      >
        {(provided: any) => (
          <SponsorLogosContainer
            ref={provided.innerRef}
            {...provided.droppableProps}
            amountOfSponsorLogos={sponsorLogos.length}
          >
            {sponsorLogos.map((sponsorLogo: ISponsorLogo, i: number) => {
              return (
                <Draggable
                  key={sponsorLogo.id}
                  draggableId={`${sponsorLogo.id}`}
                  index={i}
                >
                  {(
                    provided: DraggableProvided,
                    snapshot: DraggableStateSnapshot
                  ) => (
                    <PortalAwareItem
                      provided={provided}
                      snapshot={snapshot}
                      sponsorLogo={sponsorLogo}
                    />
                  )}
                </Draggable>
              );
            })}
            <div style={{ display: 'none' }}>{provided.placeholder}</div>
          </SponsorLogosContainer>
        )}
      </Droppable>
    </DragDropContext>
  );
};
