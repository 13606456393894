export const NARROW_CASTING_DASHBOARD_BY_ID = `
  query NarrowCastingDashboardById($id: Float!) {
    narrowCastingDashboardById(id: $id) {
      id
      name
      slidesOrder
      slides {
        id
        name
        scope
        timeActiveInSeconds
        content {
          id
          name
          data
        }
      }
    }
  }
`;
