export const ALL_DASHBOARDS = `
  query AllDashboards {
    allDashboards {
      id
      name
      predefinedBlocks {
        id
        scope
        name
      }
      customBlocks {
        id
        scope
        name
      }
    }
  }
`;
