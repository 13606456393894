import React, { useState } from 'react';

import { sortByNestedProperty } from '../../../../utils/sortByNestedProperty';

// Style
import {
  DraggableBlockContainer,
  Toolbar,
  CloseButton,
  DraggableBlockContent,
  Item,
  Button,
  CustomContentContainer
} from '@iffr-dashboard-admin/ui';
// Queries and mutations
import { EDIT_CUSTOMBLOCK_BY_ID_TEMPLATE } from './mutations/EditCustomBlockById';
// Utils
import { renderItem } from './utils/renderItem';
import { executeQuery } from '../../../../utils/fetchHelper';

interface Props {
  data: Array<any>;
  block: any;
  onRemove: (block: any) => void;
  onSavePosition: () => void;
  setRefreshed: (refreshed: boolean) => void;
  loadingChanges: boolean;
}

const DraggableCustomBlock: React.FC<Props> = props => {
  const [fieldEditing, setFieldEditing] = useState('');
  const [formData, setFormData] = useState(props.data);

  const sortedFields = sortByNestedProperty(
    'index',
    props.block.blockType.fields
  );

  const updateBlock = async (formData: any, customBlockId: string) => {
    const data = {
      customBlockId: parseInt(customBlockId),
      data: formData
    };

    props.setRefreshed(false);

    await executeQuery(
      EDIT_CUSTOMBLOCK_BY_ID_TEMPLATE,
      { data },
      'editCustomBlockById'
    );
  };

  const handleBlur = (newValue: any, field: string) => {
    while (newValue.indexOf(`<br data-cke-filler="true">`) !== -1) {
      newValue = newValue.replace(`<br data-cke-filler="true">`, '');
    }

    const newFormData: any = { ...formData };
    if (newFormData[field]) {
      newFormData[field].value = newValue;
    } else {
      newFormData[field] = {
        value: newValue
      };
    }
    setFormData(newFormData);
    updateBlock(formData, props.block.id);
  };

  const renderSaveButton = () => {
    if (
      !props.block.position.minW &&
      props.block.scope === 'global' &&
      props.block.position.y < 20
    ) {
      return (
        <div style={{ margin: '20px 0 0', width: '100%', textAlign: 'center' }}>
          <Button onClick={() => props.onSavePosition()}>Save Position</Button>
        </div>
      );
    } else {
      return <div />;
    }
  };

  return (
    <DraggableBlockContainer className={'react-grid-item'}>
      <Toolbar className="dragHandle" global={props.block.scope === 'global'}>
        {!props.loadingChanges && (
          <CloseButton scope={props.block.scope}>
            <span onClick={() => props.onRemove(props.block)}>&times;</span>
          </CloseButton>
        )}
        <span style={{ fontWeight: 'bold', textAlign: 'center' }}>
          {props.block.name}
        </span>
      </Toolbar>
      <DraggableBlockContent>
        <CustomContentContainer>
          {sortedFields.map((field: any, i: number) => {
            return (
              <Item key={i} onClick={() => setFieldEditing(field.name)}>
                {renderItem(
                  field,
                  fieldEditing,
                  formData,
                  handleBlur,
                  setFieldEditing
                )}
              </Item>
            );
          })}
        </CustomContentContainer>
        {renderSaveButton()}
      </DraggableBlockContent>
    </DraggableBlockContainer>
  );
};

export default React.memo(DraggableCustomBlock);
