import React from 'react';

import {
  LaterStillAvailableContainer,
  LaterStillAvailableHeader,
  LaterStillAvailableTitle,
  LaterStillAvailableBody,
  LaterStillAvailableShowInfo,
  LaterStillAvailableTextContainer
} from '@iffr-dashboard-admin/ui';
import moment from 'moment';

interface Props {
  content: any;
}

export const LaterStillAvailableContent: React.FC<Props> = props => {
  return (
    <LaterStillAvailableContainer>
      <LaterStillAvailableHeader
        url={props.content.data.image ? props.content.data.image : ''}
      >
        {props.content.data.image ? '' : 'File Expired'}
      </LaterStillAvailableHeader>
      <LaterStillAvailableTextContainer>
        <LaterStillAvailableTitle>
          {props.content.data.title}
        </LaterStillAvailableTitle>
        <LaterStillAvailableBody
          dangerouslySetInnerHTML={{ __html: props.content.data.text }}
        />
        <LaterStillAvailableShowInfo>
          {props.content.data.location}
          {' - '}
          {moment(props.content.data.time).format('HH:mm')}
        </LaterStillAvailableShowInfo>
      </LaterStillAvailableTextContainer>
    </LaterStillAvailableContainer>
  );
};
