export const DASHBOARD_BY_ID_TEMPLATE = `
query DashboardById($id: Float!) {
  dashboardById(id: $id) {
    id
    name
    isTemplate
    swipeAnimation
    templateDashboardId
    customBlocks {
      id
      name
      scope
      blockType {
        id
        name
        fields {
          name
          inputType
          index
        }
      }
      position
      content {
        id
        data
      }
    }
    predefinedBlocks {
      id
      name
      scope
      type
      content {
        id
        data
      }
      position
    }  
  }
}`;
