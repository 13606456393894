import { executeQuery } from '../../../utils/fetchHelper';

import { CREATE_GLOBAL_PREDEFINEDBLOCK } from '../components/Modal/components/ChooseExistingGlobalBlocks/mutations/CreateGlobalPredefinedBlock';

export const createTwitterBlock = async (dashboardId: number) => {
  const data = {
    name: 'Twitter',
    type: 'twitter',
    dashboardId: `${dashboardId}`,
    contentId: '',
    width: 1,
    height: 4,
  };

  const res = await executeQuery(
    CREATE_GLOBAL_PREDEFINEDBLOCK,
    { data },
    'createGlobalPredefinedBlock'
  );

  console.log(res);
};
