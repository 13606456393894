import React, { useState, useEffect } from 'react';

// interfaces
import { ITemplateProps } from './types/ITemplateProps';
import { IField } from './types/IField';
// styled
import {
  Header,
  ListStyle,
  ListItemStyle,
  DeleteButton,
} from '@iffr-dashboard-admin/ui/dist/src';
// utils
import { sortByNestedProperty } from '../../../utils/sortByNestedProperty';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggableProvided,
} from 'react-beautiful-dnd';
import { executeQuery } from '../../../utils/fetchHelper';
import { EDIT_FIELDTYPE_INDEX_TEMPLATE } from './mutations/EditFieldTypeIndexTemplate';
import { DELETE_FIELDTYPE_BY_ID } from './mutations/deleteFieldTypeById';

export const BlockType: React.FC<ITemplateProps> = props => {
  const [blockTypeFields, setBlockTypeFields] = useState(
    sortByNestedProperty('index', props.template.fields)
  );

  useEffect(() => {
    setBlockTypeFields(sortByNestedProperty('index', props.template.fields));
  }, [props.template.fields]);

  const updateIndex = (BlockTypeFields: Array<IField>) => {
    const newBlockTypeFields = BlockTypeFields.map(
      (field: any, index: number) => {
        field.index = index;
        return field;
      }
    );

    setBlockTypeFields(newBlockTypeFields);
  };

  return (
    <div>
      <Header small>Current Fields</Header>
      <DragDropContext
        onDragEnd={async (result: any) => {
          const { destination, source, draggableId } = result;

          const draggableBlockTypeField: any =
            blockTypeFields[
              blockTypeFields.findIndex(
                (blockTypeField: any) => blockTypeField.name === draggableId
              )
            ];

          if (!destination) return;

          if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
          )
            return;

          const newBlockTypeFields: Array<any> = [...blockTypeFields];
          newBlockTypeFields.splice(source.index, 1);
          newBlockTypeFields.splice(
            destination.index,
            0,
            draggableBlockTypeField
          );

          updateIndex(newBlockTypeFields);

          const data = {
            fields: newBlockTypeFields,
          };

          await executeQuery(
            EDIT_FIELDTYPE_INDEX_TEMPLATE,
            { data },
            'editFieldTypeIndex'
          );
        }}
      >
        <Droppable droppableId={'blocktype-fields'} direction={'vertical'}>
          {(provided: any) => {
            return (
              <ListStyle ref={provided.innerRef} {...provided.droppableProps}>
                {blockTypeFields.map((field: IField, i: number) => {
                  return (
                    <Draggable
                      key={field.name}
                      draggableId={field.name}
                      index={i}
                    >
                      {(provided: DraggableProvided) => {
                        return (
                          <ListItemStyle
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            key={field.name}
                          >
                            <b>{field.name}</b>:{' '}
                            {field.inputType === 'string'
                              ? 'text'
                              : field.inputType}
                            <div
                              style={{ float: 'right' }}
                              onClick={() =>
                                executeQuery(
                                  DELETE_FIELDTYPE_BY_ID,
                                  { id: parseInt(field.id) },
                                  'deleteFieldTypeById'
                                )
                              }
                            >
                              <DeleteButton>delete</DeleteButton>
                            </div>
                          </ListItemStyle>
                        );
                      }}
                    </Draggable>
                  );
                })}
                <div>{provided.placeholder}</div>
              </ListStyle>
            );
          }}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
