// Interfaces
import { IField } from "../types/IField";

/**
 * Get initial values
 *
 * Loop through all the fields that need to be implemented (gotten from the template that the block is connected to).
 * Then create an initialValues object according to those fields.
 *
 * @param FieldsToBeImplemented
 * @param currentFields
 */
export const getInitialValues = (
  FieldsToBeImplemented: Array<IField>,
  currentFields: any
) => {
  const InitialValues: any = {};

  for (let i = 0; i < FieldsToBeImplemented.length; i++) {
    switch (FieldsToBeImplemented[i].inputType) {
      case "string":
      case "image":
      case "textarea":
        setValue(currentFields, FieldsToBeImplemented, i, InitialValues, "");
        break;
      case "number":
        setValue(currentFields, FieldsToBeImplemented, i, InitialValues, 0);
        break;
      default:
        break;
    }
  }

  return InitialValues;
};

/**
 * Set value
 *
 * If the field is already existing, set the initialValue to that value. If that is not true, then check if the field
 * has a defaultValue, gotten from the options object and set the initialValue to either that or nothing if it doesn't
 * exist.
 *
 * @param currentFields
 * @param FieldsToBeImplemented
 * @param i
 * @param InitialValues
 * @param lastChoiceValue
 */
const setValue = (
  currentFields: any,
  FieldsToBeImplemented: Array<IField>,
  i: number,
  InitialValues: any,
  lastChoiceValue: any
) => {
  if (currentFields[FieldsToBeImplemented[i].name]) {
    InitialValues[FieldsToBeImplemented[i].name] =
      currentFields[FieldsToBeImplemented[i].name];
  } else if (
    FieldsToBeImplemented[i].options &&
    FieldsToBeImplemented[i].options.defaultValue
  ) {
    InitialValues[FieldsToBeImplemented[i].name] =
      FieldsToBeImplemented[i].options.defaultValue;
  } else {
    InitialValues[FieldsToBeImplemented[i].name] = lastChoiceValue;
  }
};
