import React from 'react';
import { Form, Formik, FormikActions } from 'formik';

// components
import { FormField } from '../../../../components/FormField/FormField';
// interfaces
import { ICreateNarrowCastingDashboardFormValues } from '../../types/ICreateDashboardFormValues';
// queries and mutations
import { CREATE_NARROW_CASTING_DASHBOARD } from '../../mutations/CreateNarrowCastingDashboard';
// styled
import {
  FormContainer,
  FormBlock,
  Button,
  Background,
  ModalCloseButton,
  Header,
} from '@iffr-dashboard-admin/ui';
import { executeQuery } from '../../../../utils/fetchHelper';

interface Props {
  active: boolean;
  setFormActive: (formActive: boolean) => void;
}

export const CreateNarrowCastingDashboardForm: React.FC<Props> = props => {
  return (
    <div>
      <Background active={props.active} />
      <FormContainer active={props.active}>
        <ModalCloseButton onClick={() => props.setFormActive(false)}>
          <span
            style={{
              lineHeight: '28px',
              fontSize: '1.4em',
            }}
          >
            &times;
          </span>
        </ModalCloseButton>
        <div
          style={{
            padding: '20px 20px 15px',
          }}
        >
          <Header>Create new Narrow Casting Dashboard</Header>
          <Formik
            initialValues={{ name: '' }}
            onSubmit={async (
              values: ICreateNarrowCastingDashboardFormValues,
              actions: FormikActions<ICreateNarrowCastingDashboardFormValues>,
            ) => {
              const data = {
                name: values.name,
              };

              try {
                executeQuery(
                  CREATE_NARROW_CASTING_DASHBOARD,
                  { data },
                  'createNarrowCastingDashboard',
                );
              } catch (e) {
                throw new Error(e);
              }

              actions.setSubmitting(false);
              props.setFormActive(false);
            }}
            validate={(values: ICreateNarrowCastingDashboardFormValues) => {
              let errors: { name?: string } = {};
              if (!values.name) errors.name = 'required';
              return errors;
            }}
            render={({ isSubmitting }) => (
              <Form>
                <FormField
                  label="Name"
                  placeholder="Name"
                  name="name"
                  type="text"
                  narrow
                />
                <FormBlock narrow>
                  <div />
                  <div />
                  <Button type="submit" disabled={isSubmitting}>
                    Create
                  </Button>
                </FormBlock>
              </Form>
            )}
          />
        </div>
      </FormContainer>
    </div>
  );
};
