export const PREDEFINEDBLOCK_BY_TYPE = `
query PredefinedBlockByType($type: String!) {
  predefinedBlockByType(type: $type) {
    id
    name
    type
    content {
      id
    }
    position
  }
}`;
