import React, { useState } from 'react';

import { cdnUrl } from '../../../../utils/cdnUrl';

// styles
import {
  FileThumbnail,
  OverviewFileListItem,
  FileText,
  FileName,
  FileSize,
  FolderIcon,
  CreationDate,
  TrashcanIcon,
  Button
} from '@iffr-dashboard-admin/ui';
// types
import { IFile } from '../../types/file.interface';
import { IDirectory } from '../../types/directory.interface';
// utils
import { currentRouteStringified } from '../../utils/currentRouteStringified';
import moment from 'moment';

interface Props {
  files: Array<IFile>;
  setFiles: (files: Array<IFile>) => void;
  directories: Array<IDirectory>;
  setDirectories: (directories: Array<IDirectory>) => void;
  currentRoute: Array<string>;
  setCurrentRoute: (currentRoute: Array<string>) => void;
  setLoading: (loading: boolean) => void;
  history: any;
}

export const FilesList: React.FC<Props> = props => {
  const [creationDateEditing, setCreationDateEditing] = useState(-1);

  return (
    <div>
      <OverviewFileListItem top>
        <div />
        <FileName>Title</FileName>
        <FileName>Size</FileName>
        <FileName>Date created</FileName>
        <div />
      </OverviewFileListItem>
      {/* Directories */}
      {props.directories.map((directory: IDirectory, i: number) => {
        return (
          <OverviewFileListItem key={i} folder>
            <FolderIcon />
            <FileText
              onClick={() => {
                let newRoute = [...props.currentRoute];
                newRoute.push(directory.machineName as never);
                props.history.replace(
                  '/files/' + currentRouteStringified(newRoute)
                );
                props.setCurrentRoute(newRoute);
                props.setFiles([]);
                props.setDirectories([]);
                props.setLoading(true);
              }}
            >
              <FileName>{directory.name}</FileName>
            </FileText>
            <FileText>
              <FileSize>{`-`}</FileSize>
            </FileText>
            <FileText>
              <CreationDate>{`n/a`}</CreationDate>
            </FileText>
            <TrashcanIcon
              onClick={async () => {
                const updatedDirectories = props.directories;
                updatedDirectories.splice(i, 1);
                props.setDirectories([...updatedDirectories]);

                await fetch(
                  `${cdnUrl}/${currentRouteStringified(props.currentRoute)}${
                    directory.machineName
                  }`,
                  {
                    method: 'DELETE',
                    headers: {
                      'Content-Type': 'application/json'
                    }
                  }
                );
              }}
            />
          </OverviewFileListItem>
        );
      })}
      {/* Files */}
      {props.files.map((file: IFile, i: number) => {
        return (
          <OverviewFileListItem key={i}>
            {file.name.indexOf('.mp4') === -1 && (
              <FileThumbnail
                background={`${cdnUrl}/thumbnails/${currentRouteStringified(
                  props.currentRoute
                )}${file.name}`}
              />
            )}
            {file.name.indexOf('.mp4') !== -1 && (
              <video
                autoPlay
                muted
                loop
                style={{
                  width: '50px',
                  alignSelf: 'center',
                  borderRadius: '5px'
                }}
              >
                <source
                  src={`${cdnUrl}/${currentRouteStringified(
                    props.currentRoute
                  )}${file.name}`}
                  type="video/mp4"
                />
                Your browser does not support HTML5 video.
              </video>
            )}
            <FileText>
              <FileName>{file.name}</FileName>
            </FileText>
            <FileText>
              <FileSize>{file.size}Mb</FileSize>
            </FileText>
            <FileText>
              {i === creationDateEditing && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    defaultValue={file.creationDate.substr(11, 5)}
                    id="timeInput"
                    style={{ height: '50%', margin: '0 20px 0 0' }}
                    type="time"
                  />
                  <Button
                    style={{ margin: '0' }}
                    onClick={async () => {
                      if (!document) return;

                      const elem = document.getElementById('timeInput');
                      if (!elem) return;

                      let newTime = (elem as any).value;

                      if (newTime === '') return;

                      if (file.creationDate !== 'n/a') {
                        newTime = `${file.creationDate.substr(
                          0,
                          11
                        )}${newTime}${file.creationDate.substr(16, 3)}`;
                      } else {
                        const now = moment().format('YYYY:MM:DD HH:mm:ss');

                        newTime = `${now.substr(0, 11)}${newTime}${now.substr(
                          16,
                          3
                        )}`;
                      }

                      await fetch(`${cdnUrl}/edit-date`, {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                          fileName: file.name,
                          path: file.path,
                          newDate: newTime
                        })
                      });

                      file.creationDate = newTime;
                      setCreationDateEditing(-1);
                    }}
                  >
                    save
                  </Button>
                </div>
              )}
              {i !== creationDateEditing && (
                <CreationDate onClick={() => setCreationDateEditing(i)}>
                  {file.creationDate.substr(11, 5) || file.creationDate}
                </CreationDate>
              )}
            </FileText>
            <TrashcanIcon
              onClick={async () => {
                const updatedFiles = props.files;
                updatedFiles.splice(i, 1);
                props.setFiles([...updatedFiles]);

                await fetch(
                  `${cdnUrl}/${currentRouteStringified(props.currentRoute)}${
                    file.name
                  }`,
                  {
                    method: 'DELETE',
                    headers: {
                      'Content-Type': 'application/json'
                    }
                  }
                );
              }}
            />
          </OverviewFileListItem>
        );
      })}
    </div>
  );
};
