import React from 'react'
import {
  DashboardOverviewContainer,
  DashboardOverviewItem,
  DashboardTitle,
  BlockTag,
  Plus,
  BlockTags
} from '@iffr-dashboard-admin/ui'

interface Props {
  dashboards: Array<{
    id: number
    name: string
    predefinedBlocks: Array<any>
    customBlocks: Array<any>
  }>
  setFormActive: (formActive: boolean) => void
}

export const DashboardOverview: React.FC<Props> = (props) => {
  return (
    <div>
      <DashboardOverviewContainer>
        {props.dashboards.map((dashboard: any) => {
          return (
            <DashboardOverviewItem
              key={dashboard.id}
              href={`/dashboard/${dashboard.id}`}
            >
              <DashboardTitle>{dashboard.name}</DashboardTitle>
              <BlockTags>
                {console.log(dashboard)}
                {dashboard.predefinedBlocks.map((predefinedBlock: any) =>
                  predefinedBlock.scope === 'global' ? (
                    <BlockTag key={predefinedBlock.id}>
                      {predefinedBlock.name}
                    </BlockTag>
                  ) : (
                    <BlockTag key={predefinedBlock.id} local={1}>
                      {predefinedBlock.name}
                    </BlockTag>
                  )
                )}

                {dashboard.customBlocks.map((customBlock: any) =>
                  customBlock.scope === 'global' ? (
                    <BlockTag key={customBlock.id}>{customBlock.name}</BlockTag>
                  ) : (
                    <BlockTag key={customBlock.id} local={1}>
                      {customBlock.name}
                    </BlockTag>
                  )
                )}
              </BlockTags>
            </DashboardOverviewItem>
          )
        })}
        <DashboardOverviewItem
          key={'new'}
          onClick={() => props.setFormActive(true)}
          new
        >
          <Plus />
        </DashboardOverviewItem>
      </DashboardOverviewContainer>
    </div>
  )
}
