import { executeQuery } from '../../../../../../../../utils/fetchHelper';

import { CREATE_PREDEFINEDBLOCK_TEMPLATE } from '../../mutations/CreatePredefinedBlockTemplate';

export const createComingUpBlock = async (
  dashboardId: number,
  eventList: Array<any>,
  globalOrLocal: string
) => {
  const data = {
    name: 'Coming Up',
    scope: globalOrLocal,
    type: 'coming-up',
    dashboardId: `${dashboardId}`,
    data: eventList,
    height: 2
  };

  const res = await executeQuery(
    CREATE_PREDEFINEDBLOCK_TEMPLATE,
    { data },
    'createPredefinedBlock'
  );

  console.log(res);
};
