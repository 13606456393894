import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { IModal } from '../../../../types/modal.interface';
import { IPredefinedBlock } from '../../../../types/IPredefinedBlock';
import {
  ComingUpContentContainer,
  Event,
  EventTime,
  EventDataContainer,
  EventTitle,
  EventLocationSection,
  EventSection
} from '@iffr-dashboard-admin/ui';
import { executeQuery } from '../../../../../../utils/fetchHelper';
import { EDIT_CONTENT_DATA } from './mutations/editContentData';

interface Props {
  block: IPredefinedBlock;
  setModal: (modal: IModal) => void;
  setRefreshed: (refreshed: boolean) => void;
}

export const ComingUpContent: React.FC<Props> = props => {
  const [eventList, setEventList] = useState(props.block.content.data);

  useEffect(() => {
    setEventList(props.block.content.data);
  }, [props.block.content.data]);

  const updateEventDetailActive = async (eventIndex: number) => {
    // find already active and deactivate
    const alreadyActive = eventList.find((e: any) => e.detailViewActive);
    const newEventList = [...eventList];

    if (alreadyActive) {
      const idx = eventList.findIndex(
        (e: any) => e.title === alreadyActive.title
      );

      newEventList[idx].detailViewActive = false;
    }

    newEventList[eventIndex].detailViewActive = !newEventList[eventIndex]
      .detailViewActive;

    const data = {
      id: parseInt(props.block.content.id),
      content: newEventList
    };

    await executeQuery(EDIT_CONTENT_DATA, { data }, 'editContentData');

    setEventList(newEventList);
  };

  return (
    <ComingUpContentContainer>
      {eventList.map((event: any, i: number) => {
        return (
          <Event
            key={i}
            highlighted={event.detailViewActive}
            onClick={() => updateEventDetailActive(i)}
          >
            <EventTime>{moment(event.time).format('HH:mm')}</EventTime>
            <EventDataContainer>
              <EventTitle>{event.title}</EventTitle>
              <EventLocationSection>
                {event.location}
                {event.section && (
                  <div style={{ display: 'inline' }}>
                    <EventSection section={event.section}>
                      {event.section.replace(/-/g, ' ')}
                    </EventSection>
                  </div>
                )}
                {event.ticketsAvailable && (
                  <EventSection section="ticket">Ticket</EventSection>
                )}
              </EventLocationSection>
            </EventDataContainer>
          </Event>
        );
      })}
    </ComingUpContentContainer>
  );
};
