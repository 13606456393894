import React, { useState } from 'react';
import { useEffect } from 'react';
import { executeQuery } from '../../../../../../utils/fetchHelper';
import { ALL_GLOBAL_NARROW_CASTING_SLIDES } from './queries/allGlobalNarrowCastingSlides';
import { CREATE_NEW_GLOBAL_SLIDE_WITH_EXISTING_CONTENT } from './mutations/createNewGlobalSlideWithExistingContent';
import { GlobalSlideItem } from '@iffr-dashboard-admin/ui';

interface Props {
  setFormState: (formState: { type: string; active: boolean }) => void;
  dashboardId: number;
}

export const ListOfGlobalSlides: React.FC<Props> = props => {
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    const fetchGlobalSlides = async () => {
      try {
        const slides = await executeQuery(
          ALL_GLOBAL_NARROW_CASTING_SLIDES,
          {},
          'allGlobalNarrowCastingSlides'
        );

        setSlides(slides);
      } catch (e) {
        throw new Error(e);
      }
    };
    fetchGlobalSlides();
  });

  return (
    <div>
      <h3 style={{ color: '#fff', margin: '20px 0' }}>
        Or choose an already existing global Slide:
      </h3>
      {slides.map((slide: any) => {
        return (
          <GlobalSlideItem
            isClickable={slide.content !== null}
            key={slide.id}
            onClick={async () => {
              if (!slide.content) return;
              try {
                await executeQuery(
                  CREATE_NEW_GLOBAL_SLIDE_WITH_EXISTING_CONTENT,
                  {
                    slideId: parseInt(slide.id),
                    dashboardId: props.dashboardId
                  },
                  'createNewGlobalSlideWithExistingContent'
                );
              } catch (e) {
                throw new Error(e);
              }
              props.setFormState({ type: '', active: false });
            }}
          >
            {/* Create list with slides, but make content-less slides unclickable */}
            {slide.name}
            {/* {slide.content ? slide.content.name : 'no-content'} */}
          </GlobalSlideItem>
        );
      })}
    </div>
  );
};
