import React from 'react';
import { Formik, Form, FormikActions } from 'formik';
import { ICreateBlockFormValues } from '../../types/ICreateBlockFormValues';
import { FormField } from '../../../../components/FormField/FormField';
import { FormBlock, Button, Header } from '@iffr-dashboard-admin/ui';
// utils
import { CREATE_CUSTOMBLOCK_TEMPLATE } from '../../mutations/CreateCustomBlockTemplate';
import { executeQuery } from '../../../../utils/fetchHelper';
import { getInitialValues } from '../getInitialValues';
import { renderFields } from './renderFields';

export const renderForm = (
  currentBlockType: any,
  dashboardId: number,
  globalOrLocal: string,
  setModal: (modal: { type: string; open: boolean }) => void,
  fileWindowOpen: boolean,
  setFileWindowOpen: (fileWindowOpen: boolean) => void,
  newFileUploaderOpen: boolean,
  setNewFileUploaderOpen: (newFileUploaderOpen: boolean) => void
) => {
  return (
    <div>
      {returnFormElement(
        {
          name: '',
          ...getInitialValues(
            currentBlockType.fields,
            currentBlockType.data || {}
          )
        },
        (values: ICreateBlockFormValues) => {
          const errors: { name?: string } = {};
          if (values.name === '') errors.name = 'Required';
          return errors;
        },
        async (
          values: ICreateBlockFormValues,
          actions: FormikActions<ICreateBlockFormValues>
        ) => {
          const blockData = { ...values };
          delete blockData.name;

          const dataArray = Object.entries(blockData);

          dataArray.forEach((dataItem: any) => {
            const fieldIndex = currentBlockType.fields.findIndex(
              (field: any) => {
                return field.name === dataItem[0];
              }
            );

            (blockData as any)[dataItem[0]] = {
              value: dataItem[1],
              type: currentBlockType.fields[fieldIndex].inputType
            };
          });

          const data = {
            name: values.name,
            scope: globalOrLocal,
            blockTypeId: parseInt(currentBlockType.id),
            data: blockData,
            dashboardId
          };

          await executeQuery(
            CREATE_CUSTOMBLOCK_TEMPLATE,
            { data },
            'createBlock'
          );

          setModal({ type: '', open: false });
          actions.setSubmitting(false);
        },
        currentBlockType,
        fileWindowOpen,
        setFileWindowOpen,
        newFileUploaderOpen,
        setNewFileUploaderOpen
      )}
    </div>
  );
};

const returnFormElement = (
  initialValues: any,
  validateFunc: (arg0: any) => any,
  onsubmitFunc: (arg0: any, arg1: any) => any,
  currentBlockType: any,
  fileWindowOpen: boolean,
  setFileWindowOpen: (fileWindowOpen: boolean) => void,
  newFileUploaderOpen: boolean,
  setNewFileUploaderOpen: (newFileUploaderOpen: boolean) => void
) => {
  return (
    <div>
      <Header>Create {currentBlockType.name} block</Header>
      <Formik
        initialValues={initialValues}
        validate={validateFunc}
        onSubmit={onsubmitFunc}
        render={({ values, isSubmitting }) => (
          <Form>
            <FormField
              label="Block Name"
              placeholder="Block Name"
              name="name"
              type="text"
            />
            {renderFields(
              currentBlockType.fields,
              {},
              values,
              fileWindowOpen,
              setFileWindowOpen,
              newFileUploaderOpen,
              setNewFileUploaderOpen
            )}
            <FormBlock>
              <div />
              <div />
              <Button type="submit" disabled={isSubmitting}>
                Create
              </Button>
            </FormBlock>
          </Form>
        )}
      />
    </div>
  );
};
