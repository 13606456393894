import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import fetch from 'node-fetch';
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';

import { cdnUrl } from '../../utils/cdnUrl';

// components
import { Nav } from '../../components/Nav';
// styles
import {
  Header,
  Container,
  Loading,
  FilesToolbar,
  FilesToolbarButton
} from '@iffr-dashboard-admin/ui';
// utils
import { currentRouteStringified } from './utils/currentRouteStringified';
import { renderFileWindow } from './utils/renderFileWindow';

// types
import { IFile } from './types/file.interface';
import { IDirectory } from './types/directory.interface';
import { Auth } from '../../components/Auth/Auth';

export const Files: React.FC<RouteComponentProps> = props => {
  const { route }: { route?: string } = props.match.params;
  let initialRoute = [route || ''];

  if (route !== 'home') {
    initialRoute = ['home', route || ''];
  }

  const [files, setFiles] = useState<Array<IFile>>([]);
  const [directories, setDirectories] = useState<Array<IDirectory>>([]);
  const [currentRoute, setCurrentRoute] = useState<Array<string>>(initialRoute);
  const [loading, setLoading] = useState(true);
  const [fileWindowState, setFileWindowState] = useState('fileList');

  const uppy = Uppy({
    restrictions: {
      maxFileSize: 52428800,
      maxNumberOfFiles: 50,
      minNumberOfFiles: 1,
      allowedFileTypes: ['image/*', 'video/mp4']
    }
  });

  uppy.use(XHRUpload, {
    endpoint: `${cdnUrl}/upload`,
    method: 'post',
    formData: true,
    fieldName: 'file'
  });

  uppy.setMeta({
    path: currentRouteStringified(currentRoute)
  });

  uppy.on('complete', (result: any) => {
    if (result.failed.length > 0)
      console.error({ message: 'File upload failed', result: result.failed });
    setFileWindowState('fileList');
    setLoading(true);
  });

  useEffect(() => {
    return () => {
      uppy.close();
    };
  });

  useEffect(() => {
    const fetchFiles = async () => {
      const res = await fetch(
        `${cdnUrl}/${
          currentRouteStringified(currentRoute) === '/'
            ? ''
            : currentRouteStringified(currentRoute)
        }`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      const json = await res.json();
      if (!!json.files) setFiles(json.files);
      if (!!json.directories) setDirectories(json.directories);
      setLoading(false);
    };
    fetchFiles();
  }, [currentRoute, loading]);

  return (
    <Auth history={props.history}>
      <Nav active="/files" />
      <Container>
        <Header>Files</Header>
        {loading && <Loading center />}
        {!loading && (
          <>
            <FilesToolbar>
              <FilesToolbarButton
                inactive={currentRoute.length === 1}
                onClick={() => {
                  let newRoute = [...currentRoute];
                  newRoute.pop();

                  props.history.replace(
                    '/files/' +
                      (currentRouteStringified(newRoute) === '/'
                        ? 'home'
                        : currentRouteStringified(newRoute))
                  );
                  setCurrentRoute(newRoute);
                  setFiles([]);
                  setLoading(true);
                }}
              >
                Back
              </FilesToolbarButton>
              <FilesToolbarButton
                onClick={() => setFileWindowState('fileList')}
                center
              >
                {currentRouteStringified(currentRoute)}
              </FilesToolbarButton>
              <FilesToolbarButton
                onClick={() => setFileWindowState('fileUpload')}
                center
              >
                Upload
              </FilesToolbarButton>
              <FilesToolbarButton
                onClick={() => setFileWindowState('createFolder')}
                center
              >
                Create Folder
              </FilesToolbarButton>
            </FilesToolbar>
            {renderFileWindow(
              fileWindowState,
              setFileWindowState,
              files,
              setFiles,
              directories,
              setDirectories,
              currentRoute,
              setCurrentRoute,
              setLoading,
              uppy,
              props.history
            )}
          </>
        )}
      </Container>
    </Auth>
  );
};
