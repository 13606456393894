import React from 'react';

import { CampaignContainer } from '@iffr-dashboard-admin/ui';

interface Props {
  block: any;
  dashboardId: number;
  setRefreshed: (refreshed: boolean) => void;
}

export const CampaignContent: React.FC<Props> = props => {
  const supportedVideoFileTypes = ['mp4', 'mov'];

  if (
    supportedVideoFileTypes.indexOf(
      props.block.content.data.file.substr(
        props.block.content.data.file.length - 3,
        3
      )
    ) === -1
  ) {
    return <CampaignContainer url={props.block.content.data.file} />;
  } else {
    return (
      <CampaignContainer>
        <div style={{ alignSelf: 'center' }}>
          <video autoPlay muted loop style={{ width: '100%' }}>
            <source src={props.block.content.data.file} type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
        </div>
      </CampaignContainer>
    );
  }
};
