import React, { useState, Component } from 'react';
import ReactDOM from 'react-dom';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DraggableProvided,
  DraggableStateSnapshot
} from 'react-beautiful-dnd';
import { getVariables } from '../../../../../../../utils/getVariables';
import {
  Header,
  Button,
  SponsorLogosList,
  SponsorLogosListItem,
  SponsorLogoImage,
  SponsorLogoCheckContainer,
  SponsorLogoCheckbox,
  SponsorLogoCheckmark
} from '@iffr-dashboard-admin/ui';
import { useEffect } from 'react';
import { executeQuery } from '../../../../../../../utils/fetchHelper';
import { IFile } from '../../../../../../Files/types/file.interface';
import { CREATE_PREDEFINEDBLOCK_TEMPLATE } from '../mutations/CreatePredefinedBlockTemplate';
import { cdnUrl } from '../../../../../../../utils/cdnUrl';

interface Props {
  dashboardId: number;
  setModal: (modal: { type: string; open: boolean }) => void;
  globalOrLocal: string;
}

type ItemProps = {
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  sponsorLogoData: any;
  setSponsorLogoData: (sponsorLogoData: any) => void;
  image: any;
  i: number;
};

const portal: HTMLElement = document.createElement('div');
document.body.appendChild(portal);

class PortalAwareItem extends Component<ItemProps> {
  render() {
    const provided: DraggableProvided = this.props.provided;
    const snapshot: DraggableStateSnapshot = this.props.snapshot;

    const usePortal: boolean = snapshot.isDragging;

    const child: any = (
      // @ts-ignore
      <SponsorLogosListItem
        style={{ border: '1px solid white' }}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        inportal={`${usePortal}`}
      >
        <SponsorLogoImage
          src={`${cdnUrl}/${this.props.image.path}${this.props.image.name}`}
        />
        <SponsorLogoCheckContainer
          onClick={() => {
            const newData = [...this.props.sponsorLogoData];
            newData[this.props.i] = {
              ...this.props.sponsorLogoData[this.props.i],
              active: !this.props.sponsorLogoData[this.props.i].active
            };
            this.props.setSponsorLogoData(newData);
          }}
        >
          <SponsorLogoCheckbox
            type="checkbox"
            defaultChecked={
              this.props.sponsorLogoData[this.props.i]
                ? this.props.sponsorLogoData[this.props.i].active
                : false
            }
          />
          <SponsorLogoCheckmark
            checked={
              this.props.sponsorLogoData[this.props.i]
                ? this.props.sponsorLogoData[this.props.i].active
                : false
            }
            viewBox="0 0 50 50"
          >
            <polyline points="10,25 20,35 40,15" />
          </SponsorLogoCheckmark>
        </SponsorLogoCheckContainer>
      </SponsorLogosListItem>
    );

    if (!usePortal) {
      return child;
    }

    // if dragging - put the item in a portal
    return ReactDOM.createPortal(child, portal);
  }
}

export const SponsorLogosForm: React.FC<Props> = props => {
  const [sponsorLogoData, setSponsorLogoData] = useState<
    Array<{
      id: number;
      image: string;
      active: boolean;
    }>
  >([]);
  const [images, setImages] = useState<Array<IFile>>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFiles = async () => {
      const variables: any = await getVariables();

      fetch(`${cdnUrl}/${variables.sponsorLogoLocation}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((res: any) => res.json())
        .then(async (json: any) => {
          setImages(json.files);
          const newData = json.files.map((file: IFile) => {
            return {
              id: file.id,
              image: file.path + file.name,
              active: true
            };
          });
          setSponsorLogoData(newData);
          setLoading(false);
        });
    };
    fetchFiles();
  }, []);

  const handleSubmit = async () => {
    const data = {
      name: 'Sponsor Logos',
      scope: props.globalOrLocal,
      data: sponsorLogoData,
      dashboardId: `${props.dashboardId}`,
      type: 'sponsor-logos',
      height: 2
    };

    await executeQuery(
      CREATE_PREDEFINEDBLOCK_TEMPLATE,
      { data },
      'createPredefinedBlock'
    );

    props.setModal({ type: '', open: false });
  };

  return (
    <div>
      <Header center>
        Create Sponsor Logos Block{' - '}
        {props.globalOrLocal.charAt(0).toUpperCase() +
          props.globalOrLocal.slice(1)}
      </Header>
      {loading && <div>Loading...</div>}
      {!loading && (
        <DragDropContext
          onDragEnd={result => {
            const { destination, source, draggableId } = result;

            const draggableSponsorLogoData: any =
              sponsorLogoData[
                sponsorLogoData.findIndex(
                  (image: any) => image.id === parseInt(draggableId)
                )
              ];
            const draggableImageData: IFile =
              images[
                images.findIndex(
                  (image: any) => image.id === parseInt(draggableId)
                )
              ];

            if (!destination) return;

            if (
              destination.droppableId === source.droppableId &&
              destination.index === source.index
            )
              return;

            const newSponsorLogoData: Array<any> = [...sponsorLogoData];
            newSponsorLogoData.splice(source.index, 1);
            newSponsorLogoData.splice(
              destination.index,
              0,
              draggableSponsorLogoData
            );

            const newImagesData: Array<IFile> = [...images];
            newImagesData.splice(source.index, 1);
            newImagesData.splice(destination.index, 0, draggableImageData);

            setSponsorLogoData(newSponsorLogoData);
            setImages(newImagesData);
          }}
        >
          <div>
            <Droppable droppableId={'sponsor-logo-list'}>
              {(provided: any) => (
                <SponsorLogosList
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {images.map((image: IFile, i: number) => {
                    return (
                      <Draggable
                        key={image.id}
                        draggableId={`${image.id}`}
                        index={i}
                      >
                        {(
                          provided: DraggableProvided,
                          snapshot: DraggableStateSnapshot
                        ) => (
                          <PortalAwareItem
                            provided={provided}
                            snapshot={snapshot}
                            sponsorLogoData={sponsorLogoData}
                            setSponsorLogoData={setSponsorLogoData}
                            image={image}
                            i={i}
                          />
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </SponsorLogosList>
              )}
            </Droppable>
            <div
              style={{
                textAlign: 'center'
              }}
            >
              <Button onClick={() => handleSubmit()}>Create</Button>
            </div>
          </div>
        </DragDropContext>
      )}
    </div>
  );
};
